import { useDic } from "@/components/Dic/useDic.hook";
import { defaultParserConfigValues } from "@/components/FileParsing/FileParsingFields";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import {
    mapToInitValues,
    mapUploadedFileFormValues,
    mapVendorToVendorName,
} from "@/components/ISVMapping/helpers";
import { ISV_MAPPER_STEP_KEY } from "@/components/ISVMapping/ISVDataMappingForm.component";
import {
    useEditMapperMutation,
    useMapper,
} from "@/components/ISVMapping/loadables";
import FieldsStep from "@/components/ISVMapping/steps/Fields.step";
import ParsingStep from "@/components/ISVMapping/steps/Parsing.step";
import UploadStep from "@/components/ISVMapping/steps/Upload.step";
import { StepForm } from "@/components/StepForm";
import { useRouteISVConnection } from "@/mixpanel/hooks/useRouteISVConnection.hook";
import {
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { useLoadableHasValueChangedEffect } from "@/modules/loadable/useLoadableHasValueChangedEffect.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback, useState } from "react";

const ISVDataMappingUploadPage = () => {
    const { accountId, isvConnectionId, isvMapperId } = useAccountAppParams();
    useFullWidthTableLayout();
    const [allValues, setAllValues] = useState({
        ...defaultParserConfigValues,
    });

    const {
        locationRouterService,
        accountAppLocations: { isvDataMappingEditLocation },
    } = useDic();

    const onCancel = useCallback(() => {
        locationRouterService.navigate(isvDataMappingEditLocation, {
            accountId,
            isvConnectionId,
            isvMapperId,
            tab: ISV_MAPPER_STEP_KEY.FIELDS,
        });
    }, [
        locationRouterService,
        isvDataMappingEditLocation,
        accountId,
        isvConnectionId,
        isvMapperId,
    ]);

    const [isValidMapper, setIsValidMapper] = useState(true);

    const getStepButtons = useCallback(
        ({ stepperProps, formId }) => [
            {
                visible: !stepperProps.isFirstStep,
                label: t(
                    stepperProps.isLastStep
                        ? "general.save"
                        : "general.continue",
                ),
                type: "primary",
                formId,
                disabled: stepperProps.isLastStep ? !isValidMapper : undefined,
            },
            {
                visible: true,
                label: t("general.cancel"),
                type: "text",
                onClick: onCancel,
                "data-test": "cancel-button",
            },
        ],
        [isValidMapper, onCancel],
    );

    const steps = [
        {
            key: ISV_MAPPER_STEP_KEY.UPLOAD,
            title: t("isv-connections.data-mapping.steps.upload"),
            Component: UploadStep,
        },
        {
            key: ISV_MAPPER_STEP_KEY.PARSING,
            title: t("isv-connections.data-mapping.steps.parsing"),
            Component: ParsingStep,
        },
        {
            key: ISV_MAPPER_STEP_KEY.FIELDS,
            title: t("isv-connections.data-mapping.steps.fields"),
            Component: FieldsStep,
            stepProps: { setIsValidMapper },
        },
    ];
    const { isvConnectionLoadable } = useRouteISVConnection();
    const mapperResource = useMapper({ isvMapperId });

    const editMapper = useEditMapperMutation({
        isvMapperId,
        afterSuccess: () =>
            locationRouterService.navigate(isvDataMappingEditLocation, {
                accountId,
                isvConnectionId,
                isvMapperId,
                tab: ISV_MAPPER_STEP_KEY.FIELDS,
            }),
    });

    const handleSave = useCallback(
        ({ values }) =>
            editMapper.mutate(
                mapUploadedFileFormValues({
                    vendor: isvConnectionLoadable.valueMaybe()?.vendor,
                    mapper: allValues.mapper,
                    ...values,
                }),
            ),

        [allValues.mapper, editMapper, isvConnectionLoadable],
    );

    useLoadableHasValueChangedEffect(
        mapperResource.loadable,
        mapper =>
            setAllValues(values => ({
                ...values,
                ...mapToInitValues(mapper),
            })),
        [mapperResource.loadable],
    );

    const loadables = useComposeLoadablesMemoized([
        isvConnectionLoadable,
        mapperResource.loadable,
    ]);

    return (
        <LoadableRenderer
            loadable={loadables}
            hasValue={([isvConnection, initialValues]) => (
                <StepForm
                    headerSize={2}
                    title={t(
                        "isv-connections.data-mapping.header.upload-new-csv",
                    )}
                    steps={steps}
                    onCancel={onCancel}
                    allStepsProps={{
                        accountId,
                        isvConnection,
                        vendorName: mapVendorToVendorName(isvConnection.vendor),
                    }}
                    reactiveInitialValues={{
                        ...defaultParserConfigValues,
                        ...mapToInitValues(initialValues),
                    }}
                    getStepButtons={getStepButtons}
                    allValues={allValues}
                    setAllValues={setAllValues}
                    isTabVariant={false}
                    onSubmit={handleSave}
                />
            )}
        />
    );
};

export default ISVDataMappingUploadPage;
