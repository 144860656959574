import { Select } from "@/components/DesignSystem";
import { mapInstancesToOptions } from "@/components/Marketplace/helpers";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useMapLoadableMemoized } from "@/modules/loadable/index";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const InstanceSelect = ({
    availableInstancesLoadable,
    instanceId,
    ...props
}) => {
    const instancesOptionsLoadable = useMapLoadableMemoized(
        availableInstancesLoadable,
        mapInstancesToOptions,
    );

    return (
        <Select
            showSearch
            allowClear={false}
            value={instanceId}
            dataTest="integrations"
            {...getLoadableSelectProps(instancesOptionsLoadable)}
            {...props}
        />
    );
};

InstanceSelect.propTypes = {
    availableInstancesLoadable: PropTypes.object.isRequired,
    instanceId: PropTypes.number,
    onChange: PropTypes.func.isRequired,
};
