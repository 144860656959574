import WarningAlert from "@/components/Alerts/WarningAlert";
import {
    Button,
    ButtonMenu,
    Forms,
    Gap,
    H5,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { mapValuesToConnection } from "@/components/ISVConnections/ISVConnection.page";
import {
    useEditConnectionMutation,
    useRefreshSettingsMutation,
} from "@/components/ISVConnections/loadables";
import { useCompareActualAndPartitionParameters } from "@/components/ISVConnections/useCompareActualAndPartitionParameters.hook";
import { mapVendorToVendorName } from "@/components/ISVMapping/helpers";
import { useRouteISVConnection } from "@/mixpanel/hooks/useRouteISVConnection.hook";
import { useQueryLoadable, waitForValue } from "@/modules/loadable";
import { t } from "@/translations";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import { merge, size } from "lodash/fp";
import React, { useCallback, useState } from "react";

const { Form, Fields, pmValidators } = Forms;

export const usePartitionAssets = ({ accountId, partitionId }) => {
    const { partitionService } = useDic();
    const resource = useQueryLoadable(
        () =>
            isNaN(accountId) || isNaN(partitionId)
                ? waitForValue()
                : partitionService.getPartition(accountId, partitionId),
        [accountId, partitionId, partitionService],
    );
    return resource;
};

const previousValueOrNothing = (areDifferent, previousValue) =>
    previousValue && areDifferent ? `Saved value: ${previousValue}` : null;

const VendorStepFields = ({
    previousValues,
    areDifferent,
    isvConnectionId,
    isEdit = false,
}) => {
    const refrestSettingsMutation = useRefreshSettingsMutation();
    const formId = Forms.useFormId();
    const vendor = useFieldValue({ formId, name: "vendor" });

    return (
        <>
            <H5>{t("isv-connections.steps.vendor.title.channel")}</H5>
            <Fields.Input
                required
                name="channelId"
                label={t("isv-connections.steps.vendor.id")}
                validator={pmValidators.isRequired}
                disabled
                description={previousValueOrNothing(
                    areDifferent,
                    previousValues?.channelId,
                )}
            />
            <Fields.Input
                required
                name="baseUrl"
                label={t("isv-connections.steps.vendor.baseUrl")}
                validator={pmValidators.isRequired}
                disabled
                description={previousValueOrNothing(
                    areDifferent,
                    previousValues?.baseUrl,
                )}
            />
            {isEdit && isvConnectionId ? (
                <>
                    <Button
                        onClick={() =>
                            refrestSettingsMutation.mutate(isvConnectionId)
                        }
                        label={t(
                            "isv-connections.steps.vendor.resend-to-partition",
                        )}
                    />
                    <Gap size="large" />
                </>
            ) : null}

            <Fields.Input
                required
                name="collaboratorBaseUrl"
                label={t("isv-connections.steps.vendor.collaboratorBaseUrl")}
                validator={pmValidators.isRequired}
                disabled
                description={previousValueOrNothing(
                    areDifferent,
                    previousValues?.collaboratorBaseUrl,
                )}
            />

            <H5>{t("isv-connections.steps.vendor.title.sftp")}</H5>
            <Text size="medium">
                {t("isv-connections.steps.vendor.perex.sftp", {
                    vendorName: mapVendorToVendorName(vendor),
                })}
            </Text>
            <Fields.Input
                required
                name="sftpUrl"
                label={t("isv-connections.steps.vendor.sftpUrl")}
                validator={pmValidators.isRequired}
                disabled
                description={previousValueOrNothing(
                    areDifferent,
                    previousValues?.sftpUrl,
                )}
            />
            <Fields.Input
                required
                name="sftpPort"
                label={t("isv-connections.steps.vendor.sftpPort")}
                validator={Forms.validators.failOnFirst([
                    pmValidators.isRequired,
                    pmValidators.isInteger,
                ])}
                disabled
                description={previousValueOrNothing(
                    areDifferent,
                    previousValues?.sftpPort,
                )}
            />
            <Fields.Input
                required
                name="sftpUsername"
                label={t("isv-connections.steps.vendor.sftpUsername")}
                validator={pmValidators.isRequired}
                disabled
                description={previousValueOrNothing(
                    areDifferent,
                    previousValues?.sftpUsername,
                )}
            />
            <Fields.InputPassword
                required={!isEdit}
                name="sftpPassword"
                label={t("isv-connections.steps.vendor.sftpPassword")}
                placeholder={t(
                    "isv-connections.steps.vendor.sftpPassword.placeholder",
                )}
                validator={isEdit ? undefined : pmValidators.isRequired}
            />
            {/* <>
                <ConnectionTest />
                <Gap />
            </> */}
        </>
    );
};

export const VendorStepEditForm = ({
    accountId,
    initialValues,
    onSuccess,
    onCancel,
}) => {
    const { isvConnectionLoadable } = useRouteISVConnection();

    const editConnectionMutation = useEditConnectionMutation({
        connectionId: initialValues.id,
        afterSuccess: onSuccess,
    });

    const { formId, getBag, handleSubmit, setValues, setTouched } =
        Forms.useForm({
            onSubmit: ({ values }) =>
                editConnectionMutation.mutate(
                    mapValuesToConnection({
                        projectId: accountId,
                        ...initialValues,
                        ...values,
                    }),
                ),
        });

    const partitionId = useFieldValue({ formId, name: "partitionId" });

    const partitionAssetsResource = usePartitionAssets({
        accountId,
        partitionId,
    });

    const [sfxMissingDataWarning, setSfxMissingDataWarning] = useState(null);

    const showDataWarningAlert = useCallback(differentKeys => {
        setSfxMissingDataWarning(
            size(differentKeys) > 0
                ? t(
                      "isv-connections.steps.vendor.alert.sfx-parameters–changed",
                      {
                          list: differentKeys.join(", "),
                      },
                  )
                : null,
        );
    }, []);

    const { currentValues, previousValues, areDifferent } =
        useCompareActualAndPartitionParameters({
            partitionAssetsLoadable: partitionAssetsResource.loadable,
            initialValues,
            onDifferent: showDataWarningAlert,
        });

    const initialValuesWithSfxValues = merge(initialValues, currentValues);

    useSetValidatedInitialValues(
        {
            initialValues: initialValuesWithSfxValues,
            setValues,
            setTouched,
        },
        [initialValues, initialValuesWithSfxValues],
    );

    return (
        <UnityLayout>
            <UnityLayout.Content padding={[true, true, false, true]}>
                {sfxMissingDataWarning ? (
                    <div style={{ maxWidth: "620px" }}>
                        <WarningAlert visible message={sfxMissingDataWarning} />
                    </div>
                ) : null}
                <Form formId={formId} onSubmit={handleSubmit}>
                    <VendorStepFields
                        form={{
                            getBag,
                            setValues,
                        }}
                        previousValues={previousValues}
                        areDifferent={areDifferent}
                        isEdit
                        isvConnectionId={isvConnectionLoadable.valueMaybe()?.id}
                    />
                </Form>
            </UnityLayout.Content>
            <UnityLayout.Footer
                actionButtonsLeft={
                    <ButtonMenu
                        buttons={[
                            {
                                formId,
                                label: t("general.save"),
                                type: "primary",
                            },
                            {
                                label: t("general.cancel"),
                                onClick: onCancel,
                                type: "text",
                            },
                        ]}
                    />
                }
            />
        </UnityLayout>
    );
};

export default VendorStepFields;
