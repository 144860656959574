import { useDic } from "@/components/Dic/useDic.hook";
import { ISV_CONN_TABS } from "@/components/ISVConnections/ISVConnectionsOverview.page";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import { Badge } from "@pricefx/unity-components";
import { always, filter, identity, pipe, prop } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useState } from "react";
import DateRangeFilter from "../DateRangeFilter/DateRangeFilter.component";
import FormattedDateTime from "../DateTime/FormattedDateTime";
import { Gap, H4, Link, UnityLayout } from "../DesignSystem";
import { fieldTypes } from "../DesignSystem/Table/constants";
import { addLocalFilteredAlerts } from "../EventSchedulers/EventSchedulers.page";
import { useDetailDrawerState } from "../hooks/useDetailDrawerState.hook";
import { mapVendorToVendorName } from "../ISVMapping/helpers";
import { useMappersListQuery } from "../ISVMapping/loadables";
import { MAPPER_DIRECTION } from "../ISVMapping/steps/General.step";
import { TableLocalFiltered } from "../TableLocalFiltered";
import { ISVErrorsModal } from "./ISVErrorsModal";
import { useConnectionErrorsQuery } from "./loadables";

export const createColumns = ({ onShowDetail, onShowLogHistory } = {}) => [
    {
        type: fieldTypes.TEXT,
        label: t("isv.overview.overview.column.mapper"),
        name: "name",
        render: (text, record) => {
            if (record.lastSyncStatus && record.lastSyncStatus !== "SUCCESS") {
                return (
                    <Link onClick={() => onShowLogHistory(record)}>{text}</Link>
                );
            } else {
                return text;
            }
        },
    },
    {
        type: fieldTypes.DATETIME,
        label: t("isv.overview.overview.column.time"),
        name: "lastSync",
        render: date => <FormattedDateTime>{date}</FormattedDateTime>,
    },
    {
        type: fieldTypes.TEXT,
        label: t("isv.overview.overview.column.status"),
        name: "lastSyncStatus",
        canFilter: false,
        canSort: true,
    },
    {
        type: fieldTypes.TEXT,
        label: t("isv.overview.overview.column.errors"),
        name: "detectedErrors",
        canFilter: false,
        canSort: true,
        render: (text, record, _, __, ___, prefix) => {
            if (record.lastSyncStatus === "SUCCESS_WITH_WARNINGS") {
                return (
                    <div
                        onClick={() => onShowDetail(record)}
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        {prefix}
                        {text}
                    </div>
                );
            } else {
                return text;
            }
        },
    },
];

// TODO: Not included for now @dslavik
const ISVFilter = ({
    activeFilter = { from: undefined, to: undefined },
    setActiveFilter = () => {
        // eslint-disable-next-line no-debugger
        debugger;
    },
}) => {
    return (
        <>
            <Gap />
            <DateRangeFilter
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
            />
            <Gap />
        </>
    );
};

const ISVErrorsBadge = ({
    isvConnectionId,
    direction,
    active,
    setActive,
    reloadToken,
}) => {
    const errorsQuery = useConnectionErrorsQuery({
        isvConnectionId,
        type: direction,
        reloadToken,
    });

    const errorCountMaybe = errorsQuery.loadable.valueMaybe()?.detectedErrors;

    if (!errorCountMaybe) return null;

    return (
        <Badge
            type="critical"
            label={errorCountMaybe}
            active={active}
            onClick={(...args) => {
                setActive(!active);
            }}
            // label={t("event-wf.form.listeners.errors", { count: rowsWithErrorCount, })}
        />
    );
};

// http://localhost:8082/#/customer/3953/isv-connections/703/overview?tab=OVERVIEW
export const ISVConnectionOverview = ({
    visible,
    accountId,
    isvConnectionId,
    isvConnectionLoadable,
    reloadToken,
}) => {
    const { locationRouterService, accountAppLocations } = useDic();
    const { show, hide, record } = useDetailDrawerState();
    const [showJustErrors, setShowJustErrors] = useState(false);

    const vendorMaybe = isvConnectionLoadable.valueMaybe()?.vendor;
    const vendorName = mapVendorToVendorName(vendorMaybe);

    const query = useMappersListQuery({ isvConnectionId, reloadToken });

    const directionPredicate =
        mapperDirection =>
        ({ type }) =>
            type === mapperDirection;
    const errorsPredicate = showJustErrors ? prop("detectedErrors") : identity;
    const pfxToEnableDataSource = pipe(
        filter(directionPredicate(MAPPER_DIRECTION.DOWNLOAD)),
        filter(errorsPredicate),
        addLocalFilteredAlerts([
            {
                prop: "detectedErrors",
                getVisible: prop("detectedErrors"),
                getType: always("CRITICAL"),
                // getTooltip: always(t("general.unable-to-run")),
            },
        ]),
    )(query.loadable.valueMaybe() ?? []);
    const enableToPfxDataSource = pipe(
        filter(directionPredicate(MAPPER_DIRECTION.UPLOAD)),
        filter(errorsPredicate),
        addLocalFilteredAlerts([
            {
                prop: "detectedErrors",
                getVisible: prop("detectedErrors"),
                getType: always("CRITICAL"),
                // getTooltip: always(t("general.unable-to-run")),
            },
        ]),
    )(query.loadable.valueMaybe() ?? []);

    const onShowLogHistory = record => {
        locationRouterService.navigate(
            accountAppLocations.isvConnectionsOverviewLocation,
            {
                tab: ISV_CONN_TABS.LOG_HISTORY,
                camelExchangeId: record.lastExchangeId,
            },
        );
    };

    return (
        <UnityLayout>
            <UnityLayout.Content
                padding={[false, true]}
                // stretch={false}
            >
                <Gap />
                {/* <ISVFilter /> TODO: Not included for now @dslavik */}
                <H4>
                    {t("isv.overview.overview.pfx-to-enable")}{" "}
                    <ISVErrorsBadge
                        isvConnectionId={isvConnectionId}
                        direction={MAPPER_DIRECTION.DOWNLOAD}
                        active={showJustErrors}
                        setActive={setShowJustErrors}
                    />
                </H4>
                <TableLocalFiltered
                    rowKey="id"
                    loading={isLoading(query.loadable)}
                    dataSource={pfxToEnableDataSource}
                    columns={createColumns({
                        onShowDetail: show,
                        onShowLogHistory,
                    })}
                    padding={false}
                />
                <H4>
                    {t("isv.overview.overview.enable-to-pfx")}{" "}
                    <ISVErrorsBadge
                        isvConnectionId={isvConnectionId}
                        direction={MAPPER_DIRECTION.UPLOAD}
                        active={showJustErrors}
                        setActive={setShowJustErrors}
                    />
                </H4>
                <TableLocalFiltered
                    rowKey="id"
                    loading={isLoading(query.loadable)}
                    dataSource={enableToPfxDataSource}
                    columns={createColumns({
                        onShowDetail: show,
                        onShowLogHistory,
                    })}
                    padding={false}
                />
                <ISVErrorsModal record={record} onClose={hide} />
            </UnityLayout.Content>
        </UnityLayout>
    );
};

ISVConnectionOverview.propTypes = {
    visible: PropTypes.bool.isRequired,
    accountId: PropTypes.string.isRequired,
    isvConnectionId: PropTypes.string.isRequired,
    isvConnectionLoadable: PropTypes.object.isRequired,
    reloadToken: PropTypes.string.isRequired,
};
