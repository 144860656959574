import { Select } from "@/components/DesignSystem";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const PartitionSelect = ({
    partitionId,
    partitionsOptionsLoadable,
    ...props
}) => {
    return (
        <Select
            showSearch
            allowClear={false}
            value={partitionId}
            dataTest="partitions"
            {...getLoadableSelectProps(partitionsOptionsLoadable)}
            {...props}
        />
    );
};

PartitionSelect.propTypes = {
    partitionId: PropTypes.number,
    partitionsOptionsLoadable: PropTypes.object.isRequired,
};
