const en = {
    "app-title": "PlatformManager",
    "entity.action.edit": "Edit",
    "entity.action.delete": "Delete",
    "login.title": "Login",
    "login.placeholder.username": "Enter your username",
    "login.placeholder.password": "Enter your password",
    "login.button.login": "Login",
    "login.button.o365": "Login with O365",
    "login.verify.scan-qr-code": "Scan this QR code with your verification app",
    "login.verify.scan-qr-code-problem-question": "Cannot scan the code?",
    "login.verify.add-qr-code-details":
        "You can add the code to your application manually using the following details:",
    "login.verify.account": "Account:",
    "login.verify.key": "Key:",
    "login.verify.time-based": "Time based:",
    "login.verify.yes": "Yes",
    "login.verify.time-warning":
        "Make sure that your local time has the same minutes as the server time. Server time: {time}",
    "login.verify.verification-code": "Verification code",
    "login.verify.placeholder.verification-code": "Verification code",
    "login.verify.button.verify": "Verify",
    "login.new-password.title": "Set New Password",
    "login.new-password.subtitle": "Password change is required.",
    "login.new-password.placeholder.password": "Password",
    "login.new-password.placeholder.repeat-password": "Repeat password",
    "login.new-password.button.set-password": "Set Password",
    "login.input.label.username": "Username",
    "login.input.label.password": "Password",
    "login.error.message.message": "Invalid login credentials",
    "login.error.LOGIN.message.description":
        "You have entered incorrect username or password",
    "login.error.OTP.message.description":
        "You have entered incorrect verification code",
    "login.error.FIRST_PASS.message.description":
        "You have entered incorrect password",
    "login.error.NEW_PASS.message.description":
        "The reset of password was not successful",
    "login.footer.need-help-question.text": "Need help?",
    "login.footer.see-documentation.text": "See our documentation",

    "deprecated.title": "No longer developed",
    "deprecated.warning.accelerator.text":
        "This accelerator is no longer actively developed. You can proceed with installation at your own risk.",
    "deprecated.accelerator.text":
        "This accelerator is no longer actively developed. You can proceed with installation at your own risk.",

    "route.default": " ",
    "route.capacity": "Availability",
    "route.overview": "Overview",
    "route.welcome": "Welcome",
    "route.accounts": "Accounts",
    "route.integrations": "Integrations",
    "route.customers": "Customers",
    "route.nodes": "Nodes",
    "route.updates": "Updates",
    "route.remote-login": "Remote Login",
    "route.customers.new": "New",
    "route.customers.edit": "Edit",

    "route.customer": "Overview",
    "route.customer.settings": "Settings",
    "route.customer.settings.user-and-group-access": "Settings",
    "route.administration": "Administration",

    "route.mapper": "Mapper",
    "route.templates": "Templates",
    "route.templates.new": "Add template to instance",
    "route.solutions.deploy": "Deploy accelerator",
    "route.servers": "Servers",
    "route.settings": "Settings",
    "route.profile": "Profile",
    "route.general": "General",

    "route.connections": "Connections",
    "route.instances": "IM Instances",

    "route.packages": "Accelerator Packages",
    "route.partitions": "Partitions",
    "route.request-partition": "Request Partition",
    "route.marketplace": "Marketplace",

    "route.partition.dashboard": "Dashboard",

    "route.instance.dashboard": "Dashboard",
    "route.instance.deploy": "Deploy",
    "route.instance.connections": "Connections",
    "route.instance.integrations": "Integrations",
    "route.instance.mappers": "Mappers",
    "route.instance.mappers.edit": "Edit Mapper",
    "route.instance.settings": "Settings",
    "route.instance": "Instance",

    "route.integration": "Integration",
    "route.uploads": "Data Uploads",
    "route.alerts": "Alerts",
    "route.activity-log": "Activity Log",
    "route.uploads.history": "History",
    "route.uploads.send": "Upload",
    "route.uploads.new": "New",
    "route.tasks": "Tasks",
    "route.global-alerts": "Global Alerts",
    "route.global-alert-rules": "Global Alert Rule",
    "route.global-alert-rules.new": "New Global Alert Rule",

    "notification-rule.save.success": "The Notification Rule {name} was saved",
    "notification-rule.delete.success":
        "The Notification Rule {name} was deleted",

    "location.accountApp": "Account",
    "location.accountApp.eventOrchestration": "Event Orchestration",
    "location.accountApp.eventOrchestration.eventSchedulers": "Schedulers",
    "location.accountApp.eventOrchestration.eventSchedulers.create":
        "Create Scheduler",
    "location.accountApp.eventOrchestration.eventSchedulers.edit":
        "Edit Scheduler",
    "location.accountApp.eventOrchestration.eventSchedulers.view": "Scheduler",
    "location.accountApp.eventOrchestration.eventWorkflows": "Workflows",
    "location.accountApp.eventOrchestration.eventWorkflows.new":
        "New Event Workflow",
    "location.accountApp.eventOrchestration.eventWorkflows.create":
        "Create Event Workflow",
    "location.accountApp.eventOrchestration.eventWorkflows.edit":
        "Edit Event Workflow",
    "location.accountApp.eventOrchestration.eventWorkflows.view":
        "Event Workflow",
    "location.accountApp.eventOrchestration.eventLogs": "Logs",
    "location.accountApp.isvConnections": "ISV Connections",
    "location.accountApp.isvConnections.new": "New ISV Connection",
    "location.accountApp.isvConnections.create": "Create Connection",
    "location.accountApp.isvMappers.create": "Create Data Mapping",
    "location.accountApp.isvMappers.new": "New Data Mapping",
    "location.accountApp.isvMappers.edit": "Edit Data Mapping",
    "location.accountApp.isvConnections.isvConnectionsOverview":
        "ISV Connection Overview",
    "isv.overview.tab.overview": "Overview",
    "isv.overview.tab.log-history": "Log history",
    "isv.overview.overview.pfx-to-enable": "Pricefx to Enable",
    "isv.overview.overview.enable-to-pfx": "Enable to Pricefx",
    "isv.overview.overview.column.mapper": "Mapper",
    "isv.overview.overview.column.time": "Time",
    "isv.overview.overview.column.errors": "Errors Detected",
    "isv.overview.overview.column.status": "Status",
    "isv.overview.overview.errors.column.timestamp": "Timestamp",
    "isv.overview.overview.errors.column.message": "Message",
    "isv.overview.overview.errors.column.payload": "Payload",
    "location.accountApp.isvConnections.isvConnectionsSettings": "Settings",
    "location.accountApp.isvConnections.isvDataMappingList": "Data Mapping",
    "location.accountApp.isvConnections.isvDataMappingCreation":
        "New Data Mapping",
    "location.accountApp.isvConnections.isvDataMappingEdit":
        "Edit Data Mapping",
    "location.accountApp.isvConnections.isvDataMappingEdit.isvDataMappingUpload":
        " ",
    "location.accountApp.admin": "User Rights",
    "location.accountApp.admin.users": "Users",
    "location.accountApp.admin.groups": "User Groups",
    "location.accountApp.notifications": "Notifications",
    "location.accountApp.notifications.rules": "Alert Rules",
    "location.accountApp.notifications.notification-rules":
        "Notification Rules",
    "location.accountApp.alerts.rules": "Alert Rules",
    "location.accountApp.alerts.rules.new": "New Alert Rule",
    "location.accountApp.alerts.rules.edit": "Edit Rule",
    "location.accountApp.logs": "Logs",
    "location.accountApp.logs.activityLog": "Activity Log",
    "location.accountApp.logs.deploymentLogs": "Deployment Log",
    "location.accountApp.reports": "Reports",
    "location.accountApp.reports.new": "Schedule Report",
    "location.accountApp.reports.edit": "Edit Scheduled Report",
    "location.accountApp.dashboard": "Dashboard",
    "location.accountApp.upgrades": "Instance Upgrade",
    "location.accountApp.upgrades.new": "Instance Upgrade Request",
    "location.accountApp.upgrades.edit": "Instance Upgrade Request",
    "location.accountApp.partitions": "Partitions",
    "location.accountApp.partitions.new": "New Partition",
    "location.accountApp.partitions.request-completed":
        "Partition Request Completed",
    "location.accountApp.admin.assets": "Asset Groups",
    "location.accountApp.admin.permissions": "Business Roles",
    "location.accountApp.contactsManagement": "Contacts Management",
    "location.accountApp.workflows": "Workflows",

    "location.accountApp.sso": "Single Sign-On",

    "location.accountApp.partitions.partition.overview": "Partition Overview",
    "location.accountApp.partitions.partition.credentials": "Credentials",
    "location.accountApp.partitions.partition.data-management":
        "Data Management",
    "location.accountApp.partitions.partition.data-management.new-download":
        "New Data Download",
    "location.accountApp.partitions.partition.data-management.new-connection":
        "Create Connection",
    "location.accountApp.partitions.partition.data-management.edit-connection":
        "Edit Connection",
    "location.accountApp.partitions.partition.data-management.upload-sftp-management":
        "SFTP Management",
    "location.accountApp.partitions.partition.uploads": "Data Upload",
    "location.accountApp.partitions.partition.upload-logs": "Data Upload Logs",
    "location.accountApp.partitions.partition.general-admin": "General Admin",
    "location.accountApp.partitions.partition.uploads.new": "Data Upload",
    "location.accountApp.partitions.partition.uploads.history": "History",
    "location.accountApp.partitions.partition.uploads.edit": "Edit",
    "location.accountApp.partitions.partition.clone": "Clone partition",
    "location.accountApp.partitions.partition.move": "Move partition",
    "location.accountApp.partitions.partition.featureFlags":
        "Partition Feature Flags",

    "location.accountApp.instances": "Integrations",
    "location.accountApp.instances.new": "New Integration",
    "location.accountApp.instances.copy": "Copy instance",
    "location.accountApp.instances.instance.overview": "Integration Overview",
    "location.accountApp.instances.instance.integrations": "Routes",
    "location.accountApp.instances.instance.integrations.new": "New Route",
    "location.accountApp.instances.instance.integrations.edit": "Edit",
    "location.accountApp.instances.instance.mappers": "Mappers",
    "location.accountApp.instances.instance.mappers.new": "New Mapper",
    "location.accountApp.instances.instance.mappers.edit": "Edit",
    "location.accountApp.instances.instance.connections": "Connections",
    "location.accountApp.instances.instance.class": "Classes",
    "location.accountApp.instances.instance.class.new": "New Class",
    "location.accountApp.instances.instance.connections.edit":
        "Edit Connection",
    "location.accountApp.instances.instance.connections.new": "New Connection",
    "location.accountApp.instances.instance.settings": "Settings",
    "location.accountApp.instances.instance.copy": "Copy",
    "location.accountApp.partitions.partition.CancelDeployment":
        "Package deployment canceled",

    "location.accountApp.instances.instance.filters": "Filters",
    "location.accountApp.instances.instance.filters.edit": "Edit Filter",
    "location.accountApp.instances.instance.filters.new": "New Filter",

    "location.accountApp.instances.instance.certificates": "Certificates",
    "location.accountApp.instances.instance.resources": "Resources",
    "location.accountApp.instances.instance.resources.new": "New Resource",
    "location.accountApp.instances.instance.certificates.new":
        "New Certificate",
    "location.accountApp.instances.instance.certificates.edit":
        "Edit Certificate",
    "location.accountApp.instances.instance.sftp-servers": "SFTP Servers",
    "location.accountApp.instances.instance.sftp-servers.user-management":
        "User management",
    "location.accountApp.instances.instance.version-history": "Version History",
    "version-history.title": "Version History",
    "version-history.perex":
        "For more information about Release Notes visit our",
    "version-history.candidate-version": "Candidate version",
    "version-history.knowledge-base": "Knowledge base",
    "location.accountApp.api-token": "Account API Token",
    "sftp-servers.list.column.name": "Name",
    "sftp-servers.list.column.address": "Address",
    "sftp-servers.list.column.port": "Port",
    "sftp-servers.list.column.status": "Status",
    "sftp-servers.list.column.status.type.STARTED": "Started",
    "sftp-servers.list.column.status.type.STOPPED": "Stopped",
    "sftp-servers.list.column.status.type.STARTING": "Starting",
    "sftp-servers.list.column.status.type.STOPPING": "Stopping",
    "sftp-servers.list.action.stop-server": "Stop Server",
    "sftp-servers.list.action.starting-server": "Starting the server {name}",
    "sftp-servers.list.action.stopping-server": "Stopping the server {name}",
    "sftp-servers.list.action.stop-server.confirm":
        "Do you really want to stop the server?",
    "sftp-servers.list.action.start-server": "Start Server",
    "sftp-servers.list.action.start-server.confirm":
        "Do you want to start the server?",
    "sftp-servers.list.action.show-server-log": "Show Server Log",
    "sftp-servers.list.action.user-management": "User Management",

    "sftp-servers.user-management.saved": "The user has been saved.",
    "sftp-servers.user-management.created": "The user has been created.",
    "sftp-servers.user-management.deleted": "The user has been deleted.",
    "sftp-servers.user-management.ssh-key.added": "SSH key has been added.",
    "sftp-servers.user-management.modal.title": "User Management",
    "sftp-servers.user-management.modal.add-user": "Add User",
    "sftp-servers.user-management.columns.username": "Username",
    "sftp-servers.user-management.columns.name": "Name",
    "sftp-servers.user-management.info.system-user": "System User",
    "sftp-servers.user-management.action.change-password": "Change Password",
    "sftp-servers.user-management.action.remove-user": "Remove User",
    "sftp-servers.user-management.fields.name.label": "Username",
    "sftp-servers.user-management.fields.name.placeholder": "Enter Username",
    "sftp-servers.user-management.fields.password.label": "Password",
    "sftp-servers.user-management.fields.password.placeholder":
        "Enter Password",
    "sftp-servers.user-management.clear-password": "Clear Password",
    "sftp-servers.user-management.read-only": "Access Rights",
    "sftp-servers.user-management.read-only.full-permission": "Full permission",
    "sftp-servers.user-management.read-only.read-only": "Read only",
    "sftp-servers.user-management.add-ssh-key": "+ Add SSH Key",
    "sftp-servers.user-management.fields.key.label": "SSH Public key",
    "sftp-servers.user-management.fields.key.tooltip": "SSH Public key",
    "sftp-servers.user-management.add-key": "Add SSH key",
    "sftp-servers.user-management.perex":
        "Specify a name and authentication method for the user. Password or SSH Public Key is mandatory.",
    "sftp-servers.user-management.perex-no-ldap":
        "Specify a name and password for the user.",
    "sftp.add-user-modal.title": "SFTP - Add New User",
    "sftp.add-user-modal.name.label": "Name",
    "sftp.add-user-modal.name.placeholder": "Enter Name",
    "sftp-servers.user-management.fields.name.validation.unique":
        "Username already exists",
    "sftp-servers.user-management.fields.name.validation.maxLength":
        "Username must be shorter (together with the prefix) than {maxLength} characters.",
    "sftp.add-user-modal.addition.success":
        "User permissions were successfully saved",
    "sftp.add-user-modal.password-change.success":
        "User password was successfully changed",
    "sftp.add-user-modal.confirm.title": "Remove User",
    "sftp.add-user-modal.confirm.message":
        "Do you really want to remove the user {username}?",

    "sftp.change-password.title": "Change Password",
    "sftp.change-password.password.label": "Password",
    "sftp.change-password.password.placeholder": "Enter Password",

    "sftp-servers.email-notifications.title": "Email notification",
    "sftp-servers.email-notifications.add-email": "Add another email",
    "sftp-servers.email-notifications.perex":
        "Fill in email address(es) to get notifications when the upload of your file to the partition is finished so that you don’t have to log in to PfM/partition to check the status of the upload.",
    "sftp-servers.email-notifications.fields.emails.label":
        "Other Notification Emails",

    "feature-flags.mass-edit.title": "Mass Edit Flag: {flag}",
    "feature-flags.mass-edit.table.column.id": "Id",
    "feature-flags.mass-edit.table.column.value": "Value",
    "feature-flags.mass-edit.confirm.title": "Apply Settings",
    "feature-flags.mass-edit.confirm.message":
        "Do you want to apply settings changed on feature flags?",

    "feature-flags.not-available-partitions.title": "Not Available Partitions",
    "feature-flags.not-available-partitions.column.partitionName": "Partition",
    "feature-flags.not-available-partitions.column.projectName": "Account",
    "feature-flags.not-available-partitions.column.status": "Status",

    "feature-flag.paritions-with-flag.partitions-loading":
        "Loading the partitions with the selected flag might take several minutes.",
    "feature-flag.paritions-with-flag.info.not-all-available":
        "Some partitions are not available.",
    "feature-flag.paritions-with-flag.info.list-of-partititions":
        "List of partitions.",
    "feature-flag.paritions-with-flag.table.column.partitionName": "Partition",
    "feature-flag.paritions-with-flag.table.column.projectName": "Account",
    "feature-flag.paritions-with-flag.table.column.value": "Value",

    "location.administrationApp": "Administration",
    "location.administrationApp.profile": "Profile Settings",
    "location.administrationApp.rights.users": "Users",
    "location.administrationApp.rights.users.edit": "Edit",
    "location.administrationApp.rights.users.new": "New",
    "location.administrationApp.rights.groups": "User Groups",
    "location.administrationApp.rights.groups.newGroup": "New",
    "location.administrationApp.rights.groups.editGroup": "Edit",
    "location.administrationApp.accountsManagement": "Accounts Management",
    "location.administrationApp.accountsManagement.account":
        "Account Management",
    "location.administrationApp.logs": "Logs",
    "location.administrationApp.logs.activityLog": "Activity Log",
    "location.administrationApp.loggers": "Loggers",
    "location.administrationApp.globalAlerts": "Global Alerts",
    "location.administrationApp.globalAlerts.rules": "Rules",
    "location.administrationApp.globalAlerts.rules.new": "New",
    "location.administrationApp.globalAlerts.rules.edit": "Edit",

    "location.administrationApp.constants": "Constants",
    "location.administrationApp.constants.releaseVersions": "Release Versions",
    "location.administrationApp.constants.partitionUpgrades":
        "Partition Upgrades",
    "location.administrationApp.constants.imInstancesLimit":
        "IM Instances Limit",
    "location.administrationApp.constants.partitionLimit": "Partition Limit",
    "location.administrationApp.constants.engSupportUsers": "Eng Support Users",
    "location.administrationApp.constants.enableDeeplinks": "Enable Deeplinks",

    "im-instances-limit.column.project-name": "Project name",
    "im-instances-limit.column.max": "Max integration instances",
    "im-instances-limit.column.existing": "Existing instances",
    "im-instances-limit.edit.title": "Edit {account} IM instances limit",
    "im-instances-limit.edit.max.label": "Max Integration Instances",
    "im-instances-limit.edit.unlimited.label": "Unlimited",
    "location.administrationApp.integrationManagerVersions":
        "IntegrationManager Versions",
    "location.administrationApp.featureFlags": "Feature Flags",
    "location.administrationApp.rights": "User Rights",
    "location.administrationApp.rights.assetRoles": "Asset Groups",
    "location.administrationApp.rights.atlassianGroups": "Atlassian Groups",
    "location.administrationApp.rights.businessRoles": "Business Roles",
    "location.administrationApp.sso": "Single Sign-On",
    "location.administrationApp.adminReports": "Admin Reports",
    "location.administrationApp.pmFeatureFlags": "PM Feature Flags",
    "location.overviewApp.integrationArchives": "Integration Archives",
    "location.marketplaceApp": "Marketplace",
    "location.marketplaceApp.marketplace": "Marketplace",
    "location.marketplaceApp.marketplace.integrationTemplateDetail": "",
    "location.marketplaceApp.integrationTemplates": "Integration Templates",
    "location.marketplaceApp.integrationTemplates.integrationTemplate":
        "Integration Template",
    "location.marketplaceApp.integrationTemplates.integrationTemplateDetail":
        "Integration Template Detail",
    "location.marketplaceApp.integrationTemplates.integrationOfferDetail":
        "Integration Offer Detail",
    "location.marketplaceApp.solutionTemplates": "Accelerators",
    "location.marketplaceApp.solutionTemplates.solutionTemplate": "Accelerator",
    "location.marketplaceApp.solutionTemplates.solutionTemplateDetail":
        "Accelerator Detail",
    "location.marketplaceApp.solutionTemplates.solutionOfferDetail":
        "Solution Offer Detail",
    "location.marketplaceApp.templatesManagement": "Templates Management",
    "location.marketplaceApp.templatesManagement.templateVisibility":
        "Template Visibility",
    "location.marketplaceApp.templatesManagement.templateManagementDetail":
        "Templates Management - Detail",
    "location.marketplaceApp.packages": "Accelerator Packages",
    "location.marketplaceApp.packages.packagePartition.package":
        "Accelerator Package",
    "location.marketplaceApp.packages.packageDetail":
        "Accelerator Package Detail",
    "location.marketplaceApp.packages.packageOfferDetail":
        "Accelerator Offer Detail",
    "location.marketplaceApp.nonApprovedTemplates": "Non Approved",
    "location.marketplaceApp.nonApprovedTemplates.nonApprovedOfferDetail":
        "Non Approved Offer Detail",
    "marketplace.non-approved.header": "Non Approved Accelerators",
    "location.marketplaceApp.offers": "Offers",
    "location.marketplaceApp.offers.offerEdit": "Edit",
    "location.marketplaceApp.offers.offerCreate": "Create",

    "location.overviewApp": "Overview",
    "location.overviewApp.dashboard": "Dashboard",
    "location.overviewApp.accounts": "Accounts",
    "location.overviewApp.partitions": "Partitions",
    "location.overviewApp.integrations": "Integrations",
    "location.overviewApp.integrations.integrationOverview.versionHistory":
        "Version History",
    "location.overviewApp.nodes": "Nodes",
    "location.overviewApp.updates": "Updates",
    "location.overviewApp.remoteLogin": "Remote Login",
    "location.overviewApp.servers": "Servers",
    "location.overviewApp.upgrades": "Upgrades",
    "location.overviewApp.upgrades.slots": "Upgrade slots",
    "location.overviewApp.clusters": "Instances",
    "location.overviewApp.clusters.clusterAdmin": "Instance Admin",

    "location.productEnhancementApp": "Product Requests",
    "location.productEnhancementApp.allRequests": "All Requests",
    "location.productEnhancementApp.myRequests": "My Requests",

    "location.profile": "Profile",

    "location.workflowsApp": "Workflows",
    "location.workflowsApp.list": "Workflows",
    "location.workflowsApp.my-approvals": "My Approvals",

    "header-dropdown.profile": "Profile Settings",
    "header-dropdown.availability": "Availability",
    "header-dropdown.my-approvals": "My Approvals",
    "header-dropdown.notifications": "Notifications",
    "header-dropdown.platform-documentation": "PlatformManager Documentation",
    "header-dropdown.logout": "Logout",
    "header.customers": "Accounts",

    "tasks.menu.STARTED": "Running Tasks",
    "tasks.menu.FAILED": "Failed Tasks",
    "tasks.menu.DONE": "Finished Tasks",

    "header.notifications.title": "Notifications",
    "header.notifications.item.today-at": "Today at",
    "header.notifications.item.yesterday-at": "Yesterday at",
    "notifications.menu.title": "Notifications",
    "notifications-list.column.type": "Type",
    "notifications-list.column.status": "Status",
    "notifications-list.column.message": "Message",
    "notifications-list.column.createdAt": "Created at",
    "notifications-list.column.notifiedAt": "Notified at",
    "notifications-list.column.readAt": "Read at",

    "header.help.title": "Help",
    "header.footer.view-all.title": "View all",
    "notifications.mark-all-as-read": "Mark all as read",
    "notifications.empty-info": "You have no notifications.{br}Enjoy your day.",
    "notifications.message.UPLOAD_FILE": "Upload file",
    // "notifications.message.COPY_EVENT_WORKFLOW": "Copy workflow {name} {status}",
    "notifications.message.COPY_EVENT_WORKFLOW":
        "{name} {status, select, DONE {was copied successfully} FAILED {copy cannot be created} other {workflow copy {status}}}",
    "notifications.message.DOWNLOAD_FILE": "Download",
    "notifications.message.DOWNLOAD_FILE_EXTERNAL_STORAGE":
        "Download of a file to external storage",
    "notifications.message.SOLUTION_DELETE": "Solution deletion",
    "notifications.message.SOLUTION_DEPLOY": "Solution deployment",
    "notifications.message.PACKAGE_ROLLBACK":
        "Package rollback of {templateName} on {partitionName} finished",
    "notifications.message.PACKAGE_TERMINATION":
        "Package termination of {templateName} finished",
    "notifications.message.TRANSPORT_IM_ENVIRONMENT":
        "Transport of IM {instanceName} from {sourceBranch} to {targetBranch} environment is prepared",
    "notifications.message.INTEGRATION_TEMPLATE_DEPLOY":
        "Integration template deployment",
    "notifications.message.INSTANCE_DELETED":
        "IM instance {instanceName} has been deleted",
    "notifications.message.workflow.approval": "{type} workflow to approve",
    "notifications.message.workflow_download.delete.failed":
        "The {type} {name} has not been deleted. These event workflows/schedulers use the {type} and need to be updated first:",
    "notifications.message.workflow_download.delete.success":
        "The {type} {name} was deleted",
    "dashboard.nav-link.instances": "Integrations",
    "dashboard.nav-link.servers": "Servers",
    "dashboard.nav-link.customers": "Accounts",
    "dashboard.nav-link.nodes": "Nodes",
    "dashboard.nav-link.partitions": "Partitions",
    "dashboard.nav-link.updates": "Updates",
    "dashboard.nav-link.remote-login": "Remote Login",
    "dashboard.header-partitions": "Partitions",
    "dashboard.header-integrations": "Integrations",
    "dashboard.parent-account": "Parent account",
    "dashboard.header-templates": "Templates:",

    "dashboard-instances.header.customer": "Account",
    "dashboard-instances.header.name": "Instance Name",
    "dashboard-instances.header.server": "Server",
    "dashboard-instances.header.url": "URL",
    "dashboard-instances.header.ports": "Ports",
    "dashboard-instances.header.environment": "Environment",
    "dashboard-instances.header.cloudProvider": "Cloud Provider",
    "dashboard-instances.header.server-type": "Type",
    "dashboard-instances.header.monitoring": "Instance monitoring",
    "dashboard-instances.header.report-monitoring": "Report monitoring",
    "dashboard-instances.header.report-monitoring-recipients":
        "Report monitoring recipients",
    "dashboard-instances.header.health": "Instance health",
    "dashboard-instances.filed.all": "All",
    "dashboard-instances.filed.up": "Up",
    "dashboard-instances.filed.down": "Down",
    "dashboard-instances.filed.enabled": "Enabled",
    "dashboard-instances.filed.disabled": "Disabled",
    "dashboard-instances.header.partition": "Partition Name",
    "dashboard-instances.header.integration-tags": "ELK Tags",

    "dashboard-servers.header.server": "Server",
    "dashboard-servers.header.ports": "Ports",
    "dashboard-customers.header.customers": "Accounts",
    "dashboard-nodes.header.nodes": "Nodes",
    "dashboard-nodes.header.hostName": "Host Name",

    "customer-list.title.Customers": "Accounts",
    "customer-list.header.account-name": "Account Name",
    "customer-list.header.account-owner": "Account Owner",
    "customer-list.header.account-num": "Account Number",
    "customer-list.header.type": "Type",
    "customer-list.header.parent": "Parent Account",
    "customer-list.header.territory": "Territory",
    "customer-list.header.changed": "Changed",
    "customer-list.header.actions": "Actions",
    "customer-list.header.account-status": "Account Status",

    "customer-list.button.edit": "Edit",
    "customer-list.button.delete": "Delete",
    "customer-list.menu.general": "General",
    "customer-list.menu.user-rights": "User Rights",

    "update-checker.message": "New version available",
    "update-checker.description":
        "Reload PlatformManager to get the latest version.",
    "nodes-list.title.list-of-nodes": "All Nodes",
    "nodes-list.header.cluster-name": "Instance",
    "nodes-list.header.node-name": "Node Name",
    "nodes-list.header.major-version": "Version",
    "nodes-list.header.release-name": "Release Name",
    "nodes-list.header.build-id": "Build ID",
    "nodes-list.header.git-commit": "Git",
    "nodes-list.header.ip": "IP",
    "nodes-list.header.host-name": "Host Name",
    "nodes-list.header.checkin-date": "Check-in date",
    "nodes-list.header.actions": "Actions",
    "nodes-list.header.uptime": "Uptime",
    "nodes-list.header.status": "Status",
    "nodes-list.header.location": "Location",
    "nodes-list.header.type": "Type",
    "nodes-list.modal.remove.title": "Remove Node from List",
    "nodes-list.modal.remove.message":
        "Do you really want to remove the node {nodeName}, last checked in at {lastCheckin} from this list? Note that the node will not be deleted, only the record pointing to it will be removed.",
    "nodes-list.inactive.header": "Inactive nodes",
    "nodes-list.header.remove-cluster.title": "Remove Whole Instance from List",
    "nodes-list.modal.remove-cluster.title": "Remove Instance from List",
    "nodes-list.modal.remove-cluster.message":
        "Do you really want to remove the instance {clusterName} from this list? Note that the instance will not be deleted, only the records pointing to it will be removed.",

    "customer-partitions-list.header.majorVersion": "Major Version",
    "customer-partitions-list.header.contactEmails": "Contact emails: {emails}",
    "customer-partitions-list.header.modules": "Modules: {modules}",
    "customer-partitions-list.header.customSchema":
        "Host: {host}, Database: {database}",
    "customer-partitions-list.header.status": "Status",
    "customer-partitions-list.header.last-login": "Last Login",
    "customer-partitions-list.header.marked-to-delete": "Marked To Delete",
    "customer-partitions-list.modal.delete.title": "Delete Partition",
    "customer-partitions-list.modal.delete.message":
        "Do you really want to delete the partition {partitionId} with all its data?",
    "customer-partitions-list.modal.request-deletion.message":
        "Partition {partitionId} cannot be deleted directly; support ticket with deletion request will be created instead. Do you really want to proceed?",

    "partitions-list.header.contact-emails": "Emails",
    "partitions-list.header.updated-at": "Updated at",
    "partitions-list.header.actions": "Actions",
    "partitions-list.header.last-login": "Last Login",
    "partitions-list.header.marked-to-delete": "Marked To Delete",
    "partitions-list.loading.error": "There was an error loading partitions.",
    "partitions-list.modal.delete.title": "Delete Partition",
    "partitions-list.modal.delete.message":
        "Do you really want to delete partition {partitionId}? The partition will be deleted and all its data will be lost.",
    "partitions-list.modal.request-deletion.message":
        "Partition {partitionId} cannot be deleted directly; support ticket with deletion request will be created instead. Do you really want to proceed?",
    "partitions-list.modal.remove.title": "Remove Partition from List",
    "partitions-list.modal.remove.message":
        "Do you really want to remove the partition {partitionId} from this list? Note that the partition will not be deleted, only the record pointing to it will be removed.",
    "partitions-list.value.yes": "Yes",
    "partitions-list.value.no": "No",

    "partitions-list.actions.manage-credentials":
        "Partition Access Entitlement",
    "partitions-list.actions.create-general-admin": "Create General Admin",
    "partitions-list.actions.create-support-user": "Create Support User",
    "partitions-list.actions.request-eng-support-user":
        "Request Eng-support user",
    "partitions-list.actions.remote-login-as-eng-support-user":
        "Remote login as Eng-support user",
    "partitions-list.actions.edit-partition": "Edit Partition",
    "partitions-list.actions.copy-partition": "Copy Partition",
    "partitions-list.actions.clone-partition": "Clone Partition",
    "partitions-list.actions.move-partition": "Move Partition",
    "button.export-csv": "Export CSV",
    "button.export-errors": "Export Errors",
    "export-default-name": "Export.csv",
    "export.tooltip.max-record": "Cannot export more than {maxRows} rows",
    "partitions-list.button.request": "Add New Partition",
    "partitions-list.error.message.title":
        "Error message from remote partition",
    "partition-list.remote-login.title": "Remote login as {user}",

    "partition-request.title": "Create Partition",
    "partition-request.info":
        "To create a new partition fill in the form. You will get an email notification after the partition is successfully created.",
    "partition-request.edit.info":
        "You can edit the selected partition attributes.",
    "partition-request.collapse.settings": "Settings",
    "partition-request.collapse.modules-and-users": "Modules and Users",
    "partition-request.modules.label": "Activate Modules",
    "partition-request.modules.column.module": "Module",
    "partition-request.modules.column.number-of-users": "Number of users",
    "partition-request.modules.number-of-users.placeholder": "Unlimited",
    "partition-request.label.name": "Partition Serial Name",
    "partition-request.placeholder.name": "Enter partition serial name",
    "partition-request.tooltip.name":
        "Partition Serial Name is used to uniquely identify the partition on an instance and is used in e.g. selection fields in PlatformManager",
    "partition-request.label.label": "Partition Label",
    "partition-request.placeholder.label": "Enter partition label",
    "partition-request.tooltip.label":
        "It is recommended to include a part of Account name into Label, so that the partition can be easily identified in Pricefx systems",
    "partition-request.label.cluster": "Instance",
    "partition-request.label.version": "Version",
    "partition-request.placeholder.cluster": "Select instance",

    "partition-request.label.contact": "Salesforce contact",
    "partition-request.placeholder.contact": "Select contact",
    "partition-request.radio.purpose.demo": "Demo",
    "partition-request.radio.purpose.poc": "PoC",
    "partition-request.radio.purpose.production": "Production",
    "partition-request.radio.purpose.test": "Test/Development/QA",
    "partition-request.radio.purpose.training": "Training",
    "partition-request.switch.module.analytics": "Analytics",
    "partition-request.switch.module.price-setting": "Price Setting",
    "partition-request.switch.module.optimization": "Optimization",
    // "partition-request.switch.module.agreements-and-promotions": "Agreements & Promotions",
    "partition-request.switch.module.rebates": "Rebates",
    "partition-request.switch.module.integration-manager": "IntegrationManager",
    // "partition-request.switch.module.channel-management": "Channel Management",
    "partition-request.switch.module.quoting": "Quoting",
    // "partition-request.switch.module.deal-planning": "Deal Planning",
    "partition-request.switch.module.claim-manager": "Channel Management",
    "partition-request.switch.module.contract-manager":
        "Agreements & Promotions",
    "partition-request.switch.module.sales-compensation": "Sales Compensation",
    "partition-request.switch.module.tooltip": "Enter number of users",
    "partition-request.label.description": "Description",
    "partition-request.placeholder.description": "Enter partition description",
    "partition-request.label.modules": "Activate Modules",
    "partition-request.label.usage-end-date": "Usage end date",
    "partition-request.tooltip.usage-end-date":
        "Partition can be deleted after selected date",
    "partition-request.label.delete-after": "Partition will be deleted after",
    "partition-request.label.number-of-admin-users": "Number of admin users",
    "partition-request.placeholder.credentials-email": "Enter email address",
    "partition-request.label.credentials-email":
        "Send credentials to this email",
    "partition-request.tooltip.number-of-admin-users":
        "Sets maximum number of administrative users. Note that one such admin user will be created automatically",
    "partition-request.label.currency": "Currency",
    "partition-request.tooltip.currency": "Select your default currency",
    "partition-request.placeholder.currency": "Select currency",
    "partition-request.label.uom": "Unit of Measure",
    "partition-request.tooltip.uom": "Enter your default UoM",
    "partition-request.placeholder.uom": "Enter UoM",
    "partition-request.label.non-standard-requirements":
        "Non-standard Requirements (Partition will be created by Pricefx Support)",
    "partition-request.label.remote-db-schema": "Remote DB schema",
    "partition-request.label.purpose": "Purpose",
    "partition-request.placeholder.purpose": "Select purpose",
    "partition-request.label.other-requirements": "Other requirements",
    "partition-request.button.submit": "Submit",
    "common.validation.invalidChar":
        "The field contains an invalid character. Allowed characters are:",
    "partition-request.validation.partition-name-already-exists":
        "The name already exists",
    "partition-request.validation.currency-format-error":
        "Wrong currency format",
    "partition-request.validation.required": "Required",
    "partition-request.validation.unrelated-value": "Unrelated value",
    "partition-request.validation.maximum-date": "Maximum 1 year from now",
    "partition-request.validation.max-admin-users":
        "Maximum value of number of admin users is {limit}",
    "partition-request.validation.partitions-limit":
        "You've exceeded the recommended technical limit of {limit} partition(s) you can create via PlatformManager. If you need an extra partition under this account, please create a helpdesk ticket.",
    "partition-request.validation.partitions-limit-fetch-error":
        "Problem fetching number of partitions",
    "partition-relete-request.started":
        "The request to delete the partition was submitted to a workflow and needs to be approved. Please wait for the workflow to finish.",
    "partition-request.completed":
        "The request to create the partition was submitted to a workflow and needs to be approved. Please wait for the workflow to finish.",
    "partition-edit.completed":
        "Changes have been saved. It may take a moment for systems to synchronize.",

    "partition-clone.title": "Clone Partition",
    "partition-clone.info":
        "To clone a partition, fill in the form. You will get an email notification after the partition is successfully cloned.",
    "partition-clones.success-message": "The clone request was submitted.",

    "partition-move.title": "Move the whole partition",
    "partition-move.info":
        "You are planning to transfer the whole partition to a new instance. The current partition will be deleted with all its data.",

    "partition-move.source-cluster.label": "Source Instance",
    "partition-move.source-partition.label": "Source Partition",
    "partition-move.purpose.label": "Purpose",
    "partition-move.purpose.tooltip": "?",
    "partition-move.target-cluster.label": "Target Instance",
    "partition-move.target-cluster.tooltip": "?",
    "partition-move.target-partition.label": "Target Serial Name",
    "partition-move.target-partition.tooltip": "?",

    "partition-move.warning":
        "When the Move process is finished, the partition is moved to the new location and then deleted (with all its data) from the previous location.",
    "partition-provisioning.warning":
        "This IM has been stopped because the provisioning action is being performed. When finished, the IM will be restarted again automatically.",

    "partition-move.success-message":
        "The request to move the partition was submitted to a workflow and needs to be approved. Please wait for the workflow to finish.",

    "partition-move.stop-integrations.title": "Integrations to be stopped",
    "partition-move.stop-integrations.info":
        "To prevent errors the integrations mentioned below will be stopped before the provisioning action is performed (after the workflow approval). When the provisioning action is finished, the IMs should be restarted again automatically.",

    "partition.job-task-tracking.list.id": "ID",
    "partition.job-task-tracking.list.created": "Created",
    "partition.job-task-tracking.list.start-processing": "Start Processing",
    "partition.job-task-tracking.list.end-processing": "End Processing",
    "partition.job-task-tracking.list.status": "Status",
    "partition.job-task-tracking.list.progress": "Progress",
    "partition.job-task-tracking.list.uuid": "UUID",
    "partition.job-task-tracking.list.type": "Type",
    "partition.job-task-tracking.list.name": "Target Name",
    "partition.job-task-tracking.list.cancellation-requested":
        "Cancellation requested",
    "partition.job-task-tracking.list.node": "Node",
    "partition.job-task-tracking.list.target-object": "Target Object",
    "partition.job-task-tracking.list.concurrency-key": "Concurrency Key",
    "partition.job-task-tracking.list.calculation-context":
        "Calculation Context",
    "partition.job-task-tracking.list.status.waiting": "Pending",
    "partition.job-task-tracking.list.status.queued": "Scheduled",
    "partition.job-task-tracking.list.status.processing": "Processing",
    "partition.job-task-tracking.list.status.failed": "Error",
    "partition.job-task-tracking.list.status.finished": "Ready",
    "partition.job-task-tracking.list.status.cancelled": "Cancelled",
    "partition.job-task-tracking.list.status.interrupted": "Interrupted",

    "partition.upload-queue.list.order": "Order",
    "partition.upload-queue.list.name": "Name",
    "partition.upload-queue.list.file-name": "File Name",
    "partition.upload-queue.list.type": "Type",
    "partition.upload-queue.list.entity-type": "Entity Type",
    "partition.upload-queue.list.entity-name": "Entity Name",
    "partition.upload-queue.list.executionTime": "Scheduled to",
    "partition.upload-queue.list.status": "Status",
    "partition.upload-queue.list.status.waiting": "Waiting",
    "partition.upload-queue.list.status.in-process": "In Progress",
    "partition.upload-queue.list.remove-from-queue": "Remove From Queue",
    "partition.upload-queue.list.force-stop": "Force Stop Uploading",
    "partition.upload-queue.message.force-stopped": "Uploading was stopped",
    "partition.upload-queue.message.removed": "Removed from the queue",
    "partition.upload-queue.popover.waiting.title": "Waiting",
    "partition.upload-queue.popover.waiting.content":
        "{fileName} is waiting in a queue to{br}be uploaded.",
    "partition.upload-queue.popover.waiting.stop": "Remove From Queue",
    "partition.upload-queue.popover.in-progress.title": "In progress",
    "partition.upload-queue.popover.in-progress.content":
        "{fileName} is still In progress.",
    "partition.upload-queue.popover.in-progress.stop": "Force Stop Uploading",

    "partition.event-admin.list.last-update": "Last Update",
    "partition.event-admin.list.created": "Created",
    "partition.event-admin.list.name": "Name",
    "partition.event-admin.list.messages": "Messages",
    "partition.event-admin.list.retries-left": "Retries Left",
    "partition.event-admin.list.response": "Response",
    "partition.event-admin.list.error-message": "Error Message",
    "partition.event-admin.list.rerun-event": "Rerun Event",
    "partition.event-admin.list.rerun-event.success":
        "Event rerun successfully",
    "partition.event-admin.list.rerun-event.fail": "Event rerun failed",
    "partition.event-admin.button.even-configurator": "Event Configurator",

    "partition.event-configurator.title": "Event Configuration",
    "partition.event-configurator.form.event-bitmask": "Event Bitmask",
    "partition.event-configurator.form.event-mq": "Event to Kafka/MQ",
    "partition.event-configurator.form.event-url": "Event URL",
    "partition.event-configurator.form.event-url.placeholder":
        "Enter event URL",
    "partition.event-configurator.form.disable-event-processing":
        "Disable sending events through HTTP (PUSH)",
    "partition.event-configurator.form.email": "Email",
    "partition.event-configurator.form.email.placeholder":
        "Enter email address",
    "partition.event-configurator.server-events-documentation":
        "Server Events Documentation",

    "partition.upload-queue.tab": "Upload Queue",
    "partition.log-history.tab": "Log History",
    "partition.accelerators.tab": "Accelerators",
    "instance.templates.tab": "Templates",
    "instance.mergeRequests.tab": "Merge Requests",
    "instance.failed-events.tab": "Failed Events",
    "instance.documentation.tab": "Documentation",
    "failed-events-list.retry-event-types": "Retry Event Types",
    "failed-events-list.retry-event-type.title": "Retry Event Type",
    "failed-events-list.header.event-type": "Event Type",
    "failed-events-list.header.count": "Event Count",
    "failed-events-list.retry-event-types.success":
        "Retrying started successfully",
    "instance.documentation.overview.label": "Overview",
    "instance.documentation.overview.project-name": "Project name",
    "instance.documentation.overview.im-version": "IM version",
    "instance.documentation.overview.instance-name": "Instance-name",
    "instance.documentation.routes.label": "Routes",
    "instance.documentation.routes.header.name": "Name",
    "instance.documentation.routes.header.uri": "URI",
    "instance.documentation.routes.header.description": "Description",
    "instance.documentation.mappers.label": "Mappers",
    "instance.documentation.mappers.header.mapperName": "Name",
    "instance.documentation.mappers.header.in": "In",
    "instance.documentation.mappers.header.out": "Out",
    "instance.documentation.mappers.header.converter": "Converter",
    "instance.documentation.mappers.header.converterExpression":
        "Converter Expression",
    "instance.documentation.scheduled-jobs.label": "Scheduled Jobs",
    "instance.documentation.scheduled-jobs.header.name": "Name",
    "instance.documentation.scheduled-jobs.header.uri": "URI",
    "instance.documentation.converters.label": "Converters",
    "instance.documentation.converters.header.name": "Name",
    "instance.documentation.converters.header.usage": "Usage",
    "instance.documentation.beans.label": "Beans",
    "instance.documentation.beans.header.name": "Name",
    "instance.documentation.beans.header.class-name": "ClassName",
    "instance.documentation.resources.label": "Resources",
    "instance.documentation.resources.header.name": "Name",
    "instance.documentation.resources.header.filename": "ClassName",
    "instance.documentation.filters.label": "Filters",
    "instance.documentation.filters.header.id": "ID",
    "instance.documentation.filters.header.sort-by": "Sort by",
    "instance.documentation.filters.header.result-fields": "Result Fields",
    "instance.documentation.connections.label": "Connections",
    "instance.documentation.connections.header.id": "ID",
    "instance.documentation.connections.header.discriminator": "Discriminator",
    "instance.documentation.connections.header.uri": "URI",
    "partition.job-task-tracking.tab": "Job/Task Tracking",
    "partition.event-admin.tab": "Event Admin",
    "cluster.upgrade.validation.cluster.isRequired": "Please select instance",
    "cluster.upgrade.validation.version.isRequired":
        "Please select version to upgrade",
    "cluster.upgrade.no-timeslots.title": "No free slots are available.",
    "cluster.upgrade.no-timeslots.subtitle": "Please select a different date.",
    "cluster.upgrade.no-timeslots.content":
        "If you are unable to find a suitable time, contact our {link} to find a mutually agreeable time.",
    "cluster.upgrade.validation.date.invalid": "Invalid date",
    "cluster.upgrade.validation.time.invalid": "Invalid time",
    "cluster.upgrade.tab": "Upgrade",
    "cluster.upgrade.header": "Instance Upgrade",
    "cluster.upgrade.new-request": "Request Upgrade",
    "cluster.upgrade.title":
        "Select the instance and choose your upgrade version and select time slot for the upgrade.",
    "cluster.upgrade.fetch-available-times-failed":
        "Failed to fetch available upgrades times",
    "cluster.upgrade.not-available": "No upgrades available.",
    "cluster.upgrade.no-timeslots-available":
        "No timeslots to upgrade are available.",
    "cluster.upgrade.cluster.label": "Instance",
    "cluster.upgrade.cluster.version.label": "Current Version",
    "cluster.upgrade.snapshot.label": "Version to Upgrade",
    "cluster.upgrade.timezone.label": "Timezone",
    "cluster.upgrade.automatic-upgrade.label": "Run upgrade by",
    "cluster.upgrade.automatic-upgrade.tooltip":
        "Automated upgrade runs without the need of human interaction; it also has no downtime.",
    "cluster.upgrade.automatic-upgrade.option.manual": "Person",
    "cluster.upgrade.automatic-upgrade.option.automated": "Automated",
    "cluster.upgrade.note.label": "Note",
    "cluster.upgrade.additional-attendees.label":
        "Send upgrade meeting placeholder to other recipients",
    "cluster.upgrade.event-created": "Calendar event created successfully",
    "cluster.upgrade.event-updated": "Calendar event updated successfully",
    "cluster.upgrade.fetch-available-clusters":
        "Failed to fetch available instances",
    "cluster.upgrade.fetch-available-upgrades-failed":
        "Failed to fetch available upgrades",
    "cluster.upgrade.date-and-slot.title": "Date and slot",
    "cluster.upgrade.consent.text":
        "By requesting to upgrade our productive environment, I hereby confirm that our organization properly tested the new software release and is familiar with the changes introduced in the release.",

    "cluster.upgrade.overview.header": "Instance Upgrade",
    "cluster.upgrade.overview.perex":
        "List of all instances waiting for upgrade.",

    "cluster.upgrade.overview.filter.upgrades": "Upgrades",
    "cluster.upgrade.overview.filter.all": "All",
    "cluster.upgrade.overview.filter.today": "Today",
    "cluster.upgrade.overview.filter.tomorrow": "Tomorrow",
    "cluster.upgrade.overview.filter.next3days": "Next 3 days",
    "cluster.upgrade.overview.filter.thisWeek": "This week",

    "cluster.upgrade.overview.column.cluster": "Instance",
    "cluster.upgrade.overview.column.currentVersion": "Current Version",
    "cluster.upgrade.overview.column.targetVersion": "Target Version",
    "cluster.upgrade.overview.column.upgradeDate": "Upgrade Time",
    "cluster.upgrade.overview.column.responsiblePerson": "Responsible Person",
    "cluster.upgrade.overview.column.requestedBy": "Requested By",
    "cluster.upgrade.overview.column.status": "Status",
    "cluster.upgrade.overview.column.status.requested": "Requested",
    "cluster.upgrade.overview.column.status.approved": "Approved",
    "cluster.upgrade.overview.column.status.cancelled": "Cancelled",
    "cluster.upgrade.overview.column.status.completed": "Completed",

    "cluster.upgrade.overview.no-edit-available":
        "Instance upgrade cannot be edited",

    "cluster.upgrade.delete":
        "Do you really want to delete this upgrade for instance {cluster}?",
    "cluster.upgrade.delete.success": "Upgrade event deleted",
    "cluster.upgrade.delete.fail": "Upgrade event delete failed",

    "cluster.upgrade.slots.overview.header": "Upgrade Slots Overview",
    "cluster.upgrade.slots.overview.filter": "Upcoming Schedules Slots",
    "cluster.upgrade.slots.overview.column.name": "Name",
    "cluster.upgrade.slots.overview.column.date": "Date",
    "cluster.upgrade.slots.overview.column.time": "Time",
    "cluster.upgrade.slots.overview.column.duration": "Duration",
    "cluster.upgrade.slots.overview.column.timezone": "Timezone",
    "cluster.upgrade.slots.overview.column.recurrent": "Recurrent",
    "cluster.upgrade.slots.overview.column.status": "Status",
    "cluster.upgrade.slots.status": "Status",
    "cluster.upgrade.slots.status.planned": "Planned Upgrade",
    "cluster.upgrade.slots.status.not_used": "Not Used",

    "cluster.upgrade.slots.detail.title": "Scheduled Slots by {name}",

    "classes-list.button.new": "New Class",
    "classes-list.header.name": "Class Name",
    "classes-list.header.status": "Status",
    "classes-list.header.parent": "Instance Parent",
    "classes-list.header.integrationType": "Type",
    "classes-list.header.revisionNumber": "Revision Number",
    "classes-list.header.createdAt": "Created at",
    "classes-list.header.updatedAt": "Updated at",
    "classes-list.header.createdBy": "Created by",
    "classes-list.header.updatedBy": "Updated by",
    "classes-list.header.path": "Path",
    "classes-list.copy.title": "Copy Class",

    "class-form.title.new-class": "New Class",
    "class-form.title.edit-class": "Edit Class",
    "class-form.title.new-perex":
        "You can define classes that can be deployed to IntegrationManager and used in routes via Bean reference. The solution is currently limited to Groovy classes with imports from basic Camel libraries.",
    "class-form.updated": "The class was updated.",
    "class-form.updated-and-deployed": "The class was updated and deployed.",
    "class-form.created": "The class was created.",
    "class-form.created-and-deployed": "The class was created and deployed.",
    "class-form.perex.save-and-deploy":
        "Deploy - The class will be directly deployed into Instance Repository",
    "class-form.perex.save":
        "Save - The class will only be saved locally in Workspace and can be deployed later",
    "class-form.deployment.success": "The class was deployed",

    "class-form.name.label": "Class Name",
    "class-form.name.placeholder": "Enter resource name",
    "class-form.code.label": "Code",
    "class-form-copy.modal.title": "Copy Class",
    "class-form-copy.instance.label": "Integration Instances",
    "class-form-copy.class-name.label": "Class Name",
    "class-form-copy.class-name.placeholder": "Enter Class Name",

    "partition.general-admin.title": "General Admin",
    "partition.general-admin.exists.inserted":
        "User {userName} already exists in the partition and has the General Admin role. You can update the password and expiry date.",
    "partition.general-admin.exists.notinserted":
        "User {userName} already exists in the partition and has the General Admin role which is probably assigned outside PlatformManager.",
    "partition.general-admin.assign-role-to-existing-user":
        "User {userName} already exists in the partition. The General Admin role will be assigned.",
    "partition.general-admin.create-user-and-role":
        "Create user with the general admin role.",
    "partition.general-admin.submitted.description":
        "Request for general admin creation was submitted.",
    "partition.general-admin.loginName": "Login Name",
    "partition.general-admin.password": "Password",
    "partition.general-admin.expiration": "Expiry date",
    "partition.general-admin.submit": "Submit",
    "partition.create-support-user.title": "Create Support User",
    "partition.create-support-user.created":
        "Support user successfully created.",
    "partition.create-support-user.username": "Username",
    "partition.create-support-user.expiration": "Expiration",
    "partition.request-eng-support-user.title": "Request Eng-support user",
    "partition.request-eng-support-user.created":
        "Request for eng support user successfully created.",
    "partition.request-eng-support-user.reason": "Reason",
    "partition.request-eng-support-user.expiration": "Expiration",

    "updates-list.header.id": "ID",
    "updates-list.header.url": "URL",
    "updates-list.header.name": "Name",
    "updates-list.header.build-id": "Build ID",

    "remote-login.title": "Remote Login",
    "remote-login.perex":
        "Select an instance and user to log in remotely to a selected partition.",
    "remote-login.label.cluster": "Instance",
    "remote-login.placeholder.cluster": "Select instance",
    "remote-login.label.user": "User",
    "remote-login.placeholder.user": "Select user",
    "remote-login.label.partition.select": "Select partition",
    "remote-login.label.partition.input": "System partition name",
    "remote-login.placeholder.partition.select": "Select partition",
    "remote-login.button.login": "Login",
    "remote-login.type.select": "Partition type",
    "remote-login.type.partition": "Partition",
    "remote-login.type.system-partition": "System Partition",

    "integrations-component-grid.placeholder.search-connector":
        "Search connectors…",
    "integrations-component-grid.placeholder.search-category":
        "Search category…",

    "isv-connections.list.name": "Name",
    "isv-connections.list.vendor": "Vendor",
    "isv-connections.list.partition": "Partition",
    "isv-connections.list.status": "Status",
    "isv-connections.list.date-connected": "Date connected",
    "isv-connections.list.last-change": "Last change",
    "isv-connections.stepper.general": "General",
    "isv-connections.stepper.vendor": "Vendor",
    "isv-connections.stepper.pricefx": "Pricefx",
    "isv-connections.stepper.email-notifications": "Email notifications",

    "isv-connections.steps.general.name": "Name",
    "isv-connections.steps.general.name.placeholder": "Enter connection name",
    "isv-connections.steps.general.vendor": "Vendor",
    "isv-connections.steps.general.partition": "Partition",
    "isv-connections.steps.general.saml-configuration": "Saml Configuration",
    "isv-connections.steps.general.saml-configuration.tooltip":
        "The selection of the correct SAML configuration is necessary for the correct functioning of the links from Enable to the partition.",
    "isv-connections.steps.general.status": "Status",

    "isv-connections.steps.vendor.id": "Channel ID",
    "isv-connections.steps.vendor.baseUrl": "Base URL - Trading programs",
    "isv-connections.steps.vendor.collaboratorBaseUrl":
        "Base URL - Collaborator",
    "isv-connections.steps.vendor.sftpUrl": "URL",
    "isv-connections.steps.vendor.resend-to-partition": "Resend to partition",
    "isv-connections.steps.vendor.resend-to-partition.success":
        "Isv connection config was successfully resent to partition",
    "isv-connections.steps.vendor.sftpPort": "Port",
    "isv-connections.steps.vendor.sftpUsername": "Username",
    "isv-connections.steps.vendor.sftpPassword": "Password",
    "isv-connections.steps.vendor.sftpPassword.placeholder": "Set a password",
    "isv-connections.steps.vendor.connection.title": "Connection Test",
    "isv-connections.steps.vendor.connection.perex":
        "Before you create this Connection we recommend you to test the settings a credentials.",
    "isv-connections.steps.vendor.connection.test-connection":
        "Test Connection",
    "isv-connections.steps.vendor.connection.connection-success":
        "Connection successful",
    "isv-connections.steps.vendor.connection.connection-failed":
        "Connection failed",
    "isv-connections.steps.email-notifications.perex":
        "Customize email notifications for data transfer results between Pricefx and Enable for each email address.",
    "isv-connections.steps.email-notifications.success":
        "Notification settings have been successfully saved.",
    "isv-connections.steps.email-notifications.form.add-another":
        "Add another e-mail",
    "isv-connections.steps.email-notifications.form.email.label": "E-mail",
    "isv-connections.steps.email-notifications.form.notify-about.label":
        "Notify about data transfer directions",
    "isv-connections.steps.email-notifications.form.success-results.label":
        "Success results",
    "isv-connections.steps.email-notifications.form.error-results.label":
        "Error results",

    "isv-connections.data-mapping.header.upload-new-csv": "Upload new CSV file",
    "isv-connections.data-mapping.list.name": "Mapping Name",
    "isv-connections.data-mapping.list.direction": "Direction",
    "isv-connections.data-mapping.list.pricefx-tabels": "Pricefx table(s)",
    "isv-connections.data-mapping.list.enable-table": "Enable table",
    "isv-connections.data-mapping.list.enable.status": "Status",
    "isv-connections.data-mapping.list.enable.last-sync": "Last sync",
    "isv-connections.data-mapping.list.action.delete": "Delete Data Mapping",

    "isv-connections.data-mapping.steps.general": "General",
    "isv-connections.data-mapping.steps.entities": "Entities",
    "isv-connections.data-mapping.steps.login": "Login",
    "isv-connections.data-mapping.steps.upload": "Upload",
    "isv-connections.data-mapping.steps.parsing": "Parsing",
    "isv-connections.data-mapping.steps.fields": "Fields",
    "isv-connections.data-mapping.steps.fields-mapping": "Fields Mapping",
    "isv-connections.data-mapping.steps.fields.transactionDateColumn.tooltip":
        "Select a column that contains the date on which the transaction took place.",
    "isv-connections.data-mapping.steps.fields.transactionDateColumn":
        "Transaction date column",
    "isv-connections.data-mapping.steps.synchronization": "Synchronization",

    "isv-connections.data-mapping.step.general.name": "Mapping Name",
    "isv-connections.data-mapping.step.general.status": "Status",
    "isv-connections.data-mapping.step.general.direction": "Direction",
    "isv-connections.data-mapping.step.general.direction.pfx-vendor":
        "Pricefx → {vendorName}",
    "isv-connections.data-mapping.step.general.direction.vendor-pfx":
        "{vendorName} → Pricefx",
    "isv-connections.data-mapping.step.general.send-empty-value-as":
        "Send Empty Value As",
    "isv-connections.data-mapping.step.general.empty-string": "Empty String",
    "isv-connections.data-mapping.step.general.custom-string": "Custom String",

    "isv-connections.data-mapping.step.entities.entity": "{vendorName} Entity",
    "isv-connections.data-mapping.step.entities.entity-type":
        "{vendorName} Entity Type",
    "isv-connections.data-mapping.step.entities.entity-name":
        "{vendorName} Entity Name",
    "isv-connections.data-mapping.step.entities.table": "{vendorName} Table",

    "isv-connections.data-mapping.step.upload.title": "{vendorName} Table",
    "isv-connections.data-mapping.step.upload.upload-csv-file":
        "Upload a CSV file",
    "isv-connections.data-mapping.step.upload.knowledge-base": "Knowledge base",
    "isv-connections.data-mapping.step.upload.next-step-info":
        "In the following steps you will map mandatory / optional fields. ",
    "isv-connections.data-mapping.step.upload.perex":
        "Provide here your file you downloaded from Enable. This file must contain fields corresponding to the entity you are going to update. Visit our {knowledgeBaseLink} for more information about the supported formats and file requirements.",

    "isv-connections.data-mapping.step.synchronization.title": "Scheduler",
    "isv-connections.data-mapping.step.synchronization.scheduler-only.title":
        "Synchronization Scheduler",
    "isv-connections.data-mapping.step.synchronization.synchronization-trigger":
        "Synchronization Trigger",
    "isv-connections.data-mapping.step.synchronization.synchronization-trigger.immediately":
        "Immediately",
    "isv-connections.data-mapping.step.synchronization.synchronization-trigger.scheduler":
        "Scheduler",
    "isv-connections.steps.vendor.alert.sfx-parameters–changed":
        "The Salesfx parameters {list} on partition were changed. Please resave connection with new parameters imported from your partition.",
    "isv-connections.steps.vendor.title.channel": "Channel",
    "isv-connections.steps.vendor.title.sftp": "SFTP",
    "isv-connections.sftp.perex":
        "Create a user who will have access to the Pricefx SFTP where files are exported from {vendorName}.",
    "isv-connections.steps.vendor.perex.sftp":
        "Please specify {vendorName}'s SFTP details.",
    "isv-connections.steps.success.title":
        "The ISV Connection was successfully created",
    "isv-connections.steps.success.perex":
        "Pricefx SFTP server is now active and ready to use. The SFTP address and user credentials have been sent to your email. You can manage the SFTP users in the ISV Connection Edit section.",

    "isv-connections.steps.pricefx.title": "Pricefx SFTP",
    "isv-connections.steps.pricefx.perex":
        "Details of the Pricefx SFTP server where the files from {vendorName} should be exported. ",

    "server-list.error.loading": "There was an error loading the items",
    "servers.create.success": "Server was successfully created",
    "server-list.title.servers": "Servers",
    "server-list.header.server-name": "Server Name",
    "server-list.header.location": "Location",
    "server-list.header.created-at": "Created at",
    "server-list.header.created-by": "Created by",
    "server-list.header.updated-at": "Updated at",
    "server-list.header.updated-by": "Updated by",
    "server-list.header.action": "Action",
    "server-list.button.delete": "Delete",
    "server-list.modal.title": "Delete Server",
    "server-list.modal.message":
        "Do you really want to delete the server {name}?",

    "server-create.error.missing-server-name": "Server name is required",
    "server-create.error.missing-location": "Server location is required",
    "server-create.label.server-name": "Server Name",
    "server-create.title.new-server": "New Server",
    "server-create.label.server-location": "Server Location",

    "server-create.button.save": "Save",
    "server-create.button.cancel": "Cancel",
    "server-create.label.action-info":
        "Here you can add a new integration server.",
    "server-create.placeholder.server-name": "Enter server name",
    "server-create.placeholder.select-location": "Select location",

    "user-list.title.list-of-users": "Users",
    "user-list.title.create-user": "Create User",
    "user-list.title.delete-user": "Delete User",
    "user-list.message.delete-user":
        "Do you really want to delete the user {username}?",
    "user-list.header.name": "Name",
    "user-list.header.full-name": "Full Name",
    "user-list.header.email": "Email",
    "user-list.header.job-title": "Job Title",
    "user-list.header.last-login": "Last Login",
    "user-list.header.created-by": "Created by",
    "user-list.header.created-at": "Created at",
    "user-list.header.actions": "Actions",
    "user-list.button.edit": "Edit",
    "user-list.button.delete": "Delete",
    "user-list.button.cancel": "Cancel",

    "user-form.title.edit-user": "Edit User",
    "user-form.title.new-user": "New User",
    "user-form.label.username": "Username",
    "user-form.label.password": "Password",
    "user-form.label.first-name": "First Name",
    "user-form.label.last-name": "Last Name",
    "user-form.label.email": "Email",
    "user-form.label.job-title": "Job Title",
    "user-form.button.save": "Save",
    "user-form.button.cancel": "Cancel",
    "user-form.error.missing-username":
        "Please enter the user's login in the format username@example.com",
    "user-form.error.wrong-group-name-format":
        "Please enter the username in the format user.name@example.com",
    "user-form.error.missing-first-name": "Please enter the user's first name",
    "user-form.error.missing-last-name": "Please enter the user's last name",
    "user-form.error.missing-email":
        "Please enter the user's email in the format username@example.com",
    "user-form.warning.existing-user":
        "Deactivated user with the same username already exists. Saving this record will reactivate this user.",

    "user-activation.header": "Activate Account",
    "user-activation.action": "Activate account",
    "user-activation.back-to-login-page": "Back to login page",
    "activate-account.command.activateAccount.running":
        "Activating the account",
    "activate-account.command.activateAccount.error":
        "Unable to activate the account",
    "activate-account.command.activateAccount.success":
        "The account was activated successfully",
    "group-detail.new.title": "New Group",
    "user-detail.nav-bar.user": "User",
    "user-detail.new.title": "New User",
    "user-detail.edit.title": "Edit User",
    "user-detail.groups.title": "User in Group(s)",
    "user-detail.groups.add.title": "Add user to group",
    "user-detail.groups.header.name": "Name",
    "user-detail.groups.header.label": "Label",
    "user-detail.groups.action.remove.title": "Remove from Group",
    "user-detail.groups.action.remove.confirm.title": "Remove User from Group",
    "user-detail.groups.action.remove.confirm.message":
        "Do you really want to remove the user from the group {groupName}?",
    "user-detail.groups.action.remove.confirm.ok": "Remove",
    "user-detail.password.title": "Reset User Password",
    "user-detail.password.button.reset": "Reset Password",
    "user-detail.password.description":
        "When you reset a user's password, an email with instructions will be sent to the user.",
    "user-detail.password.successful":
        "Request with a password reset was sent to the user",
    "user-detail.alert.permission":
        "Warning: Changing the permissions can affect your session.", // TODO: not used, check
    "user-detail.alert.assigned-groups":
        "Warning: Changing the assigned groups can affect your session.",
    "user-detail.modal.sso-username.title": "SSO Username",
    "user-detail.modal.sso-username.text":
        "If users use SSO username with their SSO provider, fill it in.",
    "user-detail.resend-activation-email": "Resend Activation Email",
    "user-detail.resend-activation-email-ok":
        "Activation email was successfully resent",
    "user-detail.resend-activation-email-failed":
        "Failed to resend activation email",
    "group-list.title.delete-group": "Delete Group",
    "group-list.message.delete-group":
        "Do you really want to delete the group {groupName}?",
    "group-list.header.name": "Name",
    "group-list.header.label": "Label",
    "group-list.header.adManaged": "AD Managed",
    "group-list.header.action": "Action",
    "group-list.button.edit": "Edit",
    "group-list.button.cancel": "Cancel",
    "group-list.button.create-group": "Create Group",
    "group-list.button.sync-with-ad": "Sync with AD",
    "group-list.notification.sync-with-ad.success":
        "Active Directory groups users were successfully synced",
    "group-list.notification.sync-with-ad.error":
        "An error occured while syncing Active Directory group.",

    "group-detail.nav-bar.general": "General",
    "group-detail.nav-bar.assigned-users": "Assigned Users",
    "group-detail.loading": "Loading…",
    "group-detail.button.add": "Add",
    "group-detail.button.delete": "Delete",
    "group-detail.header.id": "ID",
    "group-detail.header.login": "Login",
    "group-detail.header.full-name": "Full Name",
    "group-detail.header.email": "Email",
    "group-detail.header.actions": "Actions",
    "group-detail.alert.assigned-users":
        "Warning: Changing the group's assigned users can affect your session.",
    "group-detail.title.remove-user": "Remove User",
    "group-detail.message.remove-user":
        "Do you really want to remove the user {userName}?",

    "group-form.title.edit-group": "Edit Group",
    "group-form.title.create-new-group": "Create New Group",
    "group-form.title.new-group": "New Group",
    "group-form.button.save": "Save",
    "group-form.button.cancel": "Cancel",
    "group-form.button.add": "Add",
    "group-form.label.name": "Group Name",
    "group-form.label.label": "Description",
    "group-form.title": "AD managed",
    "group-form.title.ad-groups": "Assigned AD Groups",
    "group-form.label.ad-managed": "AD Managed",
    "group-form.placeholder.name": "groupName@domain.com",
    "group-form.placeholder.ad-name": "Please select Active Directory group",
    "group-form.description.label":
        "By adding a domain (e.g. @pricefx.eu) at the end of the group, you can assign the group to that specific domain.",
    "group-form.placeholder.label": "Group Label",
    "group-form.error.missing-name": "Please enter a group name",
    "group-form.error.wrong-group-name-format":
        "Please enter a group name in the format group:name@example.com or group:name",
    "group-form.error.missing-label": "Please enter a group label",
    "edit-group.title": "Edit group",
    "edit-group.users-added": "Users added successfully",
    "edit-group.user-removed": "User removed from group",
    "edit-user.user-groups-added": "User added to groups",
    "edit-user.user-removed": "User removed from group",

    "help-search.title": "Help",
    "help-search.input.placeholder": "Search documentation…",
    "help-search.documentation.text": "For questions and help, please visit",
    "help-search.documentation.link": "PlatformManager Documentation",
    "help-search.privacy-policy.see-our": "See also our",
    "help-search.privacy-policy.link": "Privacy Policy",
    "help-search.whats-new.link": "What's new?",

    "customer-management.title.customers-management": "Accounts Management",
    "customer-management.title.user-access": "User Access",
    "customer-management.title.group-access": "Group Access",
    "customer-management.title.domain-access": "Domain Access",
    "customer-management.title.general": "General",
    "customer-management.error": "There was an error loading the items",
    "customer-management.header.id": "ID",
    "customer-management.header.customer-name": "Account Name",
    "customer-management.header.user": "User",
    "customer-management.header.group": "Group",
    "customer-management.header.action": "Action",
    "customer-management.header.permission": "Permission",
    "customer-management.button.edit": "Edit",
    "customer-management.button.delete": "Delete",
    "customer-management.button.read": "Read",
    "customer-management.button.write": "Write",
    "customer-management.button.admin": "Admin",
    "customer-management.button.add": "Add",
    "customer-management.button.remove": "Remove",
    "customer-management.button.save": "Save",
    "customer-management.button.reset": "Reset",
    "customer-management.placeholder.add-domain":
        "Please enter a domain in the format 'example.com'",
    "customer-management.label.customer-name": "Account Name",
    "customer-management.label.description": "Description",
    "customer-management.loading": "Loading…",
    "customer-management.nav-item.user-and-group-access":
        "User and Group Access",
    "customer-management.nav-item.instances": "IM Instances",
    "customer-management.nav-item.connections": "Connections",
    "customer-management.nav-item.mappers": "Mappers",
    "customer-management.nav-item.data-uploads": "Data Uploads",
    "customer-management.nav-item.create-partition": "Create Partition",

    "data-uploads-list.fields.validation.name":
        "Required. Please use at least 3 characters.",
    "data-uploads-list.title.data-uploads": "Data Uploads",
    "data-uploads-list.header.name": "Name",
    "data-uploads-list.header.type": "Type",
    "data-uploads-list.header.entity": "Entity Type/Name",
    "data-uploads-list.header.number-of-sftp-users": "Number of SFTP Users",
    "data-uploads-list.header.created-at": "Created at",
    "data-uploads-list.header.created-by": "Created by",
    "data-uploads-list.header.updated-by": "Updated by",
    "data-uploads-list.header.updated-at": "Updated at",
    "data-uploads-list.header.actions": "Actions",
    "data-uploads-list.button.send-file": "Upload File",
    "data-uploads-list.button.files-history": "History",
    "data-uploads-list.button.send-data": "Upload Data",
    "data-uploads-list.button.delete": "Delete",
    "data-uploads-list.button.new-server": "New Server",
    "data-upload.upload.hint": "Upload data in CSV format",
    "data-upload.error.required": "Required",
    "data-upload.error.definition-required": "Definition is required",
    "data-uploads-list.delete.show-history": "Show history",
    "data-uploads-list.delete.send-file": "Send file",
    "data-uploads-list.delete.title": "Delete Data Upload",
    "data-uploads-list.delete.message":
        "Do you really want to delete the data upload {dataUploadName}?",
    "data-uploads-list.copy.title": "Copy Data Upload",

    "data-uploads-list.sftp-management.title": "SFTP User Management",

    "data-uploads.sftp.server-info.title": "SFTP Server",
    "data-uploads.sftp.server-info.address": "SFTP Server",

    "data-upload-history.title": "Data Upload",
    "data-upload-history.header.imported": "Imported",
    "data-upload-history.header.target": "Target",
    "data-upload-history.header.filename": "File Name",
    "data-upload-history.header.records": "Records",
    "data-upload-history.header.status": "Status",
    "data-upload-history.header.failed": "Failed",

    "data-upload.action.create-data-upload": "Create Data Upload",
    "data-upload.action.quick-data-upload": "Quick Data Upload",
    "data-upload.error": "Unable to upload the file to the server",
    "data-upload.parsing-error": "Unable to parse the file",

    "data-upload.delete.success": "Data upload deleted successfully",
    "data-upload.delete.error": "Unable to delete data upload",

    "data-upload-file-error.header.time": "Time",
    "data-upload-file-error.header.payload": "Payload",
    "data-upload-file-error.header.message": "Message",
    "data-upload.warnings.error-message":
        "There is a Data Upload running on the partition - please wait until the previous load finishes to start a new one.",
    "data-upload.warnings.pa-in-use":
        "Analytics tables [{entityType} - {entityNames}] are in use right now. Parallel data uploads are NOT advised.",
    "data-upload.warnings.in-use.master-table":
        "Some partition tables [{entityType}] are in use right now. Parallel data uploads into the same tables are NOT advised.",
    "data-upload.warnings.in-use":
        "Some partition tables [{entityType} - {entityNames}] are in use right now. Parallel data uploads into the same tables are NOT advised.",

    "partition.data-management.tabs.uploads": "Data Uploads",
    "partition.data-management.tabs.upload-logs": "Logs",
    "partition.data-management.tabs.downloads": "Downloads",
    "partition.data-management.tabs.connections": "Connections",
    "partition.download.list.action.create": "Create Data Download",
    "partition.download.list.action.edit": "Edit Download",
    "partition.download.list.action.run": "Run Download",
    "partition.download.list.action.available-files": "Available Files",
    "partition.download.list.action.sftp-management": "SFTP User Management",
    "partition.download.list.action.delete": "Delete Download",
    "partition.download.list.action.modal.delete.title":
        "Delete this Data Download?",
    "partition.download.list.action.modal.delete.message":
        "The system will check any Event Orchestration dependencies before deleting this data download.This may take some time, you will be notified of the results.",
    "partition.download.list.message.delete-request.success":
        "Data download deletion has been requested successfully",
    "partition.download.available-files.perex":
        "Here you find files that have been downloaded to the PlatformManager. The files are available for 7 days.",
    "partition.download.available-files.columns.files": "Files",
    "partition.download.available-files.columns.size": "Size",
    "partition.download.available-files.columns.created-at": "Created at",
    "partition.download.available-files.columns.triggered-by": "Triggered by",
    "partition.download.form.name.label": "Name",
    "partition.download.form.destination.label": "Download destination",
    "partition.download.form.destination.tooltip":
        "With the “PlatformManager” option, the files will be stored in the application and accessible in the “Available files” section. “External Storage” allows you to save the files directly to your storage (SFTP, S3,…).",
    "partition.download.destination.option.pm": "PlatformManager",
    "partition.download.destination.option.external": "External Storage",
    "partition.download.form.action.export-csv": "Export to CSV",
    "partition.download.form.steps.entity-type.title": "Entity Type",
    "partition.download.form.steps.fields.title": "Fields",
    "partition.download.form.steps.filter.title": "Filter",
    "partition.download.form.steps.entity-type.perex":
        "Select one or more Pricefx entities (e.g. Customer, Product, Price Parameters).",
    "partition.download.form.steps.filter.perex":
        "This step is optional. You can skip it by pressing Continue button.",
    "partition.download.form.steps.destination.title": "Destination",
    "partition.download.new": "New Data Download",
    "partition.download.edit": "Edit Data Download",
    "partition.download.connections.action.delete.message":
        "Do you really want to delete the connection {name}?",

    "quick-data-upload.header.title": "Quick Data Upload",
    "quick-data-upload.perex":
        "Upload your data without parsing and mapping steps. The CSV must have at least one column, header row matching data table column names, and it must use a comma as a delimiter.",

    "quick-data-upload.upload.success":
        "{fileName} file successfully uploaded, parsed and mapped",
    "quick-data-upload.fields.upload.description":
        "Upload your data in {supportedFormats} format. Preferred file encoding format is UTF-8.",
    "quick-data-upload.fields.data-upload-name.label": "Date Upload Name",
    "quick-data-upload.fields.data-upload-name.placeholder":
        "Enter data upload name",
    "entity-type.fields.entity-type.label": "Entity Type",
    "entity-type.fields.entity-name.label": "Entity Name",
    "quick-data-upload.processed.success":
        "Your data upload was successfuly saved. You will be notified about the result in notifications or by email.",
    "quick-data-upload.download-error-file": "Download error file",
    "quick-data-upload.reupload": "Re-upload",
    "quick-data-upload.finish-upload": "Finish Upload",
    "route-edit-form.new-route": "New Route",
    "route-edit-form.edit-route": "Edit Route",
    "route-edit-form.description":
        "A route is a set of rules which describe how to react to a specific event (incoming SOAP call, uploaded CSV file etc.) and how to extract, transform and load the incoming data.",
    "route-edit-form.form.route-name.label": "Route Name",
    "route-edit-form.form.unique-name.label": "Unique Name",
    "route-edit-form.form.route-name.placeholder": "Enter route name",

    "mapper-form.title": "New Mapper",
    "mapper-form.title.edit": "Edit Mapper",
    "mapper-form.title.show": "Show Mapper",
    "mapper-form.button.import-from-xml": "Import from XML",
    "mapper-form.description":
        "Mappers are reusable code blocks which can be used in routes and their specific function is to map one type of data to another. The main types are loadMapper (to load bulk data fast) and integrateMapper (to perform an upsert operation of selected backend objects).",
    "mapper-form.form.info.maxLength":
        "Note: IM supports MaxLength property editable from versions 4.10.8 and 5.3.0.",
    "mapper-form.form.row.input": "Input",
    "mapper-form.form.row.output": "Output",
    "mapper-form.form.row.maxLength": "MaxLength",
    "mapper-form.form.row.maxLength.tooltip":
        "The MaxLength property limit is customizable to accommodate varying data requirements.",
    "mapper-form.form.mapper-name.label": "Mapper Name",
    "mapper-form.form.mapper-name.placeholder": "Enter mapper name",
    "mapper-form.form.mapper-name.required": "Mapper name is required",
    "mapper-form.form.mapper-name.wrong-format":
        "Mapper name has a wrong format",
    "mapper-form.form.type.label": "Mapper Type",
    "mapper-form.form.type.placeholder": "Select mapper type",
    "mapper-form.form.type.required": "Mapper type is required",
    "mapper-form.form.definition.required": "Definition is required",
    "mapper-form.form.saved": "The mapper was saved",
    "mapper-form.form.saved-and-deployed": "The mapper was saved and deployed",
    "mapper-form.form.tip": "Deploying mapper to IntegrationManager",
    "mapper-form.form.send-empty-value-as": "Send Empty Value As",
    "mapper-form.form.excludeProperties": "Exclude Properties",
    "mapper-form.form.props-and-records": "Properties and Records",
    "mapper-form.form.includeUnmappedProperties": "Include unmapped properties",
    "mapper-form.form.skipInvalidRecords": "Skip invalid records",
    "mapper-form.form.includeNulls": "Include Nulls",
    "mapper.convert-empty.empty": "Empty string",
    "mapper.convert-empty.null": "Null",
    "profile.nav-item.general": "General",
    "profile.nav-item.password": "Password",
    "profile.nav-item.tokens": "Tokens",
    "profile.nav-item.user-preferences": "User Preferences",
    "profile.nav-item.sso-username": "SSO Username",
    "profile.title.general-user-information": "General User Information",
    "profile.user-info.perex": "Edit your profile information.",
    "profile.password.success": "Password was changed",
    "profile.password.error": "An error occurred during password changing",
    "profile.password.title": "Login Password",
    "profile.password.perex": "Change password.",
    "profile.title.api-tokens": "API Tokens",
    "profile.title.user-preferences": "User preferences",
    "profile.label.username": "User Login Name",
    "profile.label.full-name": "Username",
    "profile.label.email": "Email",
    "profile.label.job-title": "Job Title",
    "profile.label.old-password": "Old password",
    "profile.placeholder.old-password": "Enter old password",
    "profile.label.new-password": "New password",
    "profile.placeholder.new-password": "Enter new password",
    "profile.label.repeated-password": "Repeat New password",
    "profile.placeholder.repeated-password": "Enter the new password again",
    "profile.button.delete": "Delete",
    "profile.error.missing-old-password": "Please enter the old password",
    "profile.error.missing-new-password": "Please enter a new password",
    "profile.error.missing-repeated-password":
        "Please enter the password again",
    "profile.error.pass-does-not-match":
        "The repeated password does not match the new password",
    "profile.header.token": "Token",
    "profile.header.token-name": "Token Name",
    "create-token.label.token-name.required": "Token name is required",
    "profile.header.action": "Action",
    "profile.button.create-token": "Create New API Token",
    "profile.remove-token.message":
        "Do you really want to remove token {name}?",
    "profile.message":
        'Create your API token. The token has to be set in the header: "{header}" in the format "{format}".',
    "profile.sso-username.text":
        "If you use SSO username with your SSO provider, fill it in.",
    "instances.restarting-routes-modal.title": "Restarting Routes",
    "instances.restarting-routes-modal.columns.routes": "Routes",
    "instances.restarting-routes-modal.columns.prn": "PRN",
    "instances.restarting-routes-modal.description":
        "For this change to take effect, all affected routes must be restarted. Do you want to restart them now?",
    "instances.transport.ready.modal.title": "Transport Ready",
    "instances.transport.encrypted-property-tooltip":
        "Contains encrypted data that become invalid in different environments due to unique secret keys. To transport it, you must enter a new value.",
    "instances.transport.ready.modal.content":
        "Transport from {instanceNameFrom} to {instanceNameTo} environment is ready.\nDo you want to deploy it?",
    "instances.transport.not-found.modal.title": "Transport not found",
    "instances.transport.not-found.modal.content":
        "Looks like the transport was already resolved.",
    "instances.transport.steps.in-progress.title": "Transport in Progress",
    "instances.transport.steps.target.title": "Target",
    "instances.transport.steps.objects.title": "Objects",
    "instances.transport.steps.properties.title": "Properties",
    "instances.transport.to.modal.warning.conections.message":
        "The Connections will not be transported. Ensure that you have created all the Connections used on the target environment.",
    "instances.transport.to.modal.warning.conections.target":
        "Target environment",
    "instances.transport.to.modal.title": "Transport to",
    "instances.transport.to.submit": "Transport",
    "instances.transport.to.modal.content":
        "You initiated transfer of code between environments. Select the target enviroment where you want transport the {instanceName} environment.",
    "instances.transport.to.modal.form.target.label": "Target Environment",
    "instances.transport.to.modal.warning":
        "Note that all the code (routes, mappers, filters, connections) will be transferred. It may be necessary to update some of the components for your integration to work in a different environment (typically, connection details need to be adjusted - URL, user, password, etc.).",
    "instances.transport.to.modal.form.target.placeholder":
        "Select environment",
    "instances.transport.to.modal.select-objects":
        "Select the objects which you want to transport from the {instanceName} environment.",
    "instances.transport.to.modal.select-objects.slow-warning":
        "The transport process can take several minutes. You will receive a notification when it is ready.",
    "instances.transport.to.modal.select-objects.path": "Object Name",
    "instances.transport.to.modal.select-objects.property":
        "Application Property",
    "instances.transport.to.modal.value": "Value",
    "instances.transport.to.modal.select-objects.routes": "Related Routes",
    "instances.transport.to.modal.success":
        "Transport is being prepared. You will be notified when it is ready.",
    "instances.transport.in-progress.modal.content":
        "Transport from {instanceNameFrom} to {instanceNameTo} is in progress. This process might take several minutes.\nYou can choose to wait and finish the deploy or to be notified.",
    "instances.transport.failed.modal.title": "Transport Failed",
    "instances.transport.failed.modal.content":
        "An error occured and the transport cannot be started. \n You will find more information in GIT: {url}",
    "instance-list.header.updated": "{when} by {whom}",
    "instance-list.title.im-instance": "IM Instances",
    "instance-list.message.deleted": "Instance was deleted successfully",
    "instance-list.message.not-deleted": "Instance cannot be deleted",
    "instance-list.button.new": "New IntegrationManager",
    "instance-list.menu.delete": "Delete IntegrationManager",
    "instance-list.menu.force-delete": "Force Delete IntegrationManager",
    "instance-list.modal.message":
        "Do you really want to delete the instance {instanceName} ?",
    "instance-list.header.instance-name": "Instance Name",
    "instance-list.header.operational-state": "Operational State",
    "instance-list.header.cloud-provider": "Cloud Provider",
    "instance-list.header.operational-state.state.INITIATED_INSTANCE_REQUEST":
        "Initiated instance request",
    "instance-list.header.operational-state.state.PROJECT_FAILED_TO_INITIALIZE":
        "Project failed to initialize",
    "instance-list.header.operational-state.state.REQUESTED_ENVIRONMENT":
        "Requested environment",
    "instance-list.header.operational-state.state.ENVIRONMENT_FAILED_TO_START":
        "Environment failed to start",
    "instance-list.header.operational-state.state.REQUESTED_INSTANCE":
        "Requested instance",
    "instance-list.header.operational-state.state.INITIATED_STOP":
        "Initiated stop",
    "instance-list.header.operational-state.state.REQUESTED_STOP":
        "Requested stop",
    "instance-list.header.operational-state.state.REQUEST_STOP_FAILED":
        "Request stop failed",
    "instance-list.header.operational-state.state.STOP_FAILED": "Stop failed",
    "instance-list.header.operational-state.state.STOPPED": "Stopped",
    "instance-list.header.operational-state.state.INITIATED_START":
        "Initiated start",
    "instance-list.header.operational-state.state.REQUESTED_START":
        "Requested start",
    "instance-list.header.operational-state.state.REQUEST_START_FAILED":
        "Request start failed",
    "instance-list.header.operational-state.state.START_FAILED": "Start failed",
    "instance-list.header.operational-state.state.STARTING": "Starting",
    "instance-list.header.operational-state.state.STARTED": "Started",
    "instance-list.header.operational-state.state.INITIATED_RESTART":
        "Initiated restart",
    "instance-list.header.operational-state.state.REQUESTED_RESTART":
        "Requested restart",
    "instance-list.header.operational-state.state.REQUEST_RESTART_FAILED":
        "Request restart failed",
    "instance-list.header.operational-state.state.RESTART_FAILED":
        "Restart failed",
    "instance-list.header.operational-state.state.REQUESTED_RESET":
        "Requested reset",
    "instance-list.header.operational-state.state.REQUEST_RESET_FAILED":
        "Requested reset failed",
    "instance-list.header.operational-state.state.RESET_FAILED": "Reset failed",
    "instance-list.header.operational-state.state.INITIATED_UPDATE":
        "Initiated update",
    "instance-list.header.operational-state.state.REQUESTED_UPDATE":
        "Requested update",
    "instance-list.header.operational-state.state.REQUEST_UPDATE_FAILED":
        "Request update failed",
    "instance-list.header.operational-state.state.UPDATE_FAILED":
        "Update failed",
    "instance-list.header.operational-state.state.INITIATED_SHUTDOWN":
        "Initiated shutdown",
    "instance-list.header.operational-state.state.REQUEST_SHUTDOWN":
        "Request shutdown",
    "instance-list.header.operational-state.state.REQUEST_SHUTDOWN_FAILED":
        "Request shutdown failed",
    "instance-list.header.operational-state.state.SHUTDOWN": "Shutdown",
    "instance-list.header.operational-state.state.SHUTDOWN_FAILED":
        "Shutdown failed",
    "instance-list.header.operational-state.state.UNDEFINED": "Undefined",

    "instance-list.header.instance-server": "Server",
    "instance-list.header.state": "Status",
    "instance-list.header.environment": "Environment",
    "instance-list.header.server-type": "Type",
    "instance-list.header.partition": "Partition",
    "instance-list.header.im-version": "IM Version",
    "instance-list.header.changed": "Changed",
    "instance-list.header.actions": "Actions",
    "instance-list.header.created-by": "Created by: {createdBy}",
    "instance-list.header.monitored": "Monitored : {monitored}",
    "instance-list.header.configurable-supported":
        "Remote configuration supported : {configurableSupported}",
    "instance-list.header.monitoring-recipients":
        "Monitoring recipients: {recipients}",
    "instance-list.header.created-at": "Created at: {createdAt}",
    "instance-list.button.start": "Start Integration",
    "instance-list.button.stop": "Stop Integration",
    "instance-list.button.restart": "Restart Integration",
    "instance-list.button.settings": "Settings",
    "instance-list.button.version-history": "Version History",
    "instance-list.button.deploy": "Deploy",
    "instance-list.button.delete": "Delete",
    "instance-list.button.transport": "Transport to",
    "instance-list.button.resolve-merge-request": "Resolve merge request",
    "instance-form.title": "New Integration",
    "instance-form.perex":
        "Here you can create a new Integration instance. When multiple instances are needed, it is recommended to create multiple environments under the same name to fully utilize UI aided development approach.",
    "instance-form.perex-note":
        '<b>Note:</b> Each instance name consists of prefix (pim - "provisioned IntegrationManager"), the name itself (company name, or something similar) and suffix (abbreviation of the environment purpose - e.g. dev"). <b>Prefix and suffix are added automatically, it is not needed to type them.</b>',
    "instance-form.title.edit": "Edit IM Instance General Properties",
    "instance-form.title.add": "Add IM Instance",
    "instance-form.title.instance-management": "Instance Management",
    "instance-form.error.instance-name.required":
        "Required. Please use at least 5 characters.",
    "instance-form.error.instance-name-aws.invalid":
        "Use lower case characters, numbers and a dash. Minimum length is 3, maximum 34.",
    "instance-form.error.required": "Required. ",
    "instance-form.error.email-format":
        "Wrong format. Use comma separated email addresses.",
    "instance-form.error.email-empty": "Please set email(s)",
    "instance-form.error.password-match": "Passwords do not match",
    "instance-form.label.general": "General",
    "instance-form.label.update": "Update",
    "instance-form.label.tags": "Tags",
    "instance-form.label.archive-settings": "Archive Settings",
    "instance-form.label.archive-query": "Archive Query",
    "im-archive-query.documentation-link-text": "documentation",
    "im-archive-query.perex":
        "This section allows to retrieve archived files. Please specify the query in order to get the relevant data from the archive. Consider finetuning your query before downloading, as archive search result for an oversimplified query can yield large amount of data, which would complicate further processing of the downloaded section of the archive. Check {documentationLink} for instructions and samples.",
    "im-archive-query.search.notification.success": "Queued",
    "im-archive-query.result.in-progress.cancel": "cancel",
    "im-archive-query.fields.queryMode.label": "File Name Query Mode",
    "im-archive-query.fields.queryMode.tooltip":
        "GLOB - simple pattern matching; example: **/*.csv matches all CSV files in all folders\nREGEX - regular expression pattern matching; example: .*event.* matches anything that contains event in the path",
    "im-archive-query.fields.value.label": "File Name Pattern",
    "im-archive-query.fields.value.placeholder": "Enter file name pattern",
    "im-archive-query.fields.value.tooltip":
        "Enter the expression according to selected Query Mode",
    "im-archive-query.fields.contentQueryMode.label": "Content Query Mode",
    "im-archive-query.fields.contentQueryMode.tooltip":
        "EXACT - searches for the exact sequence of characters in the file content\nREGEX - allows more flexible content matching by using regular expression language",
    "im-archive-query.fields.contentValue.label": "Content Query",
    "im-archive-query.fields.contentValue.placeholder": "Enter query",
    "im-archive-query.fields.contentValue.tooltip":
        "Enter the expression according to selected Query Mode",
    "im-archive-query.fields.dateRange.label": "Date Interval",
    "im-archive-query.result.in-progress.slow-search-warning-with-cancel":
        "Large number of files found. The query search might take several minutes. You can leave this screen and you will be notified when it is finished or {cancel} the query to start a new one.",

    "instance-form.label.expiration-date": "Expiration Date",
    "instance-edit-form.title": "Edit Integration",
    "instance-edit-form.perex":
        "You can edit the Integration instance. When multiple instances are needed, it is recommended to create multiple environments under the same name to fully utilize UI aided development approach.",
    "instance-edit-form.perex-note":
        '<b>Note</b>: Each instance name consists of prefix (pim - "provisioned IntegrationManager"), the name itself (company name, or something similar) and suffix (abbreviation of the environment purpose - e.g. "dev"). <b>Prefix and suffix are added automatically, it is not needed to type them.</b>',
    "instance-edit-form.confirmation.title": "Confirm changes",
    "instance-edit-form.confirmation.column.table-title":
        "Environment {envName}",
    "instance-edit-form.confirmation.column.item": "Item",
    "instance-edit-form.confirmation.column.original": "Original",
    "instance-edit-form.confirmation.column.newValue": "New Value",
    "instance-edit-form.confirmation.delete-warning.text":
        "After pressing Confirm the <b>{envName} environment</b> is deleted permanently.",

    "elk-tags.integration-tags.label": "ELK Tags",
    "elk-tags.validations.combined-length":
        "Combined length of all tags interspersed with separator must be less or equal to {maxCombinedLength}, current length is {length}",
    "im-archive.account.perex":
        "You can manage backups for your IntegrationManager instance here. You can add or delete folders and directories and change the period in which the backup occurs.",
    "im-archive.account.fields.enabled.label": "Archive",
    "im-archive.account.fields.enabled.checked": "Enabled",
    "im-archive.account.fields.enabled.unchecked": "Disabled",
    "im-archive.account.fields.dirs.folderName.label": "Directory",
    "im-archive.account.fields.dirs.limit.label": "Limit",
    "im-archive.account.fields.dirs.lastRun.label": "Last Run",
    "im-archive.account.fields.dirs.nextRun.label": "Next Run",
    "im-archive.account.fields.dirs.totalFilesCount.label": "# Files",
    "im-archive.account.fields.dirs.totalFilesSize.label": "File Size [MB]",
    "im-archive.account.add-dir.label": "+ Add Directory",
    "instance-form.label.runtime-properties": "Runtime Properties",
    "instance-form.label.application-properties": "Application Properties",
    "instance-form.label.management": "Management",
    "instance-form.label.monitoring": "Monitoring",
    "instance-form.label.connections": "Connections",
    "instance-form.label.integrations": "Integrations",
    "instance-form.label.type.manual": "Connect Existing Instance",
    "instance-form.label.type.provisioned": "Create New Instance",
    "instance-form.label.server-type": "Server Type",
    "instance-form.label.version": "Version",
    "instance-form.label.integration-name": "Integration Name",
    "instance-form.tooltip.integration-name":
        "Instance name automatically contains environment name. You do not need to add it.",
    "instance-form.placeholder.integration-name": "Enter Integration Name",
    "instance-form.label.integration-type": "Integration Type",
    "instance-form.placeholder.integration-type": "Select integration type",
    "instance-form.label.partition-name": "Partition Name",
    "instance-form.placeholder.partition-name": "Enter partition name",
    "instance-form.label.instance-server": "Integration Server",
    "instance-form.placeholder.instance-server": "Select integration server",
    "instance-form.label.monitored":
        "Monitoring instance checks if the IM is running or not",
    "instance-form.label.email": "Emails for notifications",
    "instance-form.label.report-monitored":
        "Monitoring report checks if the processing of records (via ELK data) is OK or not",
    "instance-form.label.report-recipients": "Notification Emails for Report",
    "instance-form.label.management-url": "Instance Management URL",
    "instance-form.label.management-username": "Instance Management Username",
    "instance-form.label.password": "Password",
    "instance-form.label.repeat-password": "Repeated password",
    "instance-form.label.environment": "Environment",
    "instance-form.label.profile": "Profile",
    "instance-form.label.cloud-provider": "Cloud Provider",
    "instance-form.label.region": "Region",
    "instance-form.label.storage": "Storage",
    "instance-form.label.expiration": "Expiration",
    "instance-form.label.cpu": "CPU",
    "instance-form.label.memory": "Memory",
    "instance-form.label.action": "Action",
    "instance-form.label.notes": "Notes",
    "instance-form.status.delete-failed": "Deletion failed",
    "instance-form.status.delete-failed.popover.text":
        "Due to some reasons deletion did not succeed.",
    "instance-form.status.delete-failed.popover.text2": "Try to delete again",
    "instance-form.status.missing-in-git": "Not displayed",
    "instance-form.status.missing-in-git.popover.text":
        "This environment is not currently visible in the Integration list. To resolve this issue, try restarting the instance.",
    "instance-form.status.missing-in-git.popover.link": "Restart",
    "instance-form.status.missing-in-git.resync.success":
        "Re-sync of the instance was successfully initiated.",

    "instance-form.collapse.settings": "Settings",
    "instance-form.collapse.architecture-information.perex":
        "In order to be able to better troubleshoot any future issues, we kindly ask you to provide the following information about the planned integration on this account.",

    "instance-form.label.lastChange": "Last Change",
    "instance-form.label.system": "ERP/CRM",
    "instance-form.tooltip.system":
        "ERP/CRM system this integration integrates with",
    "instance-form.label.direction": "Direction",
    "instance-form.tooltip.direction":
        "Integration direction from Pricefx partition perspective",
    "instance-form.label.description": "Description",
    "instance-form.tooltip.description":
        "Integration description (example - Inbound CSV, Outbound CSV and REST webservices)",
    "instance-form.label.technology": "Used Technology",
    "instance-form.tooltip.technology": "Camel, Groovy, Java",
    "instance-form.label.templatesUsage": "Usage of Integration templates",

    "instance-form.button.add": "Add Environment",
    "instance-form.button.test": "Test Rule",
    "instance-form.button.cancel": "Cancel",
    "instance-form.button.password.save": "Save Password",
    "instance-form.message.password-saved": "Password was saved",
    "instance-form.message.password-error":
        "Error occurred during password save",

    "new-instance.message.created-manual":
        "Instance has been created successfully.",
    "new-instance.message.created-provisioned":
        "Instance creation has been initiated. You will be notified by email once it is ready.",
    "new-instance.alert.not-enough-space":
        "There is currently not enough space on the infrastructure so the instance cannot be created right away. But don’t worry, we are already working on it. Feel free to continue with setting up the new instance, we will save it and notify you once it is finally created.",
    "instance-edit.message.submit-provisioned":
        "Instance modification has been initiated.",

    "integrations.route.blockly.name": "Name",
    "integrations.route.blockly.value": "Value",
    "integrations.route.blockly.mapper.new": "New",

    "blockly.select-properties.title": "Available properties",
    "blockly.select-properties.column.name": "Name",
    "blockly.select-properties.column.required": "Required",

    "route-definition-blockly.label.show-xml": "Show XML definition",

    "logger-panel.column.component": "Component",
    "logger-panel.column.level": "Level",
    "logger-panel.column.actions": "Actions",

    "select-columns.input.search.placeholder": "Enter your query",
    "select-columns.warning":
        "The table shows only the first 10 000 rows. To see the subsequent rows, change your filter.",
    "select-columns.modal.title": "Columns",
    "select-columns.info.found": "Found {number} rows",
    "select-columns.button.columns": "Manage Columns",
    "select-columns.button.export": "Export",

    "instance-messages.messages": "Messages",
    "instance-messages.events": "Events",
    "instance-messages.outgoing": "Outgoing",
    "instance-messages.incoming": "Incoming",
    "instance-messages.failed": "Failed",

    "failed-records.title": "Failed Records",
    "failed-records-count.title": "{recordsCount} Failed Records",
    "failed-records.old-unavailable-warning":
        "No details available. We do not store history older than 3 months.",
    "failed-records.no-details-error": "No details available. {message}",
    "failed-records.tooltip":
        "Displaying first 50 errors. It is possible to see maximum of 10 000 errors using the Export CSV button and analyze results in your local environment.",
    "failed-records.column.time": "Time",
    "failed-records.column.payload": "Payload",
    "failed-records.column.message": "Message",
    "failed-records.batch-failed":
        "There are no data because the whole batch failed.",

    "processed-files.warning":
        "The table shows only the first 10 000 rows. To see the subsequent rows, change your filter.",
    "processed-files.info.found": "Found {number} rows",
    "processed-files.search.placeholder": "Search",
    "processed-files.column.time": "Time",
    "processed-files.column.integration": "Integration",
    "processed-files.column.direction": "Direction",
    "processed-files.column.type": "Type",
    "processed-files.column.status": "Status",
    "processed-files.column.source": "Source",
    "processed-files.column.exception": "Exception",
    "processed-files.column.start-time": "Start Time",
    "processed-files.column.end-time": "End Time",
    "processed-files.column.status.successful": "Successful",
    "processed-files.column.status.failed": "Failed",

    "connection-list.title.connections": "Connections",
    "connection-list.header.unique-name": "Unique Name",
    "connection-list.header.type": "Type",
    "connection-list.header.debug": "Debug",
    "connection-list.header.created-at": "Created at",
    "connection-list.header.created-by": "Created by",
    "connection-list.header.updated-by": "Updated by",
    "connection-list.header.updated-at": "Updated at",
    "connection-list.header.actions": "Actions",
    "connection-list.header.path": "Path",
    "connection-list.button.edit": "Edit",
    "connection-list.button.send-data": "Send Data",
    "connection-list.button.delete": "Delete",
    "connection-list.button.new": "New Connection",
    "connection-list.copy.title": "Copy Connection",
    "connection-list.error.cannot-set-debug":
        "Unable to run debug for connection",
    "connection-list.error.cannot-resolve-im-version":
        "Unable to resolve IM version",
    "certificate-list.header.unique-name": "Name",
    "certificate-list.header.type": "Type",
    "certificate-list.header.status": "Status",
    "certificate-list.button.new": "New Certificate",
    "certificate-form.label.name": "Certificate Name",
    "certificate-form.label.certificate-type": "Type",
    "certificate-form.label.certificate-body": "Body",
    "certificate-form.placeholder.name": "Enter certificate name",
    "certificate-form.title.new-certificate": "New Certificate",
    "certificate-form.title.edit-certificate": "Edit Certificate",
    "certificate-form.saving.tip": "Saving the certificate",
    "certificate-form.updated": "The certificate was updated.",
    "certificate-form.updatedAndDeployed":
        "The certificate was updated and deployed.",
    "certificate-form.created": "The certificate was created.",
    "certificate-form.createdAndDeployed":
        "The certificate was created and deployed.",
    "certificate-form.encryption.not-supported":
        "For increased security, we strongly recommend that you only store encrypted certificates. This feature is supported in version {version} and above. Please consider upgrading before using certificates.",
    "certificate-form.encryption.supported":
        "For increased security, we strongly recommend that you only store encrypted certificates.",
    "certificate-form.header-description.new-certificate":
        "Certificate can be used in connections.",
    "certificate-form.loading": "Loading...",
    "certificate-form.deployment.success": "The certificate was deployed",
    "certificate-form.deployment.not-supported":
        "IM instance version {currentVersion} does not support deployment. Feature is available from IM version {minVersion}.",
    "im-instance.version-alert.delete-after-deploy":
        "IM instance version {currentVersion} does not support object deletion from Workspace after deployment. The feature is available in IM version {minVersion} and newer.",
    "im-instance.version-alert.fetch-instance-error":
        "It was not possible to load the IM instance version to evaluate the feature availability",
    "im-instance.create-support-ticket": "Create Support Ticket",
    "im-instance.support-ticket-created":
        "Support ticket {id} was successfully created",
    "im-instance.update.leave.modal.save-and-leave": "Save & Leave",
    "im-instance.update.continue.modal.title": "Continue Update",
    "im-instance.update.continue.modal.message":
        "We found an unfinished update for this instance. Do you want to continue with it or delete the progress and start a new update from scratch?",
    "im-instance.update.leave.modal.title": "Leaving the Update",
    "im-instance.update.leave.modal.message":
        "Do you want to save the progress of the update so you can finish it later? The progress will be stored for <b>{days}</b>, after that it will be deleted.",
    "im-instance.update.leave.model.cancel.success":
        "Update was successfully canceled",
    "im-instance.update.in-conflict.modal.title":
        "Current Update is in Conflict",
    "im-instance.update.in-conflict.modal.message":
        "We found that analysis is in conflict with changes in git. The update wizard needs to be restarted.",
    "im-instance.update.in-conflict.modal.action": "Start new update",
    "im-instance.update.direct.modal.title": "Direct Update",
    "im-instance.update.direct.modal.message":
        "You have selected the Direct Update that skips some recommended steps. This option should be used by experienced users only.",
    "im-instance.update.direct.modal.warning":
        "By continuing you agree that you know all the risks associated with it and that Pricefx is not responsible for any damage caused by this action. ",
    "im-instance.update.update.in-progress":
        "Update is running, this can take up to {minutes} minutes to complete.{br}You can wait or leave the step and we will notify you when it's finished.",
    "im-instance.update.update.progress": "Updating...",
    "im-instance.update.result": "Update Result",
    "im-instance.update.result.success": `All the changes were successfully merged and the instance was updated to version {version}.
We will now restart the instance and refresh the version number to finish the update. Please note that this might take several minutes.`,
    "im-instance.update.result.merge-needed":
        "Update is partially completed, you need to merge the changes: ",
    "im-instance.update.new-version-available":
        "There is even a newer version available for this Instance.{br}Do you want to start a {link}?",
    "im-instance.update.new-version-available.link": "new update",

    "im-instance.update.test-update.in-progress":
        "The test of the update is running, this can take up to {minutes} minutes to complete.{br}You can wait or leave the step and we will notify you once it is finished.",
    "im-instance.update.test-update.column.test-type": "Test Type",
    "im-instance.update.test-update.column.test-result": "Test Result",
    "im-instance.update.test-update.progress": "Testing...",
    "im-instance.update.test-update.test-result": "Test Result",
    "im-instance.update.test-update.test-result.success":
        "Test was successfully finished.{br}Based on the results we recommend to proceed with the Instance update.",
    "im-instance.update.code-modification.in-progress":
        "Code modification is in progress, this can take up to {seconds} seconds to complete.{br}You can wait or leave the step and we will notify you once it is finished.",
    "im-instance.update.code-modification.result": "Code Modification Result",
    "im-instance.update.code-modification.progress": "Modifying...",
    "im-instance.update.code-modification.success":
        "Code modification was successfully finished.",
    "im-instance.update.code-analysis.view-in-git": "View in Git",
    "im-instance.update.code-analysis.result": "Code Analysis Result",
    "im-instance.update.code-analysis.empty-result":
        "There are no changes in your code",
    "im-instance.update.code-analysis.no-results":
        "There are no changes to analyze.",
    "im-instance.update.code-analysis.route-numbered": "Route {number}",
    "im-instance.update.code-analysis.perex":
        "We are analyzing the Instance code, this can take up to {seconds} seconds to complete.{br} You can wait or leave the step and we will notify you once it is finished.",
    "im-instance.update.current-version":
        "Your Instance is running on version <b>v{imVersion}</b>, available updates:",
    "im-instance.update.no-changes.perex":
        "There are no changes, you can perform Direct Update.",
    "im-instance.update.changes.perex":
        "These are the changes for this update that could potentially break the instance:",
    "im-instance.update.button.code-modification": "Code Modification",
    "im-instance.update.button.test-update": "Test Update",
    "im-instance.update.button.create-support-ticket": "Create Support Ticket",
    "im-instance.update.button.code-analysis": "Code Analysis",
    "im-instance.update.button.re-analyze": "Re-Analyze",
    "im-instance.update.button.start-update": "Start Update",
    "im-instance.update.button.re-test-update": "Re-Test Update",
    "im-instance.update.step.update-check": "Update Check",
    "im-instance.update.step.code-analysis": "Code Analysis",
    "im-instance.update.step.code-modification": "Code Modification",
    "im-instance.update.step.test-update": "Test Update",
    "im-instance.update.step.update": "Update",
    "im-instance.update.button.direct-update": "Direct Update",
    "im-instance.update.release-notes": "See complete {link} for this version.",
    "im-instance.update.release-notes-link": "release notes",
    "im-instance.update.continue-to-next-step": "Continue to Next Step",
    "im-instance.update.continue-to-next-step-perex":
        "Next step will perform the analysis of the Integration Instance and list required changes.",
    "im-instance.update.code-analysis.continue-to-next-step-perex":
        "The following step will trigger the automatic application of the suggested code changes..",
    "im-instance.update.code-modification.continue-to-next-step-perex":
        "In the next step, the actual update of the Integration Instance will start automatically and the changes prepared in the previous steps in the sandbox environment will be merged into the code base of the Instance.",
    "im-instance.update.test-update.continue-to-next-step-perex":
        "In the next step, the actual update of the Integration Instance will start automatically and the changes prepared in the previous steps in the sandbox environment will be merged into the code base of the Instance.",

    "connection.copy.label.integration-instances": "Integration Instances",
    "connection.copy.label.connection-name": "Connection Name",
    "connection.copy.validation-message.name-duplication":
        "Connection already exists for the selected Integration Instance",
    "connection.copy.done": "The connection has been copied",
    "connection.copy.modal.title": "Copy Connection",

    "filter-list.button.new": "New Filter",

    "filter-list.copy.title": "Copy Filter",

    "filter.copy.label.connection": "Connection",
    "filter.copy.label.integration-instance": "Integration Instance",
    "filter.copy.label.filter-name": "Filter Name",
    "filter.copy.validation-message.name-duplication":
        "Filter already exists for the selected Integration Instance",
    "filter.copy.done": "The filter has been copied",
    "filter.copy.modal.title": "Copy Filter",

    "filter.create": "The filter was created.",
    "filter.edit": "The filter was updated.",

    "filter-form.form.tip": "Please wait",
    "filter-form.title": "New Filter",
    "filter-form.title.edit": "Edit Filter",
    "filter-form.name": "Name",
    "filter-form.definition": "Definition",
    "filter-form.connection": "Connection",
    "filter-form.description":
        "Filters are used by routes to fetch or delete data from a partition.",

    "image-info.conversion.title-convert": "Custom Image Conversion",
    "image-info.conversion.title-to-standard": "Conversion back to Standard",
    "image-info.conversion.content-convert":
        "This action is required to allow adding additional dependencies into pom.xml.",
    "image-info.conversion.content-to-standard":
        "You may simplify the IM instance in case the custom image is not needed anymore.",
    "image-info.conversion.action-convert": "Convert to Custom Image",
    "image-info.conversion.action-convert-to-standard": "Convert to Standard",
    "image-info.modal.conversion.title": "Convert to Custom Image",
    "image-info.modal.conversion.title-rebuild": "Custom Image Rebuild",
    "image-info.modal.conversion.title-to-standard": "Convert Back to Standard",
    "image-info.modal.conversion.message":
        "Conversion to Custom Image is an action intended for experienced integration developers, proceed with caution.",
    "image-info.modal.conversion.message-rebuild":
        "Rebuilding custom image might be needed when adding external resources to the repository. The action is intended for experienced integration developers, proceed with caution. ",
    "image-info.modal.conversion.message-to-standard":
        "The IM instance will be converted back to the standard generic image. Any dependencies manually added to pom.xml will be lost.",
    "image-info.modal.conversion.documentation":
        "For more information visit the {documentationLink}.",
    "image-info.modal.conversion.warning":
        "Conversion of the Integration instance to the custom image is a process that requires rebuilding and restarting the instance. This operation will take several minutes.",
    "image-info.modal.conversion.warning-rebuild":
        "Custom Image Rebuild of the integration instance is a one-way action that requires restarting of the instance. This operation might take several minutes.",
    "image-info.modal.conversion.warning-to-standard":
        "Custom Image Rebuild of the integration instance is a one-way action that requires restarting of the instance. This operation might take several minutes.",
    "image-info.modal.conversion.success":
        "Integration {instanceName} is now being converted to a Custom image. It will be ready in a few minutes.",
    "image-info.modal.conversion.success-rebuild":
        "Rebuild of custom image has been initiated. It will be ready in a few minutes.",
    "image-info.modal.conversion.to-standard.success":
        "Integration {instanceName} is now being converted back to Standard image. It will be ready in few minutes.",

    "custom-image-state.conversion.being-rebuilt": "Being rebuilt",
    "custom-image-state.conversion.being-converted": "Being converted",
    "custom-image-state.conversion.ok": "Ok",
    "custom-image-state.conversion.broken": "Broken",
    "custom-image-state.action.rebuild": "Rebuild",
    "custom-image-state.action.convert": "Convert",

    "health-chart.title": "Health State",
    "health-chart.filter.day": "Day",
    "health-chart.filter.week": "Week",
    "health-chart.filter.month": "Month",
    "system-health.status.loading": "Loading IntegrationManager status",
    "system-health.widget.system-info": "System Info",
    "system-health.widget.build-info": "Build Info",
    "system-health.widget.build-id": "Build ID",
    "system-health.widget.image": "Image",
    "system-health.widget.image.type.standard": "Standard",
    "system-health.widget.git-commit": "Git Commit",
    "system-health.widget.im": "IntegrationManager",
    "system-health.widget.version": "Version",
    "system-health.widget.route-count": "Routes",
    "system-health.widget.environment": "Environment and ELK",
    "system-health.widget.environment-type": "Type",
    "system-health.widget.elk-last-timestamp": "Last ELK",
    "system-health.widget.system": "System Info",
    "system-health.widget.os": "{os} PID",
    "system-health.widget.uptime": "Uptime",
    "system-health.widget.status": "Status",
    "system-health.widget.routes-visible": "Routes visible: {routesVisible}",
    "system-health.widget.instance-info": "Instance Info",
    "system-health.widget.git-repo": "Git Rep.",

    "processed-files.search.start-time": "Start time",
    "processed-files.search.end-time": "End time",

    "widget-01.view-more": "View More",

    "page.new-password.title": "New password",
    "page.new-password.desc": "Set new password",
    "page.new-password.form.password.placeholder": "Enter new password",
    "page.new-password.form.submit": "Set password",
    "page.new-password.back": "Back to login page",
    "page.new-password.success": "New password successfully set.",

    "page.500.title": "Internal server error",
    "page.500.desc":
        "An error occurred on the server. Please try again later. If the issue persists, please contact support, provide screenshot of this page and copy the technical information below.",

    "page.404.go-to-dashboard": "Go to Dashboard",
    "page.404.title": "The page you’re looking for couldn’t be found",
    "page.404.desc":
        "Check the web address any try again. Or navigate to the page from main menu on top.",

    "page.login-error.title": "Login error",
    "page.login-error.action": "Login Page",
    "page.login-error.description":
        "No saved authentication request was found. Go back to Login page and try to log again or create a support ticket.",

    "connection-form.title.edit-connection": "Edit Connection",
    "connection-form.title.new-connection": "New Connection",
    "connection-form.header-description.new-connection":
        "Connections allow you to connect to a Pricefx partition or 3rd party system, namely SAP S/4HANA.",
    "connection-form.label.name": "Name",
    "connection-form.placeholder.name": "Enter connection name",
    "connection-form.label.select-connection":
        "Select Connection of {connectionType} type",
    "connection-form.label.missing-connection":
        "There is no connection of the {connectionType} type. Please go to Admin" +
        " section and create such connection. ",
    "connection-form.label.connection-type": "Connection type",
    "connection-form.placeholder.connection-type": "Select connection type",
    "connection-form.button.save": "Save",
    "connection-form.button.test": "Test connection",
    "connection-form.error.required": "{name}  is required",
    "connection-form.deployment.tip": "Deploying the connection",
    "connection-form.saving.tip": "Saving the connection",
    "connection-form.deployment.success": "The connection was deployed",
    "connection-form.test.successful": "The connection test was successful.",
    "connection-form.test.unsuccessful":
        "The connection test was unsuccessful. {error}",
    "instance-entity.alerts.name-change":
        "Changing a {entity} name which is already used in a route can break the integration. Changed properties will be deployed to the running environment together with the route deployment.",
    "connection-form.loading": "Loading...",
    "connection-form.updated": "The connection was updated.",
    "connection-form.updated-and-deployed":
        "The connection was updated and deployed.",
    "connection-form.created": "The connection was created.",
    "connection-form.created-and-deployed":
        "The connection was created and deployed.",
    "connection-form.button.open-admin-section": "Open admin section.",

    "partition-send-data.step2.title": "Upload File",
    "partition-send-data.step2.description":
        "Provide here your file whose fields correspond to the entity you are going to update. Visit our {link} for more information about the supported formats and file requirements.{br}{br}In the following steps, you will map the fields to the mandatory/optional Pricefx fields.",
    "partition-send-data.step2.description.link-text": "knowledge base",
    "partition-send-data.step2.upload.hint":
        "Upload your data in {supportedFormats} format. Preferred file encoding format is UTF-8.",

    "partition-send-data.button.continue": "Continue",
    "partition-send-data.button.cancel": "Cancel",
    "partition-send-data.button.finish": "Finish Upload",
    "send-data-mapper.header.column-name": "Column Name",
    "send-data-mapper.header.column-mapped-column": "Mapped to Column",

    "partition-send-data.title.data-upload": "New Data Upload",
    "partition-send-data.title.entity-type": "Entity Type",
    "partition-send-data.title.description":
        "Select one of the Pricefx entities (e.g. Customer, Product, Price Parameters) at the selected partition with your own CSV file.",
    "partition-send-data.placeholder.select-data-input-type":
        "Select entity type",
    "partition-send-data.placeholder.select-partition": "Select partition",
    "partition-send-data.placeholder.name": "Name",
    "partition-send-data.placeholder.separator": "Insert field separator",
    "partition-send-data.placeholder.quote-char": "Insert quote character",
    "partition-send-data.placeholder.escape-char": "Insert escape character",
    "partition-send-data.label.upload-file": "Upload file",
    "partition-send-data.error.wrong-input-data":
        "Data header does not match the specified filter data",
    "partition-send-data.label.partition": "Partition",
    "partition-send-data.label.name": "Data Upload Name",
    "partition-send-data.label.type": "Type",
    "partition-send-data.label.mass-delete-strategy": "Delete original data",
    "partition-send-data.label.schedule":
        "Set up the date and time to process your uploaded data",
    "partition-send-data.label.ignore-quotations": "Ignore Quotations",
    "partition-send-data.label.strict-quotes": "Strict Quotes",
    "partition-send-data.button.advance-parsing-options":
        "Advance Parsing Options",
    "partition-send-data.button.save": "Save",
    "partition-send-data.message.parsed-columns":
        "Parsed columns ({count}): [{columns}]",
    "partition-send-data.label.mass-delete-strategy.never":
        "Never – Adds new lines and updates the existing ones",
    "partition-send-data.label.mass-delete-strategy.before":
        "Before upload – The  existing data is replaced by new upload",
    "partition-send-data.label.mass-delete-strategy.after":
        "After upload – Not updated records of a given entity are deleted",

    "partition-send-data.label.mass-delete-strategy.tooltip":
        "Never – No data is deleted. The process works as UPSERT. It adds new lines and updates the existing ones. Before upload – The data is replaced by this data upload. This helps you prevent duplicate data on the server in case you cannot ensure a unique ID for each record. After upload – After a successful import of a new file only not updated records of a given entity are deleted.",
    "partition-send-data.label.send-email":
        "Receive an email once the data upload is finished.",
    "partition-send-data.label.scheduled-time": "Upload Date & Time",
    "partition-send-data.delete-after-warning.title": "Warning",
    "partition-send-data.delete-after-warning.message":
        "You have selected that original data should be deleted after upload. This setting might cause an unwanted deletion of your original data if there is an error in the newly uploaded file.",
    "partition-send-data.delete-after-warning.ok": "Keep Setting & Continue",
    "partition-send-data.tooltip.scheduled-time":
        "Set up the date and time to process your uploaded data.",
    "supported-table-import-type.label.competition-data": "Competition Data",
    "supported-table-import-type.label.condition-records": "Condition Records",
    "supported-table-import-type.label.seller": "Seller",
    "supported-table-import-type.label.seller-extension": "Seller Extension",
    "supported-table-import-type.label.live-price-grid": "Live Price Grid",
    "supported-table-import-type.label.x-live-price-grid":
        "Live Price Grid Matrix",
    "supported-table-import-type.label.x-price-list": "Matrix Price List",
    "supported-table-import-type.label.data-source": "Data Source",
    "supported-table-import-type.label.data-mart": "Datamart",
    "supported-table-import-type.label.product": "Product",
    "supported-table-import-type.label.product-extension": "Product Extension",
    "supported-table-import-type.label.product-reference": "Product Reference",
    "supported-table-import-type.label.customer": "Customer",
    "supported-table-import-type.label.customer-extension":
        "Customer Extension",
    "supported-table-import-type.label.company-parameter": "Company Parameters",
    "supported-table-import-type.label.company-parameter-2-key":
        "Company parameter 2 keys",
    "supported-table-import-type.label.company-parameter-3-key":
        "Company parameter 3 keys",
    "supported-table-import-type.label.company-parameter-4-key":
        "Company parameter 4 keys",
    "supported-table-import-type.label.company-parameter-5-key":
        "Company parameter 5 keys",
    "supported-table-import-type.label.company-parameter-6-key":
        "Company parameter 6 keys",
    "supported-table-import-type.label.bom-data": "BoM Data",
    "supported-table-import-type.label.user": "User",
    "supported-table-import-type.label.price-lists": "Price Lists",
    "supported-table-import-type.label.manual-price-lists":
        "Manual Price Lists",

    "data-upload-files.title.data-upload-files": "Uploaded Files",
    "data-upload-files.button.back": "Back",

    "search-panel.partition.label": "For Partition",

    "search-panel.date-range.label": "Date",
    "search-panel.date-range.placeholder-from": "Start Date",
    "search-panel.date-range.placeholder-to": "End Date",

    "table-metadata-form.placeholder.entity-type": "Select entity type",
    "table-metadata-form.placeholder.entity-name": "Select entity name",
    "table-metadata-form.label.entity-type": "Entity Type",
    "table-metadata-form.label.entity-name": "Entity Name",
    "table-metadata-form.error.unable-fetch-data":
        "Unable to fetch data from the partition",
    "table-metadata-form.button.new": "Create New Table",
    "new-table.step-create.title": "Set New Table",
    "new-table.step-configure.title": "Configure",
    "new-table.step-configure.perex": "Config Field Types",
    "new-table.step-configure.column.name": "Field Name",
    "new-table.step-configure.column.label": "Label",
    "new-table.step-configure.column.type": "Type",
    "new-table.px-name.label": "Product Extension Name",
    "new-table.sx-name.label": "Seller Extension Name",
    "new-table.cx-name.label": "Customer Extension Name",
    "new-table.label.label": "Label",
    "new-table.numberOfAttributes.label": "Size",
    "new-table.allowSearch.label": "Allow Search",
    "new-table.allowPASearch.label": "Allow Analytics Search",
    "new-table.userGroupEdit.label": "User Group (Edit)",
    "new-table.userGroupViewDetails.label": "User Group (View Details)",
    "new-table.businessKey.label": "Business key",
    "new-table.cx.uniqueName.label": "Company Parameter Name",
    "new-table.cx.label.label": "Label",
    "new-table.cx.validAfter.label": "Valid After",
    "new-table.cx.type.label": "Table Type",
    "new-table.cx.valueType.label": "Value Type",
    "new-table.cx.status.label": "Status",
    "new-table.cx.simulationSet.label": "Simulation Set",
    "new-table.cx.formatType.label": "Format Type",
    "customer-integration-list.title.customer-integration": "Integrations",
    "customer-integration-list.button.new": "New Route",
    "customer-integration-list.modal.title": "Delete Integration",
    "customer-integration-list.modal.message":
        "Do you really want to delete the integration {accountIntegrationName}?",
    "new-resource.fields.resourceName.label": "Resource Name",
    "new-resource.fields.resourceName.placeholder": "Name of the Resource",
    "new-resource.messages.success": "Resource has been deployed",
    "new-resource.messages.file-not-uploaded-yet":
        "The file must be uploaded to finish the deployment.",
    "new-resource.perex":
        "Resources are various files that contains different content (images, templates, schemas). Such resources are useful in IntegrationManager, i.e. email template, logo in the template, JSON schema of the payload etc.",
    "resources-list.header.name": "Resource Name",
    "resources-list.header.type": "Type",
    "resources-list.header.status": "Status",
    "resources-list.header.created-at": "Created at",
    "resources-list.header.created-by": "Created by",
    "resources-list.header.uploaded-at": "Uploaded at",
    "resources-list.header.uploaded-by": "Uploaded by",
    "resources-list.new-resource": "New Resource",
    "resources-list.deletion-requested":
        "Resource deletion has been requested successfully.",
    "customer-integration-list.header.name": "Name",
    "customer-integration-list.header.unique-name": "Unique Name",
    "customer-integration-list.header.type": "Integration Type",
    "customer-integration-list.header.status": "Status",
    "customer-integration-list.header.deployment-status": "Deployment Status",
    "customer-integration-list.header.changed": "Changed",
    "customer-integration-list.header.actions": "Actions",
    "customer-integration-list.button.delete": "Delete",
    "customer-integration-list.header.revision-number": "Revision Number",

    "mapper-list.title.mappers": "Mappers",
    "mapper-list.button.new": "New Mapper",
    "mapper-list.modal.title": "Delete Mapper",
    "mapper-list.modal.message":
        "Do you really want to delete the mapper {mapperName}?",
    "mapper-list.header.name": "Name",
    "mapper-list.header.non-standard-route": "Non-standard route",
    "mapper-list.header.non-standard-mapper": "Non-standard mapper",
    "mapper-list.header.non-standard-filter": "Non-standard filter",
    "mapper-list.header.unique-name": "Unique Name",
    "mapper-list.header.type": "Type",
    "mapper-list.header.status": "Status",
    "mapper-list.header.changed": "Changed",
    "mapper-list.header.revision-number": "Revision Number",
    "mapper-list.header.actions": "Actions",
    "mapper-list.header.path": "Path",
    "mapper-list.button.copy": "Copy",
    "mapper-list.message.error": "This mapper is in use and cannot be deleted.",
    "mapper-list.copy.title": "Copy Mapper",
    "mapper-list.deploy.confirm.title": "Deploy to Instance Repository",
    "mapper-list.deploy.confirm.message":
        "Mapper {name} will be deployed to Instance Repository. Do you really want to deploy it?",
    "mapper-list.create-local-copy.title": "Create Local Copy",
    "mapper-list.show-mapper": "Show Mapper",
    "mapper-list.show-definition": "Show Definition",

    "mapper-definition.title": "Mapper definition",

    "filters-list.show-definition": "Show Definition",
    "filter-definition.title": "Filter definition of {prn}",
    "connections-definition.title": "Connection definition of {prn}",
    "classes-definition.title": "Class definition of {prn}",

    "instance-repo.conflict.modal.title": "Changes to Instance Repository",
    "instance-repo.conflict.modal.perex":
        "Since the creation of a local copy there were changes in Instance Repository. The last change was made by {updatedBy} at {updatedAt}. Do you want to overwrite it and deploy it?",
    "instance-repo.conflict.modal.instance-repo.label":
        "Instance Repository Code",
    "instance-repo.conflict.modal.workspace.label": "Workspace Code",

    "mapper.copy.label.integration-instances": "Integration Instances",
    "mapper.copy.label.mapper-name": "Mapper Name",
    "mapper.copy.validation-message.name-duplication":
        "Mapper already exists for the selected Integration Instance",
    "mapper.copy.done": "The mapper has been copied",
    "mapper.copy.modal.title": "Copy Mapper",
    "mapper.local-copy.modal.title": "Create {entityName} Local Copy",
    "mapper.local-copy.form.name.label": "Name",
    "mapper.local-copy.form.name.placeholder": "Local copy name",

    "package-data-upload.add-custom-field": "Add custom field",
    "package-data-upload.custom-field": "Custom Field",
    "custom-fields.modal.title": "Create New Custom Field",
    "custom-fields.modal.form.name": "Name",
    "custom-fields.modal.form.label": "Label",
    "custom-fields.modal.form.type": "Data type",
    "custom-fields.modal.form.format": "Format",
    "custom-fields.modal.form.measureType": "Measure",
    "custom-fields.modal.form.dimension": "Dimension",
    "custom-fields.modal.form.function": "Function",
    "custom-fields.modal.divider.optional-fields": "Optional Fields",

    "create-token.title.create-token": "Create Token",
    "create-token.label.token-name": "Token Name",
    "create-token.placeholder.token-name": "Token name",
    "create-token.placeholder.comment": "Insert token description",
    "create-token.button.create": "Create",
    "create-token.button.back": "Back",
    "create-token.title.modal-created-token": "Created Token",
    "create-token.message.modal-created-token":
        'Your generated token is:{br}"{token}".',
    "create-token.message.store-in-safe-place": "Store it in a safe place.",
    "table-total-rows": "{totalRows} entries",
    "table-header.button.delete": "Delete",
    "table-header.message.selected": "{selectedRows} selected",

    "package-select.title": "Accelerator Packages",
    "package-select.select-info": "Step 1 - Select Package",
    "package-select-ext-connection-label": "Connection to retrieve values",
    "package-select-ext-connection-description":
        "It is needed to specify connection for getting select options from external system for field: {label}",

    "package.process.default-10-minutes":
        "This setup can take up to 30 minutes.",
    "package.process.spam-warning":
        "The email should arrive in your inbox shortly. Please check your spam folder if needed.",
    "package.process.set-notification":
        "If you would like to be notified by email when the data upload is complete, use the toggle button. You may then exit the browser.",
    "package.process.skip.optional":
        "This step is optional. You can {skip} it or continue.",
    "package.process.skip.deployed":
        "Some data were found on the partition. You can overwrite them or continue with the next step by clicking {skip}.",
    "package.process.skip.deployed-and-optional-allow-use-existing":
        "Some data were found on the partition. You can overwrite them, use them by clicking {useExisting} or continue with the next step by clicking {skip}.",
    "package.process.skip.deployed-and-optional":
        "Some data were found on the partition. You can overwrite them or continue with the next step by clicking {skip}.",
    "package.process.skip": "skip",
    "package.process.use-existing": "use existing",
    "package.process.continue": "Continue",

    "package-data-upload.title": "Data Upload and Mapping",
    "package-data-upload.button.skip": "Skip",
    "package-data-upload.message": "Please upload a file for {tableName}",
    "package-data-upload.button.next": "Next",
    "package-data-upload.button.back": "Back",
    "package-data-upload.or-download-example-file": " or {downloadButton}",
    "package-data-upload.button.download-example-file":
        "download sample file as reference",
    "package-data-upload.upload.hint":
        "Upload your data for {tableName} in the CSV, XLSX or zipped format. Preferred file encoding format is UTF-8.",

    "package-file-parsing.options.message": "Parsing Options",
    "package-file-upload.options.message": "Upload Options",
    "package-file-parsing.warning.no-values-present":
        "All columns with no value will be considered as the String type. This may cause problems" +
        " if number or date fields are required.",
    "package-file-upload.parsing.error":
        "The provided file cannot be parsed. {error}",

    "package-data-upload.mandatory-files.message":
        "Map your {fileName} fields to Pricefx mandatory fields",
    "package-data-upload.extension-mandatory-files.message":
        "Map your field to the Pricefx solution ",

    "package-data-upload.extension-preview.message": "Create Extension",
    "package-data-upload.price-parameters-mapper-message":
        "Create your Price Parameter table",
    "package-data-upload.additional-price-parameters-mapper-message":
        "Map your {fileName} fields to Pricefx additional fields",
    "package-data-upload.optional-files.message":
        "Map your fields to Pricefx optional fields",
    "package-data-upload.label.mandatory-fields": "Pricefx mandatory fields",
    "package-data-upload.label.additional-fields": "Pricefx additional fields",
    "package-data-upload.label.optional-fields": "Source optional fields",
    "package-data-upload.label.multiple-fields": "Multiple fields",
    "package-data-upload.label.file-fields": "{fileName} fields",
    "package-data-upload.label.skip": "Skip",
    "package-data-upload.label.business-key": "Business Key",
    "package-data-upload.label.pfx-fields": "Pricefx fields",
    "package-data-upload.label.name": "Field name in Pricefx",
    "package-data-upload.label.type": "Type",
    "package-data-upload.label.extension-optional-fields": "{fileName} fields",
    "package-label.extension-otp-fields.column-type": "Column Type",

    "package-stepper.step-table-name": "{name}",
    "package-step.deployment-restart.successfully":
        "Package deployment restarted successfully",
    "package-failed-option.title": "Error occurred",
    "package-failed-option.failed-message":
        "Error occurred during setting up the {stepMessage}.",
    "package-failed-option.info-message":
        "Try this step again or leave it for later. We have saved your progress and next time you can continue with where you left.",
    "package-failed-option.button.try-later": "Save for Later",
    "package-failed-option.button.try-again": "Try Again",
    "package-failed-option.message-step-restarted":
        "{stepLabel} step restarted successfully",
    "package-failed-option.message-step-restarted-fail":
        "Unable to restart step {stepLabel}",
    "package.import-info-modal.title": "Unfinished {label} Import",
    "package.import-info-modal.unfinished-same-deployment":
        "There is an unfinished deployment of {templateName} from {date} started by {user}",
    "package.import-info-modal.unfinished-deployment.title":
        "Unfinished Deployment Found",
    "package.import-info-modal.start-new-import": "Start New Deployment",
    "package.error-page-button-return-to-packages":
        "Return to Accelerator Packages",
    "package.import-info-modal.continue-deployment":
        "Finish Previous Deployment",
    "package.import-info-modal.text-question":
        "Do you want to continue the previous import from where you finished or start a new import from scratch?",
    "package.import-info-modal.restart-failed-step": "Re-start failed step",
    "package.import-info-modal.rollback": "Rollback",
    "package.import-info-modal.alert-different-deployment":
        "We found a different unfinished deployment of {templateName} from {date} started by {user}.",
    "package.import-info-modal.before-continue":
        "To continue with the recently initiated deployment of {templateName} one of the following actions needs to be taken:",
    "package.import-info-modal.request-termination": "Request Termination",
    "package.import-info-modal.continue-or-rollback":
        "Do you want to continue the deployment of {templateName} or rollback it?",
    "package.import-info-modal.option.finish-previous":
        "Finish Previous Deployment - will bring you back to the deployment process of {templateName}",
    "package.import-info-modal.option.rollback":
        "Rollback - allows you to revert changes done by the unfinished deployment of {templateName}",
    "package.import-info-modal.option.termination":
        "Request Termination - terminates the deployment without rollback",
    "package.import-info-modal.option.start-new":
        "Start New Deployment - starts a new deployment process from the beginning",
    "package.import-info-modal.rollback.success":
        "The rollback request was successful. Please check the notifications or email about operation success.",
    "package.import-info-modal.termination.success":
        "The termination request was successful. Please check the notifications or email about operation success.",
    "package.message.cannot-start-import":
        "Cannot start package deployment. {message}",
    "package.message.error.cannot-obtain-info":
        "Cannot connect to the partition. Please check your credentials.",
    "package-data-upload.header": "Data Mapping",
    "package-data-upload.table-header.review.perex":
        "File {fileName} is ready to be uploaded to {partitionName} with selected options:",
    "package-data-upload.table-header.table.header":
        "Sample from your uploaded {fileName} file. Your file contains {numberOfRows} lines:",
    "package-data-upload.extension.table-header.table.header":
        "Preview data from your uploaded {fileName} file:",
    "package.optional-fields.error.max-columns":
        "The extension cannot have more than {maxColumns} columns. Please skip some of the columns.",
    "package-data-upload.extension-preview.extension-name": "Extension Name",
    "package-data-upload.extension-preview.extension-label": "Extension Label",
    "package-data-upload.pp-simple.input.label": "Price Parameter Label",
    "package-data-upload.pp-matrix.input.number-of-fields.label":
        "Number of Keys",
    "package-data-upload.pp-simple.input.placeholder": "Enter your label",
    "package-data-upload.pp-matrix.input.placeholder": "Please select",
    "package-data-upload.extension-preview.table-header.skipped": "Skipped",
    "package.pp-range.error.not-number": "Range values must be numbers.",
    "packages-type.select.placeholder": "Choose type…",
    "package.integration-filter.filter-name": "Filter",
    "package.optional-fields.error.to-many-business-keys":
        "The number of business keys must be smaller than the number of attributes",
    "package-data-upload.label.significant-fields":
        "Pricefx significant fields",
    "package-data-upload.significant-fields.table.header":
        "It is recommended to map the significant fields too, they will improve your results.",

    "package.message.default": "Processing the step",

    "package.message.setup-product-master":
        "Setting up the product master table",
    "package.message.setup-customer-master":
        "Setting up the customer master table",
    "package.message.create-datasource": "Creating a data source",
    "package.message.data-upload-ui": "Uploading client's data",
    "package.message.create-px": "Creating a product extension table",
    "package.message.create-pp": "Creating a price parameter table",
    "package.message.create-cx": "Creating a customer extension table",
    "package.message.generic-form": "Uploading form data",
    "package.message.integration-init": "Initializing an integration",
    "package.message.integration-properties":
        "Setting up integration properties",
    "package.message.integration-sftp":
        "Setting up integration SFTP properties",
    "package.message.integration-connection":
        "Setting up an integration connection",
    "package.message.competition-data":
        "Sending data into the competition data table",
    "package.message.integration-mapper": "Creating a mapper",
    "package.message.integration-csv-mapper": "Creating a CSV mapper",
    "package.message.append-to-pp":
        "Appending data into a price parameter table",
    "package.message.data-load": "Sending predefined data into Pricefx",
    "package.message.uom-datasource-load":
        "Loading predefined data into a unit of measure data source",
    "package.message.sync-product-dl":
        "Synchronizing data of the product master table with the product data source",
    "package.message.sync-customer-dl":
        "Synchronizing data of the customer master table with the customer data source",
    "package.message.sync-product-metadata":
        "Synchronizing metadata of the product master table with the product data source",
    "package.message.sync-customer-metadata":
        "Synchronizing metadata of the customer master table with the customer data source",
    "package.message.ds-string-to-dimension-change":
        "Changing strings to dimensions in a data source",
    "package.message.deploy-logic": "Deploying the predefined static logic",
    "package.message.currency-datasource-load":
        "Loading predefined data into a currency data source",
    "package.message.calendar-datasource-load":
        "Loading predefined data into a calendar data source",
    "package.message.create-data-mart": "Creating a Datamart",
    "package.message.trigger-logic": "Triggering the logic in Pricefx",
    "package.message.trigger-cfs":
        "Triggering the calculated field set in Pricefx",
    "package.message.store-state": "Storing the process state in Pricefx",

    "package.mapping-options": "Mapping options",
    "package.mapping-options.description": "Select one of mapping options:",
    "package.mapping-options.option1": "1) Continue",
    "package.mapping-options.option2":
        "2) Create new data upload with selected mapping",
    "package.mapping-options.option3": "3) Run IM SFTP template",
    "package.mapping-options.cancel": "Cancel",
    "package.mapping-options.continue": "Continue",
    "package.mapping-options.create-data-upload": "Data upload",
    "package.mapping-options.run-im-sftp-template": "Run IM SFTP template",

    "package.mapping-options.create-data-upload.title": "Data Upload",
    "package.mapping-options.create-data-upload.description":
        "Create new data upload from deployment",
    "package.mapping-options.create-data-upload.create-button": "Create",
    "package.mapping-options.create-data-upload.cancel-button": "Cancel",

    "package.mapping-options.run-im-sftp-template.select-im-instance.header":
        "Select IM instance",
    "package.mapping-options.run-im-sftp-template.select-im-instance.title":
        "Select IM instance where to deploy IM SFTP template",
    "package.mapping-options.run-im-sftp-template.select-im-instance.continue":
        "Continue",

    "upload-drag-and-drop": "Click or drag file to upload",
    "packages.select.placeholder": "Choose field…",
    "packages.skip.message":
        "This step is optional. Click {skip} if you want to move to the next one.",
    "packages.continue.button": "Continue",
    "packages.skip.button": "Skip",
    "upload-filesize-of": "of",
    "upload-filesize-progress-done": "Done",
    "upload-filesize-exceeded": "Maximum file size is {maxFileSize}.",

    "deployment.title": "Deploy",
    "deployment.message": "Integration in progress…",

    "deployment.mapper.successful": "The mapper was deployed",
    "deployment.route.successful": "The route was deployed",
    "deployment.filter.successful": "The filter was deployed",

    "profile.userPreferences.defaultRoute": "User preferences",
    "defaultRouteForm.error.missingRouteKeys":
        "Some routes are invalid (removed from PlatformManager): {missing}",
    "defaultRouteForm.defaultRoute.description":
        "You will be directed to that page after login or access without path location.",
    "defaultRouteForm.defaultRoute.label": "Default route",
    "defaultRouteForm.defaultRoute.placeholder": "Set your page preference",
    "defaultRouteForm.validation.notValidUrl": "Default route",
    "defaultRouteForm.accountId.label": "Account",
    "defaultRouteForm.accountId.placeholder": "Please select related account",
    "defaultRouteForm.notification.saved": "Your preferences were saved.",

    "package-data-process.button.finish": "Finish",
    "package-data-process.button.partition-redirect": "Go to partition",
    "package-data-process.button.integration-redirect": "Go to Integration",
    "package-data-process.title": "Import",
    "package-data-process.progress.data-source": "Importing data sources…",
    "package-data-process.progress.data-load": "Sending data to data sources…",
    "package-data-process.progress.deploy": "Deploying the package…",
    "package-data-process.error.data-source": "Unable to create data sources",
    "package-data-process.error.data-load-start": "Unable to start data loads",
    "package-data-process.error.data-progress":
        "There was an error loading data.",
    "package-data-process.error.package-deploy-start":
        "Unable to start the package deployment process.",
    "package-data-process.error.package-deploy":
        "There was an error deploying the package.",

    "customer-dashboard.button.use-marketplace": "Use Marketplace",

    "partition-password.error.missing-user-name": "Username is required",
    "partition-password.error.missing-password": "Password is required",
    "partition-password.title": "Partition Access Entitlement",
    "partition-password.message":
        "Define which user is entitled to connect to Pricefx partition [{partition}] and to perform operations there.",
    "partition-password.message2":
        "You can either select from existing user accounts which are assigned to this Account. Or, if such user account does not exist yet, you can have it created and managed by PlatformManager (so called Technical User Account).",
    "partition-password.test-result": "Test Result",
    "partition-password.test-result.not-enough-rights":
        "User does not have sufficient rights.",
    "partition-password.test-result.invalid-credentials":
        "Invalid credentials.",
    "partition-password.label.user-name": "Username",
    "partition-password.placeholder.user-name": "Enter username",
    "partition-password.label.password": "Password",
    "partition-password.placeholder.password": "Enter password",
    "partition-password.use-existing": "Add Existing User",
    "partition-password.create-pfm-technical-user":
        "Use Technical User Account",
    "partition-password.button.save": "Save",
    "partition-password.button.test-and-save": "Test and Save",
    "partition-password.button.test": "Test",
    "partition-password.button.cancel": "Cancel",
    "partition-password.button.reset-credentials": "Reset Credentials",
    "partition-password.set-credentials.successful": "Credentials were saved",
    "partition-password.set-credentials.failed":
        "Error occurred during credentials save.",
    "partition-password.reset-credentials.successful":
        "Credentials reset was successful.",
    "partition-password.reset-credentials.failed": "Credentials reset failed",
    "partition-password.test-credentials.successful":
        "Credentials test has been successful.",
    "partition-password.current-user": "Currently entitled user",
    "partition-password.current-user.not-set": "Not set",
    "partition-password.missing-rights": "Missing required rights",
    "create-account-button.sing-up": "Sign up",
    "create-account.input.label.choose-password": "Set your password",
    "create-account.input.label.username": "Username",
    "create-account.title.platform": "Platform",
    "create-account.title.create-account": "Finish setup of your account",
    "create-account.placeholder.password": "Enter your password",

    "activity-log-list.header.operation": "Operation",
    "activity-log-list.header.timestamp": "Time stamp",
    "activity-log-list.header.entityName": "Entity Name",
    "activity-log-list.header.entityType": "Entity Type",
    "activity-log-list.header.executorName": "Initiated by",
    "activity-log-list.header.operationType": "Operation Type",
    "activity-log-list.header.module": "Module",
    "activity-log-list.header.result": "Status",
    "activity-log-list.header.time": "Time",

    "placeholder.please-select": "Please select…",

    "alert-rules-list.button.new": "New Rule",

    "alert-rules-form.label.create": "New Alert Rule",
    "alert-rules-form.label.edit": "Edit Alert",
    "alert-rules-form.description":
        "Using alerts, you get notified in case of specific events in an IntegrationManager instance. The notifications are based on scanning the log file where you can watch out for specific logging levels (errors, warnings etc.). ",
    "alert-rules-form.label.name": "Rule Name",
    "alert-rules-form.placeholder.name": "Enter rule name",
    "alert-rules-form.label.enable": "Enable",
    "alert-rules-form.label.disable": "Disable",
    "alert-rules-form.label.cron": "Frequency",
    "alert-rules-form.label.definition": "Matching Rule",
    "alert-rules-form.placeholder.definition":
        "Define matching rule to invoke alert",
    "alert-rules-form.label.recipients": "Email Notification",
    "alert-rules-form.placeholder.recipients": "Enter recipients emails",
    "alert-rules-form.label.threshold": "Occurrence",
    "alert-rules-form.placeholder.threshold": "Enter treshold nr.",
    "alert-rules-form.label.severity": "Severity",
    "alert-rules-form.placeholder.severity": "Select severity",
    "alert-rules-form.label.service": "Select Service",
    "alert-rules-form.placeholder.service":
        "Select integration or partition to be monitored",

    "alert-rules-list.header.name": "Name",
    "alert-rules-list.header.cron": "Scheduled",
    "alert-rules-list.header.enabled": "Enabled",
    "alert-rules-list.header.definition": "Definition",
    "alert-rules-list.header.severity": "Severity",
    "alert-rules-list.header.threshold": "Threshold",
    "alert-rules-list.header.lastExecutionTime": "Last execution time",
    "alert-rules-list.header.rule-type": "Rule Type",
    "alert-rules-list.header.updated-at": "Updated At",
    "alert-rules-list.header.updated-by": "Updated By",
    "alert-rules-list.header.created-by": "Created By",
    "alert-rules-list.header.recipients": "Recipients",
    "alert-rules-list.header.webhook": "Webhook",
    "alert-rules-list.header.entity-type": "Entity Type",

    "alert-rules-list.filled.enabled": "Enabled",
    "alert-rules-list.filled.disabled": "Disabled",

    "alert-rules-list.entity.partition": "Partition",
    "alert-rules-list.entity.integration": "Integration",
    "alert-rules-list.entity.accelerator": "Accelerator",
    "alert-rules-list.entity.workflow": "Workflow",

    "alert-rules-form.label.webhook": "Webhook Notification",
    "alert-rules-form.placeholder.webhook": "Enter Teams or Slack webhook URL",
    "alert-rules-form.placeholder.email": "Enter email address",
    "alerts-list.header.name": "Name",
    "alerts-list.header.threshold": "Threshold",
    "alerts-list.header.value": "Value",
    "alerts-list.header.severity": "Severity",
    "alerts-list.header.fired": "Fired",
    "alerts-list.header.module": "Module",
    "alerts-list.header.partition": "Partition",
    "alerts-list.header.instance-name": "IM Instance",
    "alerts-list.header.alert-type": "Alert Type",
    "alerts-list.header.trigger-value": "Trigger Value",
    "alerts-list.header.target": "Target",
    "alerts-list.header.target-type": "Target type",

    "alerts-rules.copy": "Copy Alert Rule",
    "alerts-rules.history": "History",
    "alerts-rules.copy.success": "Alert rule copied",

    "alerts.detail.errorLoading": "There was an error loading the alert info.",
    "alerts.detail.emptyInfo": "No log information found",
    "alerts.modal-create.title": "Create Job/Task Notification",
    "alerts.modal-create.advanced-title": "Create Notification",
    "alerts.modal-create.integration-title": "Create Integration Notification",
    "alerts.modal-edit.title": "Edit Job/Task Notification",
    "alerts.modal-edit.integration-title": "Edit Integration Notification",
    "alerts.modal-edit.workflow-title": "Edit Workflow Notification",
    "alerts.modal-create.type.label": "Type",
    "alerts.modal-create.entityId.label": "Entity ID",
    "alerts.modal-create.name.label": "Name",
    "alerts.modal-create.account.label": "Account",
    "alerts.modal-create.targetType.label": "Target Type",
    "alerts.modal-create.targetName.label": "Target Name",
    "alerts.modal-create.target.label": "Target Object",
    "alerts.modal-create.triggerValue.label": "State",
    "alerts.modal-create.maxRunningTime.label": "Max Running Time",
    "alerts.modal-create.date.label": "Date",
    "alerts.modal-create.time.label": "Time",
    "alerts.modal-create.lookback.label": "Lookback Interval",
    "alerts.modal-create.repeat-interval.label": "Repeat Interval",
    "alerts.modal-create.recipients.label": "Email",
    "alerts.modal-create.showDetailMessage.label":
        "Receive email with detailed information",
    "alerts.modal-create.webhook.label": "Webhook",

    "favorites.message.added": "The {type} {name} was added to favorites.",
    "favorites.message.deleted":
        "The {type} {name} was removed from favorites.",

    "general.account-name": "Account Name",
    "general.account-owner": "Account Owner",
    "general.account-type": "Account Type",
    "general.account": "Account",
    "general.action": "Action",
    "general.active": "Active",
    "general.add-user": "Add User",
    "general.add-account": "Add Account",
    "general.add-field": "Add Field",
    "general.add": "Add",
    "general.address": "Address",
    "general.admin-licences": "Admin licenses",
    "general.apply": "Apply",
    "general.advanced-filter": "Advanced Filter",
    "general.and": "and",
    "general.back": "Back",
    "general.build-id": "Build ID",
    "general.clear": "Clear",
    "general.close": "Close",
    "general.cancel": "Cancel",
    "general.submit": "Submit",
    "general.cluster": "Instance",
    "general.collapse-all": "Collapse All",
    "general.contact-emails": "Contact emails",
    "general.finish": "Finish",
    "general.continue": "Continue",
    "general.copy": "Copy",
    "general.make-copy": "Make a copy",
    "general.copy-selected": "Copy Selected",
    "general.create": "Create",
    "general.created-at": "Created at",
    "general.created-by": "Created by",
    "general.dashboard": "Dashboard",
    "general.disabled": "Disabled",
    "general.edit": "Edit",
    "general.skip": "Skip",
    "general.edit-entity": "Edit {entity}",
    "general.add-entity": "Add {entity}",
    "general.enabled": "Enabled",
    "general.expand-all": "Expand All",
    "general.expiration-date": "Expiration Date",
    "general.confirm": "Confirm",
    "general.date": "Date",
    "general.date-of-upgrade": "Date of Upgrade",
    "general.delete": "Delete",
    "general.delete-entity": "Delete {entity}",
    "general.delete-users": "Delete users",
    "general.deploy": "Deploy",
    "general.encrypt": "Encrypt",
    "general.encrypted": "Encrypted",
    "general.not-encrypted": "Not encrypted",
    "general.encrypt.tooltip":
        "Encryption is enabled only for provisioned instances starting from version 4.6.",
    "general.discard": "Discard",
    "general.download": "Download",
    "general.detail": "Detail",
    "general.documentation": "Documentation",
    "general.documentation-lowercase": "documentation",
    "general.enter": "Enter",
    "general.error.im-configuration-not-supported":
        "Configuration is not supported for this IntegrationManager instance.",
    "general.error.im-not-configurable":
        "IntegrationManager is not configurable. You need to enable configuration on the remote instance.",
    "general.error.im-not-monitored":
        "IntegrationManager is not monitored. Enable monitoring in Settings > Monitoring.",
    "general.error.im-unsupported-version":
        "This IntegrationManager version is not supported.",
    "general.field.placeholder": "Enter {fieldName}",
    "general.git": "Git",
    "general.git-log": "Git Log",
    "general.header.actions": "Actions",
    "general.header.created-at": "Created at",
    "general.header.created-by": "Created by",
    "general.header.updated-at": "Updated at",
    "general.header.updated-by": "Updated by",
    "general.host-name": "Host Name",
    "general.inactive": "Inactive",
    "general.install-date": "Install date",
    "general.instance-overview": "Instance Overview",
    "general.integrations": "Integrations",
    "general.invalid": "Invalid",
    "general.ip": "IP",
    "general.host": "Host",
    "general.cloud-provider-account-Id": "Account (Cloud provider)",
    "general.cloud-provider-availability-zone": "Zone (Cloud provider)",
    "general.cloud-provider": "Provider",
    "general.create-request-ticket-url": "Partition request ticket URL",
    "general.move-request-ticket-url": "Move partition request ticket URL",
    "general.notes": "Notes",
    "general.delete-request-ticket-url": "Delete partition request ticket URL",
    "general.last-changed": "Last changed",
    "general.loading": "Loading",
    "general.loading.error": "There was an error loading the required data",
    "general.location": "Location",
    "general.log": "Log",
    "general.major-version": "Version",
    "general.release-name": "Release Name",
    "general.message.created": "The {type} {name} was created.",
    "general.message.deleted": "The {type} {name} was deleted.",
    "general.modal.delete.message":
        "Do you really want to delete the {name} {type}?",
    "general.modal.delete.title": "Delete {type}",
    "general.modal.request-delete.title": "Request Delete {type}",
    "general.modules": "Modules",
    "general.monitored": "Monitored",
    "general.move": "Move",
    "general.monitoring-recipients": "Instance monitoring recipients",
    "general.no": "No",
    "general.on": "On",
    "general.off": "Off",
    "general.ok": "Ok",
    "general.or": "or",
    "general.owner-contact": "Owner Contact",
    "general.permissions": "Permissions",
    "general.mass-permissions": "Mass Permissions",
    "general.mass-edit": "Mass Edit",
    "general.global-permissions": "Global Permissions",
    "general.business-roles": "Business Roles",
    "general.partition-label": "Partition Label",
    "general.partition-overview": "Partition Overview",
    "general.partition-type": "Partition Type",
    "general.partition-uploads": "Partition Uploads",
    "general.partition": "Partition",
    "general.port": "Port",
    "general.uuid": "UUID",
    "general.remote-configuration-supported": "Remote configuration supported",
    "general.remove": "Remove",
    "general.running": "Running",
    "general.save": "Save",
    "general.submit-for-approval": "Submit for Approval",
    "general.restart": "Restart",
    "general.reload": "Reload",
    "general.request": "Request",
    "general.saved": "Saved",
    "general.save-changes": "Save Changes",
    "general.send": "Send",
    "general.search": "Search",
    "general.error": "Error",
    "general.database": "Database",
    "general.parent": "Parent",
    "general.select": "Select",
    "general.server-roles": "Server Roles",
    "general.server-type": "Server Type",
    "general.show-detail": "Show Detail",
    "general.show-definition": "Show Definition",
    "general.version-history": "Version History",
    "general.show-logs": "Show Logs",
    "general.start": "Start",
    "general.stop": "Stop",
    "general.started-at": "Started at",
    "general.stopped": "Stopped",
    "general.server-log": "Server Log",
    "general.territory": "Territory",
    "general.time": "Time",
    "general.tooltip.checking-im-version":
        "Checking the IntegrationManager version",
    "general.tooltip.edit": "Edit",
    "general.tooltip.im-not-configurable":
        "This IntegrationManager instance is not configurable.",
    "general.tooltip.im-version-not-supported":
        "This IntegrationManager version is not supported.",
    "general.tooltip.moreActions": "More Actions",
    "general.tooltip.trash": "Delete",
    "general.type": "Type",
    "general.change": "Change",
    "general.update": "Update",
    "general.updated-at": "Updated at",
    "general.checkin-date": "Check-in date",
    "general.unable-to-run": "Unable to run",
    "general.order-changed": "Order changed",
    "general.order-changed.desc":
        "The 'Wait for the event from the previous step' option was automatically disabled when the order of the listeners was changed. Please check the order of the listeners carefully.",
    "general.update-in-progress": "Update in Progress",
    "general.uptime": "Uptime",
    "general.username": "Username",
    "general.validation.required": "Required",
    "general.validation.numbers-only": "Required only numbers",
    "general.validation.password.minimal-length":
        "Password must have at least {minLength} characters.",
    "general.validation.text.min-length":
        "Must be at least {minLength} characters",
    "general.validation.array.min-length":
        "Must be at least {minLength, plural, one {# item} other {# items}}",
    "general.validation.max-length": "Must be at most {maxLength} characters",
    "general.validation.max-array-length":
        "Must be at most {maxLength, plural, one {# item} other {# items}}",
    "general.validation.value-forbidden": "Value forbidden",
    "general.validation.already-present": "Already present",
    "general.validation.pattern": "Does not match pattern ",
    "general.validation.re": "Must match: {pattern}",
    "general.validation.emails-not-valid":
        "Some emails are not in valid format.",
    "general.validation.email-not-valid": "Email is not in valid format.",
    "general.validation.blank": "Cannot be blank",
    "general.validation.is-valid-url": "Please enter valid URL",
    "general.validation.duration-minimal":
        "Your duration needs to be set to {minutes} minutes at least",
    "general.validation.is-valid-json": "Value is not valid JSON",
    "general.validation.is-valid-xml": "Value is not valid XML",
    "general.validation.no-white-spaces":
        "Cannot contain white space characters. Replace with _",
    "general.validation.duplicated-value": "Duplicated value",
    "general.validation.gte": "Must be higher than or equal to {min}",
    "general.validation.lte": "Must be lower than or equal to {max}",
    "general.validation.cannot-start-with": "Cannot start with {str}",
    "general.view-in-salesfx": "View in Salesfx",
    "general.do-you-really-want-to-delete-name":
        "Do you really want to delete {name}?",
    "general.do-you-really-want-to-remove-name":
        "Do you really want to remove {name}?",
    "general.yes": "Yes",
    "general.true": "True",
    "general.false": "False",
    "general.converter-expression": "Converter expression",
    "general.approved": "Approved",
    "general.rejected": "Rejected",
    "general.approve": "Approve",
    "general.reject": "Reject",
    "general.asset-role": "Asset Group",
    "general.business-role": "Business Role",
    "general.created": "created",
    "general.fetched": "fetched",
    "general.updated": "updated",
    "general.deleted": "deleted",
    "general.add-to-group": "Add to Group",
    "general.create-request": "Create Request",
    "general.all": "All",
    "general.view-edit": "View/Edit",
    "general.view": "View",
    "general.name": "Name",
    "general.status": "Status",
    "general.permission": "Permission",
    "general.remove-user": "Remove User",
    "general.edit-users": "Edit users",
    "general.filter": "Filter",
    "general.alert.warning.title": "Warning",

    "health-state.tooltip.title": "Last check",
    "health-state.state.UP": "Responding",
    "health-state.state.DOWN": "Not Responding",
    "health-state.state.NOT_MONITORED": "Not Monitored",
    "health-state.state.UNKNOWN": "Unknown",
    "health-state.state.WARNING": "Warning",

    "documentation-link.info":
        "For more technical information reach {documentationLink}.",

    "marketplace.packages.header":
        "Accelerator packages are pre-configured solutions designed to solve specific pricing issues. With the help of Accelerator packages you can quickly set up a partition to a required state and allow for interaction with users (enter inputs, upload data).",
    "marketplace.solutions.header":
        "Accelerators are pre-configured solutions which implement frequently used functionalities, typically related to dashboards, at your Pricefx partition.",
    "location.marketplaceApp.templatesManagement.templateManagementUpload":
        "Template Upload",
    "marketplace.packages.upload": "Upload Template",
    "marketplace.packages.upload.button": "Upload Template",
    "marketplace.packages.perex":
        "Upload a JAR template to deploy it to Marketplace",

    "marketplace.error.no-options": "There is no partition for this account",

    "marketplace.packages.deploy-description":
        "Choose a partition where to deploy the Accelerator Package:",
    "marketplace.solutions.deploy-description":
        "Choose a partition for the Accelerator:",
    "marketplace.integrations.deploy-description":
        "Choose an instance where to deploy the Package:",

    "marketplace.solutions.action-description":
        "Choose which action should be performed:",

    "marketplace.footer.button.top": "Top",
    "marketplace.footer.items": "Items",
    "marketplace.item.favorites.add": "Add to Favorites",
    "marketplace.item.favorites.remove": "Remove from the Favorites",
    "marketplace.template.button.packages": "Deploy",
    "marketplace.template.button.solutions": "Deploy",
    "marketplace.template.button.integrations": "Deploy",

    "marketplace.template.button.delete.solutions": "Delete Accelerator",
    "marketplace.template.button.deploy.solutions": "Deploy Accelerator",

    "marketplace.template.detail.deploy": "Deploy",
    "marketplace.template.detail.deployment-forbidden":
        "The deployment is currently forbidden.",
    "marketplace.template.detail.tags": "Tags",
    "marketplace.template.detail.deploy.solutions": "Deploy Accelerator",
    "marketplace.template.detail.targetInstance": "Target Instance",
    "marketplace.template.detail.targetPartition": "Target Partition",
    "marketplace.template.detail.version-integrity-check":
        "Version integrity check",
    "marketplace.template.detail.version-integrity-check.button":
        "Check Version Integrity",
    "marketplace.template.detail.version-integrity-check.perex":
        "By clicking Version Integrity Check you can also check here whether the previous Accelerator/Package version at the partition was changed by anyone (e.g. the logic was modified). Then it is up to you whether to continue with the deployment and overwrite these changes or whether to cancel the deployment and verify and back up the changes first.",
    "marketplace.template.detail.versions.solutions": "Accelerator version",
    "marketplace.template.detail.deployedVersion":
        "Deployed Version on Partition: {version}",
    "marketplace.template.detail.error.forbiddenDeployment":
        "Deployment to {type} is forbidden",
    "marketplace.template.detail.error.minimalVersion":
        "This version of Accelerator needs the version {version} on the target {target}. Please upgrade or contact your administrator.",
    "marketplace.error.cantFetchDeployability":
        "Can't fetch the deployability data. {error}",

    "routes-list.header.id": "ID",
    "routes-list.header.status": "Status",
    "routes-list.header.failuresHandled": "Failed Handled",
    "routes-list.header.exchangesTotal": "Total",
    "routes-list.header.exchangesInflight": "Inflight",
    "routes-list.header.meanProcessingTime": "Mean Time",
    "routes-list.header.minProcessingTime": "Min Time",
    "routes-list.header.maxProcessingTime": "Max Time",
    "routes-list.header.totalProcessingTime": "Total Time",
    "routes-list.header.deltaProcessingTime": "Delta Time",
    "routes-list.header.uptime": "Uptime",
    "routes-list.header.path": "Path",

    "routes-list.status.draft": "Draft",
    "routes-list.status.changed": "Changed",
    "routes-list.status.deployed": "Deployed",
    "routes-list.status.undeployed": "Undeployed",
    "routes-list.status.diff": "Routes diff",
    "routes-list.status.failed": "Deployment failed",
    "routes-list.type.private.info":
        "This route is private. It has limited options, e.g. you cannot stop it or remove it.",
    "route-payload.form.success": "Payload was successfully sent.",
    "routes-list.action.send-payload": "Send Payload",
    "routes-list.action.definition": "Show Definition",
    "routes-list.action.diagram": "Show Diagram",
    "routes-list.action.documentation": "Open Documentation",
    "routes-list.action.stop": "Stop",
    "routes-list.action.start": "Start",
    "routes-list.copy.title": "Copy Route",

    "routes-list.deployment.success": "The route was deployed",
    "routes-list.definition.title": "Show route",
    "routes-list.definition.title-with-id": "Show route {routeId}",

    "route.copy.label.integration-instances": "Integration Instances",
    "route.copy.label.route-unique-name": "Route Unique Name",
    "route.copy.label.route-name": "Route Name",
    "route.copy.validation-message.name-duplication":
        "Route already exists for the selected Integration Instance",
    "route.copy.validation-message.unique-name-duplication":
        "Route already exists for the selected Integration Instance",
    "route.copy.done": "The route has been copied",
    "route.copy.modal.title": "Copy Route",
    "route.copy.modal.preview": "Copy Preview",
    "route.delete.message":
        "The route will be deleted by an asynchronous job. It can take a while. Afterwards you will need to refresh the table.",

    "route.copy.properties.original-name": "Property Original name",
    "route.copy.properties.new-name": "Property New name",
    "route.copy.properties.type": "Property Type",

    "route.copy.mappers.name": "Mapper Name",
    "route.copy.mappers.exists": "Mapper Exists",

    "route.copy.filters.name": "Filter Name",
    "route.copy.filters.exists": "Filter Exists",

    "integration-manager-instance.error.unavailable":
        "IntegrationManager is not available",
    "integration-manager-instance.error.definition":
        "The route definition cannot be loaded. Please check your IntegrationManager configuration. This functionality is available for IM versions 1.1.16 or higher.",

    "templates-management-list.header.label": "Label",
    "templates-management-list.header.status": "Status",
    "templates-management-list.header.uniqueName": "Name",
    "templates-management-list.header.published": "Published",
    "templates-management-list.header.type": "Type",

    "templates-management-versions.header.version": "Version",
    "templates-management-versions.header.status": "Status",
    "templates-management-versions.header.users": "Users",

    "templates-management-detail.header.version": "Version",
    "templates-management-detail.header.createdAt": "Release Date",
    "templates-management-detail.header.published": "Published",
    "templates-management-detail.header.validation-date": "Validation Date",
    "templates-management-detail.header.minimal-integration-version":
        "Min. Integration Version",
    "templates-management-detail.header.minimal-partition-version":
        "Min. Partition Version",
    "templates-management-detail.header.is-valid": "Valid",
    "templates-management-detail.header.deploy": "Deploy",
    "templates-management-detail.delete-confirmation.title": "Delete Template",
    "templates-management-detail.delete-confirmation.message":
        "Do you want to delete the template version {version}? This action cannot be undone.",

    "templates-management.error.unavailable": "Template is not available",
    "templates-management.error.partitions-unavailable":
        "Partitions list is not available",
    "templates-management.error.instances-unavailable":
        "Instances list is not available",
    "templates-management.error.versionsUnavailable":
        "Template versions unavailable",
    "templates-management.error.publishing":
        "Template publishing cannot be changed",
    "templates-management.error.delete": "Template version cannot be deleted",

    "app-state.new-app-version.notification.title":
        "New version of PlatformManager is ready",
    "app-state.new-app-version.notification.message":
        "Click this notification to refresh this page. Any unsaved changes will be lost.",

    "log.current-log": "Current log",
    "log.history-log": "Log History",

    "package.header.processing": "Importing…",
    "package.header.starting-process": "Starting Package",
    "package.message.starting-process": "Package deployment process started",

    "location.marketplaceApp.templatesManagement.deployedTemplates":
        "Deployed Templates",
    "deployed-templates-list.header.job-id": "Job ID",
    "deployed-templates-list.header.label": "Label",
    "deployed-templates-list.header.template-name": "Name",
    "deployed-templates-list.header.template-version": "Version",
    "deployed-templates-list.header.partition-name": "Partition Name",
    "deployed-templates-list.header.account-name": "Account Name",
    "deployed-templates-list.header.instance-name": "Instance Name",
    "deployed-templates-list.header.user-name": "Started by",
    "deployed-templates-list.header.date": "Deployed at",
    "deployed-templates-list.header.actions": "Actions",
    "deployed-templates-list.download-state": "Download Deployment State",
    "deployed-templates-list.download-state.error":
        "Unable to download the deployment state",

    "location.marketplaceApp.templatesManagement.failedDeployments":
        "Failed Deployments",
    "failed-deployments-list.header.job-id": "Job ID",
    "failed-deployments-list.header.label": "Label",
    "failed-deployments-list.header.template-name": "Name",
    "failed-depoloyments-list.header.template-version": "Template version",
    "failed-deployments-list.header.started-by": "Started by",
    "failed-deployments-list.header.failed-at": "Failed at",
    "failed-deployments-list.header.messages": "Messages",
    "failed-deployments-list.action-menu.view-error-logs": "View Error Logs",

    "failed-deployments-errorlog-modal.title": "Error Logs",
    "failed-deployments-errorlog-modal.partition-log": "Partition Error Log",
    "failed-deployments-errorlog-modal.accelerator-log":
        "Accelerator Error Log",
    "failed-deployments-errorlog-modal.partition-log-link": "Partition Log",
    "failed-deployments-errorlog-modal.accelerator-log-link":
        "Accelerator Error Log",

    "solutions.title.deploy": "Deploy Accelerator to Partition",
    "solutions.button.next": "Next",
    "solutions.button.back": "Back",
    "solution.validation.required": "Required",

    "integration-templates.init.template-already-installed":
        "This template is already used in the selected IntegrationManager.",
    "integration-templates.init.im-not-available":
        "IntegrationManager is not available.",
    "integration-templates.init.unable-fetch-connections":
        "Unable to fetch connections",
    "integration-templates.init.available-connections":
        "There are no available connections.",
    "integration-templates.init.no-connections":
        "Connection is not available. Please define {newConnection}.",
    "integration-templates.init.no-connections.new-connection":
        "New connection",
    "integration-templates.init.available-connections-define":
        "Please define one {here}",
    "integration-templates.init.form.connection": "Connection",
    "integration-templates.init.form.sftp-connection": "SFTP connection",
    "integration-templates.init.form.name": "Name",
    "integration-templates.init.available-connections-here": "here",
    "integration-templates.init.connection-check-failed":
        "Connection check failed",
    "integration-templates.init.connection-check-settings":
        "Please check Connection Settings ",

    "integration-templates.init.tip":
        "Checking IntegrationManager configuration",
    "integration-templates.init.available":
        "IntegrationManager is up and running.",
    "integration-templates.init.connection-available":
        "The connection to the Pricefx partition is defined.",
    "integration-templates.init.template-not-available":
        "The selected template is not available",
    "integration-templates.init.integration-unique-name":
        "A template with the same label already exists. Please change the label.",
    "integration-templates.init.template-occurrence":
        "This template can be used only once at an instance and it is already deployed.",
    "integration-templates.init.template-empty":
        "Template label must not be empty",

    "loggers-management-list.header.component": "Component",
    "loggers-management-list.header.effective-level": "Effective level",

    "release-version": "release version",
    "release-version.releaseDate": "Release date",
    "release-version.releaseNumber": "Release number",
    "release-version.serverBuildNumber": "Server build number",
    "release-version.webBuildNumber": "Web build number",
    "release-version.notes": "Notes",
    "release-version.releaseDate.label": "Release Date",
    "release-version.releaseNumber.label": "Release Number",
    "release-version.serverBuildNumber.label": "Server Build Number",
    "release-version.webBuildNumber.label": "Web Build Number",
    "release-version.notes.label": "Notes",
    "release-version.delete": "Delete release version",
    "release-version.delete.title": "Delete Release Version",
    "release-version.delete.message":
        "Do you really want to delete the release version {objectName}?",
    "release-version.delete.failed": "Cannot delete release version",
    "release-version.create.modal.button": "Create Release Version",
    "release-version.create.modal.title": "Create Release Version",
    "release-version.edit.modal.title": "Edit Release Version",
    "release-version.saved": "Release version saved",
    "release-version.save-failed": "Release version save failed",

    "partition-upgrade": "partition upgrade",
    "partition-upgrade.upgradeType": "Upgrade type",
    "partition-upgrade.duration": "Duration",
    "partition-upgrade.upgradeType.label": "Upgrade Type",
    "partition-upgrade.duration.label": "Duration",
    "partition-upgrade.delete": "Delete partition upgrade",
    "partition-upgrade.delete.title": "Delete Partition Upgrade",
    "partition-upgrade.delete.message":
        "Do you really want to delete the partition upgrade {objectName}?",
    "partition-upgrade.delete.failed": "Cannot delete partition upgrade",
    "partition-upgrade.create.modal.button": "Create Partition Upgrade",
    "partition-upgrade.create.modal.title": "Create Partition Upgrade",
    "partition-upgrade.edit.modal.title": "Edit Partition Upgrade",
    "partition-upgrade.saved": "Partition upgrade saved",
    "partition-upgrade.save-failed": "Partition upgrade save failed",
    "partition-upgrade.create-modal.disabled.tooltip":
        "Only one record per upgrade type can be created",

    "integration-properties.header.name": "Name",
    "integration-properties.header.value": "Value",

    "mapper.validation.missing-sku": "Output SKU must be defined",
    "mapper.validation.missing-key2": "Output key2 must be defined",
    "mapper.validation.missing-customer-id":
        "Output customerId must be defined",
    "mapper.validation.missing-seller-id": "Output sellerId must be defined",
    "mapper.validation.must-have-output-outside-of-business-key":
        "At least one output which is not part of a business key must be used",

    "route.saved": "Route was saved",
    "route.saved-and-deployed": "Route was saved and deployed",
    "route.invalid": "Route definition is not valid",

    "generic-form.title": "Settings",

    "properties.add-property": "Add Property",
    "properties.deploy-property": "Deploy Property",
    "properties.save-and-deploy": "Save and Deploy Property",

    "properties.column.name": "Name",
    "properties.column.type": "Type",
    "properties.column.value": "Value",
    "properties.deploy.success": "Property has been deployed.",

    "edit-property.modal.title.add-property": "Add Property",
    "edit-property.modal.title.edit-property": "Edit Property",
    "edit-property.modal.form.name.label": "Name",
    "edit-property.modal.form.name.placeholder": "Enter name",
    "edit-property.modal.form.type.label": "Type",
    "edit-property.modal.form.type.placeholder": "Enter type",
    "edit-property.modal.form.value.label": "Value",
    "edit-property.modal.form.value.placeholder": "Enter value",
    "edit-property.modal.success": "Property has been saved.",

    "instance-password.modal.title": "Change Password",

    "route-definition-diff.platform.definition.error":
        "Cannot load the definition from PlatformManager",
    "route-definition-diff.im.definition.error":
        "Cannot load the definition from IntegrationManager",
    "composite-select.switch.tooltip": "Enable to choose multiple fields",
    "package-confirmation.title": "Congratulations!",
    "package-confirmation.description":
        "Your deployment finished successfully. Continue to see the result.",
    "data-upload.step-name.entity-type": "Entity Type",
    "data-upload.step-name.upload-file": "Upload File",
    "data-upload.step-name.options": "Options",
    "data-upload.step-name.data-mapping": "Data Mapping",
    "data-upload.step-name.upload-review": "Upload Review",
    "data-upload.review.starts-at": "Upload starts at: {date}",
    "data-upload.review.email":
        "Information about the finished upload will be sent by email",
    "data-upload.review.rows-note":
        "In the review table below you can see the first 3 rows of the resulting data that will be sent (mapped columns only, including conversions)",
    "data-upload.review.error.postscript": "Fix the data in previous steps.",
    "data-upload.review.convert-empty-null": "Send empty value as NULL",
    "data-upload.review.convert-empty-string":
        "Send empty value as empty string (““)",
    "data-upload.finish.pending.heading": "File is proceeding...",
    "data-upload.finish.pending.text":
        "You can leave and continue your work. We will inform you in notification when the proceeding of the uploaded file is ready.",
    "data-upload.finish.finished.heading": "Upload Finished",
    "data-upload.finish.success.text":
        "Your Data Upload finished successfully, {recordsCount} records were uploaded.",
    "data-upload.finish.error.text":
        "Your Data Upload finished, there were {recordsCount} failed records.",
    "data-upload.finish.button.back": "Back to Data Uploads",
    "data-upload.finish.button.detail": "View detail",
    "stepper.button.back": "Back",
    "package-failed-option.header.messages": "Error messages",

    "templates-management-list.footer.git-commit": "Git Commit: {commit}",

    "entity.action.link": "Link",
    "deploy-accelerator.button.continue": "Continue",
    "accelerator-card.select-deploy-location":
        "Select {destination} where to deploy the Package:",
    "search.placeholder": "Search…",

    "route-form.error.name.required":
        "Route name is required. Please use at least 5 characters.",
    "delete-accelerator-action.error":
        "Error occurred during the Accelerator deletion start",
    "delete-accelerator-action.success":
        "The Accelerator deletion has started successfully",
    "delete-accelerator-action.confirm.title": "Delete Accelerator",
    "delete-accelerator-action.confirm.message":
        "Do you want to start the deletion of {name} Accelerator? The operation may take several minutes. You will be informed about the result via notifications.",
    "route-form.error.unique-name.required": "Route unique name is required",

    "alert-rule-form.button.partitions": "Partitions",
    "alert-rule-form.button.integrations": "Integrations",
    "alert-rule-form.button.accelerators": "Accelerators",
    "validation.alert-rule.exists": "Alert Rule {name} already exists.",
    "validation.alert-rule.valid-url": "Please use a valid HTTPS URL",
    "validation.alert-rule.valid-email-address":
        "Please use a valid email address",
    "validation.alert-rule.recipients-too-long":
        "Recipients list exceeds maximum length of {max} characters.",

    "unauthorized-header": "You don’t have a permission to access this page.",
    "unauthorized-message":
        "You may not have sufficient rights and permissions to open this page. If you believe that you should be able to access this page, please contact your support.",

    "account.sidebar.selected-account": "Selected account",

    "account-admin-users-list.button.invite-user": "Invite User",
    "account-admin-asset-role-list.button.create-asset-role":
        "Create Asset Group",

    "user-admin-settings.query.users.running": "Loading users",
    "user-admin-settings.query.groups.running": "Loading groups",
    "user-admin-settings.command.deleteUser.running": "Deleting the user",
    "user-admin-settings.command.deleteUser.success":
        "User deleted successfully",
    "user-admin-settings.command.deleteUser.error":
        "The user deletion failed: {message}",
    "user-admin-settings.command.removeUsersFromGroup.running":
        "Deleting users",
    "user-admin-settings.command.removeUsersFromGroup.success":
        "Users deleted successfully",
    "user-admin-settings.command.removeUsersFromGroup.error":
        "Users deletion failed: {message}",
    "user-admin-settings.command.inviteUser.running": "Inviting the user",
    "user-admin-settings.command.inviteUser.success":
        "The user invited successfully",
    "user-admin-settings.command.inviteUser.error": "Failed to invite the user",

    "user-admin-settings.command.deleteGroup.running": "Deleting the group",
    "user-admin-settings.command.deleteGroup.success": "The group was deleted",
    "user-admin-settings.command.deleteGroup.error":
        "The group deletion failed",
    "user-admin-settings.command.addGroup.running": "Adding the group",
    "user-admin-settings.command.addGroup.success": "The group was added",
    "user-admin-settings.command.addGroup.error": "The group creation failed",
    "user-admin-settings.command.editGroup.running": "Updating the group",
    "user-admin-settings.command.editGroup.success": "The group was updated",
    "user-admin-settings.command.editGroup.error": "The group update failed ",
    "user-admin-settings.command.removeUserFromGroup.running":
        "Removing the user from the group",
    "user-admin-settings.command.removeUserFromGroup.success":
        "The user removal was successful",
    "user-admin-settings.command.removeUserFromGroup.error":
        "The user removal failed",
    "user-admin-settings.command.inviteUsers.running": "Inviting the users",
    "user-admin-settings.command.inviteUsers.success": "The users were invited",
    "user-admin-settings.command.inviteUsers.error":
        "The users invitation failed ",
    "user-admin-settings.query.multipleQueries.running": "Loading",

    "partitionSelectForm.partition.placeholder": "Select target partition",
    "instanceSelectForm.instance.label": "Instance",
    "instanceSelectForm.instance.placeholder": "Select target instance",

    "assets-permissions-panel.command.save.running": "Saving the permissions",
    "assets-permissions-panel.command.save.error":
        "The permissions update failed",
    "assets-permissions-panel.command.save.success":
        "The permissions were updated successfully",
    "assets-permissions-panel.command.batchSave.running":
        "Saving the permissions",
    "assets-permissions-panel.command.batchSave.error":
        "The permissions updated failed",
    "assets-permissions-panel.command.batchSave.success":
        "The permissions were updated successfully",
    "assets-permissions-panel.query.multipleQueries.running": "Loading",
    "assets-table.query.users.running": "Loading assets",

    "account-admin-users-list.header.username": "Username",
    "account-admin-users-list.header.full-name": "Full Name",
    "account-admin-users-list.header.email": "Email",
    "account-admin-users-list.header.job-title": "Title",
    "account-admin-users-list.header.created-by": "Created by",
    "account-admin-users-list.header.created-at": "Created at",
    "account-admin-users-list.header.last-login": "Last Login",
    "account-admin-users-list.header.status": "Status",
    "account-admin-users-list.tab.users": "Users",
    "account-admin-users-list.tab.groups": "User Groups",
    "account-admin-users-list.table-context-menu.set-permissions":
        "Set Permissions",
    "account-admin-users-list.table-context-menu.batch-add-permissions":
        "Batch add permissions",

    "account-admin-users-list.modal.title": "Invite New User",
    "account-admin-users-list.modal.invite-user": "Invite User",
    "account-admin-users-list.modal.invite-user-desc":
        "Invite a new user via email. The user will receive an invitation link.",
    "account-admin-users-list.modal.username-label": "Email",
    "account-admin-users-list.modal.validation.message":
        "Must be a valid email",

    "account-admin-users-list.remove-user.title": "Remove User From Project",
    "account-admin-users-list.remove-user.message":
        "Do you really want to remove the user {username}?",

    "global.assets": "Assets",
    "global.super-assets": "Global Asset Class",
    "global.asset-groups": "Asset Groups",
    "account-admin-group-list.header.name": "Name",
    "account-admin-group-list.header.number-of-users": "Number of Users",
    "account-admin-group-list.header.description": "Description",
    "account-admin-group-list.header.created-at": "Created at",

    "account-admin-group-detail.header.user-name": "Username",
    "account-admin-group-detail.header.email": "Email",

    "account-admin-group-detail.header.title": "Remove User",
    "account-admin-group-detail.header.message":
        "Do you really want to remove the user {username}?",

    "account-admin-group-list.button.create-group": "New Group",

    "account-admin-groups-list.modal.title": "Create New Group",
    "account-admin-groups-list.modal.title.edit": "Edit Group",
    "account-admin-groups-list.drawer.title": "Group detail",
    "account-admin-groups-list.modal.group-name": "Group Name",
    "account-admin-groups-list.modal.group-description": "Group Description",
    "account-admin-groups-list.remove-group.title": "Delete Group",
    "account-admin-groups-list.remove-group.message":
        "Do you really want to delete the group {name}?",
    "account-admin-group-add-user.modal.title": "Add User to {groupName}",
    "account-admin-group-add-user.modal.message":
        "Select user(s) you would like to add to the group or send an invitation in case they are not in the list",
    "account-admin-group-add-user.modal.invite-message":
        "Invite new user via username",

    "account-admin-assets-role-list.header.name": "Name",
    "account-admin-assets-role-list.header.description": "Description",
    "account-admin-assets-role-list.header.account-name": "Account name",
    "account-admin-asset-role-list.modal.label-name": "Name",
    "account-admin-asset-role-list.modal.label-description": "Description",
    "account-admin-asset-role-list.modal.title": "Create Asset Group",
    "account-admin-asset-role-list.modal.title-edit": "Edit Asset Group",
    "account-admin-asset-role-list.asset-roles": "Asset Groups",
    "account-admin-asset-role.asset-list.header": "Assets",
    "account-admin-asset-role-list-delete-action.title": "Delete Asset Group",
    "account-admin-asset-role-list-delete-action.message":
        "Do you really want to delete the asset group {name}?",

    "account-admin-assets-list.header.name": "Account Name",
    "account-admin-assets-list.header.type": "Type",
    "account-admin-partition-list.header.serial-number": "Partition Name",
    "account-admin-partition-list.header.label": "Label",
    "account-admin-partition-list.header.cluster": "Instance",
    "account-admin-instance-list.header.name": "Integration name",
    "account-admin-instance-list.header.server": "Server",
    "account-admin-instance-list.header.cluster": "Instance",
    "account-admin-asset-role-list-delete.message":
        "Asset group deleted successfully",

    "account-admin-business-role-list.business-roles": "Business Roles",
    "account-admin-business-role-list.modal.title": "Create Business Role",
    "account-admin-business-role-list.modal.title-edit": "Edit Business Role",
    "account-admin-business-role-list.modal.label-name": "Name",
    "account-admin-business-role-list.header.description": "Description",
    "account-admin-business-roles-list.button.create-business-role":
        "Create Business Role",
    "account-admin-business-role-list-delete-action.title":
        "Delete Business Role",
    "account-admin-business-role-list-delete-action.message":
        "Do you really want to delete the business role {name}?",
    "account-admin-business-role-list-delete.message":
        "Business role deleted successfully",

    "reports-list.header.name": "Name",
    "reports-list.header.enabled": "Enabled",
    "reports-list.header.fired": "Fired",
    "reports-list.header.type": "Type",
    "reports-list.header.frequency": "Frequency",
    "reports-list.header.status": "Status",
    "reports-list.header.next-execution-time": "Scheduled for",

    "reports-list.filled.enabled": "Enabled",
    "reports-list.filled.disabled": "Disabled",

    "reports-list.action.execute": "Execute",
    "reports-list.action.execute.successful": "Report was executed",
    "reports-list.action.execute.failed": "Report execution failed",

    "reports-form.button.new": "Schedule Report",

    "reports-form.label.create": "Schedule Report",
    "reports-form.label.edit": "Edit Schedule Report",

    "reports-form.label.name": "Name",
    "reports-form.label.enabled": "Enabled",
    "reports-form.label.scheduled": "Scheduled at",
    "reports-form.label.recipients": "Recipients",
    "reports-form.label.service": "Service",
    "reports-form.label.for": "For",
    "reports-form.label.report-type": "Report type",

    "reports-form.validation.recipients.valid":
        "Please use a valid email address",

    "partition.connection.unavailable": "Partition is not available",
    "partition.connection.unavailable.description":
        "{message} Check credentials {clickHereLink}.",
    "partition.connection.unavailable.link": "here",
    "assets-table.project.name": "Account Name",
    "assets-table.project.createdBy": "Owner",
    "assets-table.project.type": "Type",
    "assets-table.project.territory": "Territory",
    "assets-table.partition.name": "Partition Name",
    "assets-table.partition.serialNumber": "Serial Number",
    "assets-table.partition.clusterName": "Instance",
    "assets-table.integration.instanceName": "Integration Name",
    "assets-table.integration.server": "Server",
    "assets-table.integration.type": "Type",
    "asset-roles-table.name": "Asset Group Name",
    "asset-roles-table.description": "Description",
    "asset-roles-table.message.warn.no-asset-in-asset-role":
        "Asset Group {roleName} was not assigned any asset. Go to User Rights -> Assets to assign one.",
    "permissions-table.account": "Account Permissions",
    "permissions-table.partition": "Partition Permissions",
    "permissions-table.integration": "Integration Permissions",
    "permissions-table.package": "Package Permissions",
    "permissions-table.asset-role": "Asset Group Permissions",

    "data-upload.mapper-table.validation.entity-name.empty":
        "Entity name must not be empty",
    "data-upload.mapper-table.validation.mandatory-fields":
        "Missing output mandatory fields",
    "data-upload.mapper-table.validation.mapper.empty":
        "Mapper must have at least one item",
    "data-upload.mapper-table.validation-undefined-input":
        "Input must be defined",
    "data-upload.mapper-table.validation-undefined-output":
        "Output must be defined",
    "data-upload.mapper-table.validation-not-found-in-options":
        "Not found in options",
    "data-upload.mapper-table.validation-not-unique-output":
        "Output name must be unique",
    "data-upload.mapper-table.validation-undefined-input-type":
        "InputType must be defined",

    "general.loading-data": "Loading data…",
    "general.initial-load-failed":
        "Initial data load failed. Please reload this page.",

    "global-permissions-table.query.permissions.running":
        "Loading global permissions",
    "global-permissions-panel.query.userPermissions.running":
        "Loading user global permissions",
    "global-permissions-panel.command.savePermissions.running":
        "Saving global permissions",
    "global-permissions-panel.command.savePermissions.success":
        "Global Permissions saved successfully",
    "global-permissions-panel.command.savePermissions.error":
        "Saving global permissions failed",
    "group-permissions-panel.command.onSave.running":
        "Saving group permissions",
    "group-permissions-panel.command.onSave.success":
        "Group Permissions saved successfully",
    "group-permissions-panel.command.onSave.error":
        "Group permissions saving error",
    "group-permissions-panel.query.multipleQueries.running": "Loading",
    "user-permissions-panel.command.onSave.running": "Saving user permissions",
    "user-permissions-panel.command.onSave.success":
        "User permissions saved successfully",
    "user-permissions-panel.command.onSave.error":
        "User permissions saving error",
    "user-permissions-panel.query.multipleQueries.running": "Loading",
    "global-permissions-panel.query.multipleQueries.running": "Loading",
    "admin.users.table-context-menu.global-permissions": "Global Permissions",
    "validation.data-upload.exists": "Data Upload {name} already exists.",

    "admin-assets-permissions-panel.command.save.running":
        "Saving the permissions",
    "admin-assets-permissions-panel.command.save.error":
        "The permissions updated failed",
    "admin-assets-permissions-panel.command.batchSave.success":
        "The permissions were updated successfully",
    "admin-assets-permissions-panel.query.usersRights.running": "Loading",
    "admin-assets-permissions-panel.query.multipleQueries.running": "Saving",

    "marketplace.template-visibility.visibility.label": "Template visible for",
    "marketplace.template-visibility.visibility.public": "Public",
    "marketplace.template-visibility.visibility.protected": "Protected",
    "marketplace.template-visibility.public-permissions":
        "Template {name} is visible for everyone",
    "marketplace.template-visibility.tab.users": "Users",
    "marketplace.template-visibility.tab.accounts": "Accounts",
    "marketplace.template-visibility.users-table.username": "Username",
    "marketplace.template-visibility.users-table.email": "Email",
    "marketplace.template-visibility.users-table.option.deploy": "Deploy",
    "marketplace.template-visibility.users-table.option.view": "View",
    "marketplace.template-visibility.users-table.job-title": "Job title",
    "marketplace.template-visibility.pick-users-modal.title": "Add users",
    "marketplace.template-visibility.accounts-table.account": "Account",
    "marketplace.template-visibility.accounts-table.owner": "Owner",
    "marketplace.template-visibility.accounts-table.type": "Type",
    "marketplace.template-visibility.pick-accounts-modal.title":
        "Add Account(s)",
    "task-table.headers.type": "Type",
    "task-table.headers.status": "Status",
    "task-table.headers.took": "Took",
    "task-table.headers.start": "Started at",
    "task-table.headers.end": "Ended at",
    "task-table.headers.initiated-by": "Initiated by",
    "template-visibility-drawer.command.setTemplateUsers.running": "Saving",
    "template-visibility-drawer.command.setTemplateUsers.success": "Saved",
    "template-visibility-drawer.command.setTemplateUsers.error": "Error",
    "template-visibility-drawer.command.updateTemplateVisibility.running":
        "Saving",
    "template-visibility-drawer.command.updateTemplateVisibility.success":
        "Saved",
    "template-visibility-drawer.command.updateTemplateVisibility.error":
        "Error",
    "template-visibility-drawer.command.updateTemplate.running": "Saving",
    "template-visibility-drawer.command.updateTemplate.success": "Saved",
    "template-visibility-drawer.command.updateTemplate.error": "Error",
    "template-visibility-drawer.query.templateUsers.running": "Loading",
    "template-visibility-drawer.query.projectUsers.running": "Loading",
    "template-visibility-drawer.query.multipleQueries.running": "Loading",
    "im-instance.status": "Integration Status",
    "im-instance.stop.error": "Unable to stop IM instance.",
    "im-instance.start.error": "Unable to start IM instance.",
    "im-instance.restart.error": "Unable to restart IM instance.",
    "im-instance.version.update": "Integration Version Update",
    "im-instance.message.upgrade-failed": "Update failed.",
    "im-instance.message.upgrade-successfully-finished":
        "Update was successfully finished",
    "im-instance.tooltip.versions-to-update":
        "Versions that can be updated to.",
    "im-instance.tooltip.start-stop.only-provisioned":
        "Start/Stop is available only for provisioned instances.",

    "im-instance.warning.NO_ROUTES": "There are no routes in this instance.",
    "im-instance.warning.UNABLE_TO_FETCH_ROUTES":
        "Unable to fetch route definitions.",
    "im-instance.warning.LOGFILE_EMPTY": "Log file is empty.",
    "im-instance.warning.UNABLE_TO_GET_LOGFILE": "Unable to get the log file.",
    "im-instance.warning.ELK_ERROR_RESPONSE":
        "ELK service returned error status.",
    "im-instance.warning.ELK_NO_ENTRIES": "There are no entries in ELK.",
    "im-instance.warning.ELK_COMMUNICATION_ERROR": "Unable to read from ELK.",
    "im-instance.warning.UNABLE_TO_CREATE_IM_CLIENT":
        "Unable to create IM client.",
    "im-instance.warning.UNKNOWN_WARNING": "Unknown warning code.",

    "related-contacts.header.name": "Name",
    "related-contacts.header.title": "Title",
    "related-contacts.header.phone": "Contact Phone",
    "related-contacts.header.email": "Contact Mail",
    "account.dashboard.activity-log": "Activity Log",
    "related-contacts.drop-down.text": "Contacts",
    "related-contacts.header.visible": "Visible",
    "related-contacts.switch.visible": "Visible",
    "related-contacts.switch.hidden": "Hidden",
    "related-contacts.switch.failed": "Cannot set contact",
    "contacts-management.description":
        "Here you can set whether the contact persons are shown on the Account Dashboard or not.",

    "packages.waterfall.header":
        "Choose your Datamart source and configure waterfall",
    "packages.waterfall.select.source": "Source",
    "packages.waterfall.select.placeholder": "Select Datamart",
    "packages.waterfall.table.header.label": "Label",
    "packages.waterfall.table.header.sum": "Sum",
    "packages.waterfall.table.header.percent-base": "Percent Base",
    "packages.waterfall.table.header.reverse": "Reverse",
    "packages.waterfall.table.header.disabled": "Disabled",
    "packages.waterfall.table.button.add-row": "Add Row",
    "packages.waterfall.table.button.add-row.tooltip": "Select Datamart",
    "packages.waterfall.table.sub-level.tooltip-select-datamart-field":
        "Select Datamart field first",
    "warning-step.title.accelerator": "Accelerator Deploy",
    "warning-step.title.template": "Template Deploy",
    "warning-step.message.instance":
        "You initiated deployment of the template ({templateName}) to IntegrationManager instance ({instanceName}).",
    "warning-step.message.no-instance":
        "You initiated deployment of the template ({templateName}).",
    "warning-step.message.instance-warn":
        "By deploying again all previously uploaded data will be erased and will no longer be accessible.",
    "warning-step.message.partition":
        "You initiated deployment of the Accelerator or Accelerator package ({templateName}) to partition ({serialNumber}).",
    "warning-step.message.no-partition":
        "You initiated deployment of the Accelerator or Accelerator package ({templateName}).",
    "warning-step.message.note":
        "This action has potential influence on your setup and can lead to damaging your configuration. By continuing, you acknowledge that you are aware of the consequences of your action and agree that a change made by the template is requested by you.",
    "deployment.start.failed": "Starting of deployment failed",
    "warning-step.message.importantPart":
        "If you break your configuration, you acknowledge that the cost of repair may be fully charged to you by Pricefx.",
    "packages.waterfall.sub-level.select.source": "Source (Optional)",
    "packages.waterfall.validation.error.isFirstRowWithSource":
        "The first row needs source selection.",
    "packages.waterfall.validation.error.isExistingMaxOnePercentBase":
        "There should be just one row with percent base selected.",
    "packages.waterfall.validation.error.isDefinedFieldWithSourceAndLabel":
        "There is no field with source and label. You need at least one field.",
    "packages.waterfall.validation.error.isFieldWithSumFirst":
        "The first row cannot be the sum field.",
    "packages.waterfall.validation.error.isDefinedFieldWithSumAndLabel":
        "There is no field with sum and label. You need to define at least one sum field.",
    "packages.waterfall.validation.error.isDefinedLastFieldAsSum":
        "To see the result, put the Sum field at the end.",
    "packages.waterfall.validation.error.isDefinedFieldsWithLabels":
        "Please fill all field labels",
    "packages.waterfall.validation.error.fieldNeedsSource":
        "Please select source in fields without sublevel.",
    "packages.simulation.select.sources": "Sources",
    "packages.simulation.table.title.group-by": "Group By",
    "packages.simulation.table.title.measures": "Measures",
    "packages.simulation.table.title.filters": "Filters",
    "packages.simulation.table.header.source-field": "Source Field",
    "packages.simulation.table.header.operator": "Operator",

    "packages.simulation.table.header.filter-value": "Value",
    "packages.simulation.table.header.label": "Label",
    "packages.simulation.table.header.expected-field": "Expected Source Field",
    "packages.simulation.button.tooltip.add-field": "Add field",
    "packages.simulation.button.message.please-select":
        "Please select Group By source field first.",

    "data-upload.copy.label.partitions": "Partitions",
    "data-upload.copy.label.name": "Data Upload Name",
    "data-upload.copy.name-duplication":
        "Data Upload already exists for partitions: {duplicates}",
    "data-upload.copy.modal.title": "Copy Data Upload",
    "capacity.nav-item.capacity": "Availability",
    "capacity-configuration.add-capacity": "Add availability",
    "capacity-configuration.timezone.label": "Timezone",
    "capacity-configuration.header": "Availability Configuration",
    "capacity-configuration.perex":
        "Set your availability for instance upgrades.",
    "capacity-configuration.saved": "Your configuration was saved",
    "capacity-configuration.validation.division":
        "Your time needs to be rounded to 15 minutes",
    "capacity-configuration.validation.invalidRange":
        "Your time interval is not valid.",
    "capacity-entry.time-from.label": "Time from",
    "capacity-entry.time-from.placeholder": "Time from",
    "capacity-entry.time-to.label": "Time to",
    "capacity-entry.time-to.placeholder": "Time to",
    "capacity-entry.date.label": "Date",
    "capacity-entry.date.placeholder": "Date",
    "capacity-entry.repeat.label": "Repeat every week",
    "data-upload.mapper.add.field": "Add Field",
    "data-upload.mapper.import.label": "Import File Columns",
    "data-upload.mapper.conversion.label": "Conversion",
    "data-upload.mapper.export.label": "Pricefx Columns",
    "data-upload.mapper.vendor.label": "{vendor} Field",
    "data-upload.mapper.convert.label": "Convert",
    "data-upload.mapper.converted.label": "Converted",
    "data-upload.mapper.converted.tooltip": "Automatic convert applied",
    "data-upload.mapper.detail-view.label": "Detail view",

    "filter-form.text": "Select options you would like to use:",
    "filter-form.select-all": "Select all",
    "filter-form.deselect-all": "Deselect all",

    "module-multiple-select.text": "Select modules you would like to deploy:",
    "module-multiple-select.button.next": "Next",

    "integration.template.integration-sftp.check.tip":
        "Checking SFTP connection",
    "integration.template.integration-sftp.check.button":
        "Test SFTP connection",
    "integration.template.integration-sftp.success": "SFTP connection is valid",
    "preference.new.saved": "New preference was saved",
    "preference.changes.saved": "Preference changes were saved",
    "preference.deleted": "Preference was deleted",
    "formula-editor.tab.field.input": "Input",
    "formula-editor.tab.converter": "Converter",
    "formula-editor.tab.converter.field-type": "Field type",
    "formula-editor.tab.expert-options": "Expert options",
    "formula-editor.tab.expert-options.field-type": "Type",
    "formula-editor.tab.expert-options.expression": "Expression",
    "formula-editor.tab.formula": "Formula",
    "formula-editor.tab.groovy": "Groovy",
    "formula-editor.title": "Formula fields",
    "formula-editor.description":
        "The following fields are available for use in this Formula",

    "formula-editor.table.header.key": "Key",
    "formula-editor.table.header.source-field": "Source Field",
    "formula-editor.table.header.example": "Field Example (1st row)",
    "formula-editor.label.formula": "Formula",

    "groovy-editor.title.groovy-code": "Groovy Code",
    "groovy-editor.placeholder.enter-groovy": "Enter Your groovy code",

    "formula-editor.tab.multiple-field": "Multiple field",

    "select-with-add-item.add": "Add item",
    "parsing-warning.is-type-in-correct-format":
        "Are you sure the {type} is in a correct format?",
    "parsing-warning.type-parsing": "{type} Parsing",
    "parsing-warning.set-decimal-separator":
        "If not, consider changing the decimal separator.",
    "parsing-warning.set-date-format":
        "If not, consider changing the date format.",
    "parsing-warning.set-date-time-format":
        "If not, consider changing the date-time format.",

    "integration-manager-versions.label.name": "Name",
    "integration-manager-versions.label.bound-infra-version-gcp":
        "GCP Bound Infra Version",
    "integration-manager-versions.label.bound-infra-version-aws":
        "AWS Bound Infra Version",
    "integration-manager-versions.label.allowed-upgrade-aws":
        "Allowed Upgrade AWS",
    "integration-manager-versions.label.allowed-new": "Allowed New",
    "integration-manager-versions.label.create-in-kubernetes":
        "Create In Kubernetes",
    "integration-manager-versions.label.is-transition": "Is Transition",

    "record-table.failed-records.tooltip":
        "This number only contains one failed record per batch.",

    "failed-record-column-indicator.tooltip":
        "There are {count} failed records.",

    "product-enhancement.table.columns.key": "Key",
    "product-enhancement.table.columns.type": "Type",
    "product-enhancement.table.columns.status": "Status",
    "product-enhancement.table.columns.customers": "Customers",
    "product-enhancement.table.columns.description": "Description",
    "product-enhancement.table.columns.attachments": "Attachments",
    "product-enhancement.table.columns.summary": "Summary",
    "product-enhancement.table.columns.roadmapPriority": "Roadmap Priority",
    "product-enhancement.table.columns.eta": "ETA",
    "product-enhancement.table.columns.fixVersions": "Fix Versions",
    "product-enhancement.table.columns.popularity": "Popularity",
    "product-enhancement.table.columns.submited": "Submitted",
    "product-enhancement.table.columns.updated": "Updated",
    "product-enhancement.request-modal.title": "Create Product Request",
    "product-enhancement.request-form.summary.label": "Summary",
    "product-enhancement.request-form.summary.tooltip":
        "Enter a brief summary of what you seek to improve",
    "product-enhancement.request-form.summary.placeholder": "Enter summary",
    "product-enhancement.request-form.problem-description.label":
        "Problem Description",
    "product-enhancement.request-form.problem-description.tooltip":
        "Elaborate on the summary, describe in detail the requested improvement",
    "product-enhancement.request-form.problem-description.placeholder":
        "Enter description",
    "product-enhancement.request-form.customer-priority.label": "Priority",
    "product-enhancement.request-form.customer-priority.placeholder": "Select",
    "product-enhancement.request-form.components.label": "Products",
    "product-enhancement.request-form.components.placeholder":
        "Select components",
    "product-enhancement.request-form.components.tooltip":
        "Select all products that are affected by the issue or that you want improved",
    "product-enhancement.request-form.customers.placeholder": "Select account",
    "product-enhancement.request-form.customers.tooltip":
        "Select the customer or division on which behalf you are creating the request",
    "product-enhancement.request-form.attachments.label": "Attachments",
    "product-enhancement.request-form.consent.label":
        "I understand that submitting this Product Request is voluntary and I acknowledge that PFX may contact me to discuss the provided idea.",
    "elastic-search.query.search": "ELK Query Search",
    "elastic-search.query.dropdown": "Queries",
    "elastic-search.query.dropdown.save-and-search": "Save and Share",
    "elastic-search.query.dropdown.manage-queries": "Manage Queries",
    "elastic-search.modal.save-as-new-query": "Save as New Query",
    "elastic-search.modal.edit-query": "Edit Query",
    "elastic-search.modal.form.query-name": "Query Name",
    "elastic-search.modal.form.placeholder.query-name": "Enter Query name",
    "elastic-search.modal.form.query-string": "Query String",
    "elastic-search.modal.form.placeholder.query-string":
        "Enter search query name",
    "elastic-search.modal.form.share": "Share",
    "elastic-search.modal.form.placeholder.share": "Enter user email addresses",
    "elastic-search.modal.title.manage-queries": "Manage Queries",
    "elastic-search.modal.table.title.query-name": "Query Name",
    "elastic-search.modal.table.title.created-by": "Created by",
    "elastic-search.modal.table.title.last-updated": "Last Update",
    "elastic-search.modal.table.title.shared-with": "Shared with",
    "elastic-search.modal.table.title.actions": "Actions",
    "elastic-search.message.query-deleted-success": "Query has been deleted",
    "elastic-search.delete-confirmation.title":
        "Do you want to delete this query?",
    "elastic-search.delete-confirmation.message":
        "This action cannot be undone.",
    "elastic-search.message.query-saved": "Query has been saved",
    "elastic-search.message.query-changed": "Query changes have been saved",
    "elastic-search.validation.query-name.unique": "Query name must be unique",
    "clusters-list.header.name": "Name",
    "clusters-list.header.project-name": "Account Name",
    "clusters-list.header.type": "Type",
    "clusters-list.header.url": "URL",
    "clusters-list.header.version": "Version",
    "clusters-list.header.release-name": "Release Name",
    "clusters-list.header.last-upgrade": "Last Upgrade",
    "clusters-list.header.next-upgrade": "Next Upgrade",
    "clusters-list.header.custom-upgrade-time.major":
        "Major Version Upgrade Duration",
    "clusters-list.header.custom-upgrade-time.cluster-overrides":
        "Instance overrides",
    "clusters-list.header.custom-upgrade-time.minor":
        "Minor Version Upgrade Duration",
    "clusters-list.header.database-vendor": "Database Vendor",
    "clusters-list.header.database-version": "Database Version",
    "clusters-list.header.account-status": "Account Status",
    "clusters-list.header.account-id": "Account ID",
    "clusters-list.custom-upgrade-time.edit": "Edit Custom Upgrade Times",
    "clusters-list.custom-upgrade-time.major": "Major version",
    "clusters-list.custom-upgrade-time.minor": "Minor version",
    "clusters-list.custom-upgrade-time.edit.success":
        "Custom upgrade times saved",
    "clusters-list.custom-upgrade-time.edit.fail":
        "Saving custom upgrade times failed",
    "clusters-list.cluster-admin": "Instance Admin",
    "cluster-nodes.header.name": "Name",
    "cluster-nodes.header.jetty-host": "Jetty Host",
    "cluster-nodes.header.jetty-port": "Jetty Port",
    "cluster-nodes.header.last-join-date": "Last Join Date",
    "cluster-nodes.header.last-seen-date": "Last Seen Date",
    "cluster-nodes.header.server-roles": "Server Roles",
    "cluster-nodes.header.restrict-calculation-to-partition":
        "Restrict Calculation to Partition",
    "cluster-nodes.header.restrict-pa-data-to-loads-to-partition":
        "Restrict Analytics Data to Loads to Partition",
    "file-parsing.separator.label": "Separator",
    "file-parsing.separator.default": ",",
    "file-parsing.separator.validation": "Separator must have 1 character",

    "file-parsing.quote-char.label": "Quote character",
    "file-parsing.quote-char.default": '"',
    "file-parsing.quote-char.validation":
        "Quote character must have 1 character",

    "file-parsing.escape-char.label": "Escape character",
    "file-parsing.escape-char.default": "\\\\",
    "file-parsing.escape-char.validation":
        "Escape character must have 1 character",

    "file-parsing.decimal-separator.label": "Decimal Separator",

    "certificate-table.label.certificateId": "Certificate Id",
    "certificate-table.label.validFrom": "Valid from",
    "certificate-table.label.validTo": "Valid to",
    "certificate-table.label.certificate": "Certificate",
    "certificate-table.delete.modal.title":
        "Do you want to delete this certificate?",
    "certificate-table.delete.modal.message":
        "This action can have an impact on your login.",
    "certificate-table.add-certificate": "Add Certificate",

    "sso.add-certificate.modal.title": "Add Certificate",
    "sso.add-certificate.label.id": "Certificate Id",
    "sso.add-certificate.placeholder.id": "Enter Certificate Name",
    "sso.add-certificate.label.certificate": "Certificate",

    "api-token-table.delete.modal.message":
        "Do you want to delete {tokenName}?",
    "api-token-table.label.comment": "Token Name",
    "api-token-table.label.username": "Username",
    "api-token-table.label.createdBy": "Created by",
    "api-token-table.label.createdAt": "Created at",
    "api-token.message.deleted": "{tokenName} has been deleted",
    "api-token.message.created": "{tokenName} has been created",
    "api-token.message.already-existing": "Account Token is already existing.",
    "api-token.description":
        "Create your Account API Token. Only one token per account. This token can be used in automated deployment of Event Workflows configuration. Check the detailed ",
    "api-token.button.new-token": "New API Token",
    "api-token.modal.create.title": "Create Account API Token",
    "api-token.modal.create.form.tokenName": "Token Name",
    "api-token.modal.created.title": "Created Token",
    "api-token.modal.created.content1": "Your created Account API Token is:",
    "api-token.modal.created.content2": "Store it in safe place.",

    "file-parsing.date-format.label": "Date Format",
    "file-parsing.date-format.default": "yyyy-MM-dd",
    "file-parsing.use-virtual-header.label": "Uploaded file contains header",
    "alert-rule.history.modal.title": "Alert Rule History",
    "alert-rules-list.histroy-table.header.operation-type": "Operation Type",
    "alert-rules-list.histroy-table.header.changed-by": "Changed by",
    "alert-rules-list.histroy-table.header.changed-at": "Changed at",
    "accelerators.currency-setting.title": "Currency Setting",
    "accelerators.currency-setting.description":
        "Select your base currency. In addition, you can also select other reporting currencies.",
    "accelerators.select.title.base-currency": "Base currency (required)",
    "accelerators.select.title.reporting-currencies":
        "Reporting currencies (optional)",
    "advanced-field-editor.title": "Advanced Field Editor",
    "advanced-field-editor.documentation": "Type Expression Documentation",
    "advanced-field-editor.output-preview.label": "Output Preview",
    "advanced-field-editor.converter-warning-1":
        "Converter expression autogenerated for different parser configuration",
    "advanced-field-editor.converter-warning-2":
        "Check if the expression is correct",
    "account-salesfx-link.tooltip": "Open account in Salesfx",
    "account-salesfx-link.tooltip-not-defined":
        "Salesfx account ID is not defined",

    "offer-edit.header.new-offer": "New offer",
    "offer-edit.header.edit-offer": "Edit offer",
    "offer-edit.perex": " ",
    "offer-edit.upload.text": "Upload image (maximum {maxSize} MB)",
    "offer-edit.error.get-detail": "Unable to fetch offer detail",
    "offer-edit.error.max-image-size": "Maximum file size is {maxSize} MB",
    "offer-edit.error.edit-offer": "Unable to edit the offer",
    "offer-edit.error.create-offer": "Unable to create the offer",
    "offer-edit.name.label": "Name",
    "offer-edit.name.placeholder": "Enter name",
    "offer-edit.published.label": "Publish",
    "offer-edit.description.label": "Short Description",
    "offer-edit.description.placeholder": "Enter short description for tile",
    "offer-edit.detailedDescription.label": "Long Description",
    "offer-edit.detailedDescription.placeholder":
        "Enter long description for detail page",
    "offer-edit.link.label": "URL",
    "offer-edit.link.placeholder": "Enter URL address",
    "offer-edit.type.label": "Type",
    "offer-edit.button.cancel": "Cancel",
    "offer-edit.button.save": "Save",
    "offer-edit.button.saving": "Saving",
    "offer-edit.validator.starts-with-http":
        "Link must start with 'http(s)://'",

    "offer-list.action.edit": "Edit",
    "offer-list.action.delete": "Delete",
    "offer-list.button.create-new": "New Offer",
    "offer-list.header.label": "Label",
    "offer-list.header.published": "published",
    "offer-list.header.type": "type",
    "offer-list.error.fetch": "Unable to fetch offers",
    "offer-list.error.delete": "Unable to delete an offer",
    "offer-list.message.delete-confirm": "Delete offer {name}?",

    "super-assets-table.all-accounts": "All Accounts",
    "super-assets-table.all-integrations": "All Integrations",
    "super-assets-table.all-partitions": "All Partitions",
    "super-assets-table.asset-types-label": "Asset types",

    "workflows.nav-item.my-approvals": "My Approvals",
    "account.workflows.header.action": "Action",
    "account.workflows.header.created-by": "Created by",
    "account.workflows.header.created-at": "Created at",
    "account.workflows.header.asset": "Asset",
    "account.workflows.header.target": "Target",
    "account.workflows.header.name": "Name",
    "account.workflows.confirm-workflow.title-approve": "Approve Workflow",
    "account.workflows.confirm.workflow.message-approve":
        "{user} wants to approve {action} for {account} {assetName}.",
    "account.workflows.confirm.workflow.message-approve-question":
        "Do you want to approve workflow?",
    "account.workflows.detail.label.action": "Action",
    "account.workflows.detail.label.account": "Account",
    "account.workflows.detail.label.asset": "Asset",
    "account.workflows.detail.label.created-by": "Created by",
    "account.workflows.detail.label.created-at": "Created at",
    "account.workflows.detail.label.list-of-approvers": "List of Approvers",
    "account.workflows.detail.label.list-of-group-approvers":
        "List of Group Approvers",
    "account.workflows.detail.label.list-of-admin-group-approvers":
        "List of Admin Group Approvers",
    "account.workflows.detail.label.list-of-role-approvers":
        "List of Role Approvers",
    "account.workflows.detail.label.number-of-approvers":
        "Number of Users to Approve",

    "copy-partition.form.title": "Copy partition",
    "copy-partition.form.description":
        "You are planning to create a new instance of this partition. The current partition will stay untouched.",
    "copy-partition.form.salesforce-contact.label": "Salesforce contact",
    "copy-partition.form.description.label": "Description",
    "copy-partition.form.description.placeholder": "Enter Description",
    "copy-partition.destinationCluster.label": "Destination Instance",
    "copy-partition.destinationCluster.tooltip":
        "Only instances that meet the criteria for the selected action (same version, same database type, etc.) are listed",
    "copy-partition.sourcePartition.label": "Source Partition",
    "copy-partition.success":
        "The request to copy the partition was submitted to a workflow and needs to be approved. Please wait for the workflow to finish.",
    "copy-partition.error": "Partition copying failed",
    "copy-partition.form.partitionLabel.label": "Partition Label",
    "copy-partition.form.partitionLabel.placeholder": "Enter Partition Label",
    "copy-partition.form.partitionName.label": "Partition Serial Name",
    "copy-partition.form.partitionName.placeholder": "Enter Partition Name",

    "workflow-list.header.name": "Name",
    "workflow-list.header.action": "Action",
    "workflow-list.header.created-by": "Created By",
    "workflow-list.header.created-at": "Created At",
    "workflow-list.header.accountName": "Account",
    "workflow-list.action.edit": "Edit",
    "workflow-list.action.delete": "Delete",
    "workflow-list.button.create-new": "Create Workflow",
    "workflow-list.panel.approve-by": "Approve by",

    "workflow-create.required.approval":
        "At least one approval type is required",
    "workflow-create.label.name": "Name",
    "workflow-create.placeholder.name": "Enter workflow name",
    "workflow-create.label.action": "Action",
    "workflow-create.placeholder.action": "Select action",
    "workflow-create.label.persons": "User(s)",
    "workflow-create.placeholder.persons": "Select user(s)",
    "workflow-create.placeholder.persons-search": "Type to search user(s)",
    "workflow-create.label.groups": "Group(s)",
    "workflow-create.placeholder.groups": "Select group(s)",
    "workflow-create.label.roles": "Role(s)",
    "workflow-create.placeholder.roles": "Select role(s)",
    "workflow-create.label.approved-number": "Number of Users to Approve",
    "workflow-create.button.add-approval": "Add Approval",
    "workflow-create.button.remove-approval": "Remove Approval",
    "workflow-create.text.approval": "Approvers",
    "workflow-create.text.and": "and",
    "workflow-create.label.PERMISSION_ASSIGNMENT_WORKFLOW":
        "Permission assignment",
    "workflow-create.all-accounts": "All Accounts",
    "workflow-create.label.account": "Account",
    "workflow-create.placeholder.account": "Select Account",
    "workflow-create.placeholder.PERMISSION_ASSIGNMENT_WORKFLOW":
        "Select Permission assignment",
    "workflow-create.account-change-info":
        "Do you really want to change the account? This change resets your prefilled settings.",

    "workflow-list.error.fetch": "Unable to fetch workflows.",
    "workflow-list.error.create": "Unable to create the workflow.",
    "workflow-list.error.delete": "Unable to delete the workflow.",
    "workflow-create.warning.used-type":
        "The selected type is already used. Saving will override the previous version.",
    "workflow-list.message.delete-confirm": "Delete workflow {name}.",

    "workflow-create.title.create": "Create Workflow",
    "workflow-create.title.edit": "Edit Workflow",

    "workflow-type.CREATE_PARTITION_APPROVAL": "Create Partition",
    "workflow-type.PERMISSION_ASSIGNMENT_APPROVAL": "Permission Assignment",
    "workflow-type.DELETE_CLUSTER_APPROVAL": "Delete Instance",
    "workflow-type.DELETE_INTEGRATION_MANAGER_INSTANCE_APPROVAL":
        "Delete IntegrationManager Instance",
    "workflow-type.PARTITION_PROVISIONING_APPROVAL": "Copy/Move Partition",
    "workflow-type.DELETE_PARTITION_APPROVAL": "Delete Partition",
    "workflow-type.CALCULATION_ENGINE_APPROVAL": "Calculation Engine Request",
    "workflow-type.PFX_ENG_SUPPORT_USER_APPROVAL": "Eng Support User Request",

    "workflow-permission.alerts.edit": "Alerts - edit",
    "workflow-permission.partition.allow-create":
        "Partition - allow create/delete",
    "workflow-permission.partition.global-asset-class": "Global asset class ",

    "account.workflows.confirm-workflow.title-reject": "Reject Workflow",
    "account.workflows.confirm.workflow.message-reject":
        "{user} wants to reject {action} for {account} {assetName}.",
    "account.workflows.confirm.workflow.message-reject-question":
        "Do you want to reject workflow?",

    "workflows.type.create-partition-approval": "Create Partition",
    "workflows.type.permission-assignment-approval": "Permission Assignment",
    "workflows.type.delete-partition-approval": "Delete Partition",
    "workflows.type.delete-cluster-approval": "Delete Instance",
    "workflows.type.delete-im-instance-approval":
        "Delete IntegrationManager Instance",
    "workflows.type.calculation-engine-approval": "Calculation Engine Request",
    "workflows.type.partition-provisioning-approval":
        "Partition Provisioning Approval",
    "workflows.type.pfx-eng-support-user-approval": "Eng Support User Request",
    "workflows.type.saml-sso-approval": "SAML SSO Approval",
    "workflows.type.event-orchestration-approval":
        "Event Orchestration Approval",
    "workflows.type.copilot-approval": "Copilot Approval",

    "workflow-approval-page.title": "Workflow {action}",
    "workflow-approval-page.action-taken": "action already taken",
    "workflow-approval-page.description":
        "Action has been successfully processed with the result {action}",
    "workflow-approval-page.repeated action": "repeated action",
    "workflow-approval-page.button.to-pm": "Open PlatformManager",
    "workflow-running-modal.title": "Workflow in Progress",
    "workflow-running-modal.description": "{action} already in progress.",
    "workflow-running-modal.table.description": "Pending approvals:",

    "workflow-approval-modal.title": "Approval required",
    "workflow-approval-modal.description":
        "To make changes to this Workflow, you need permission from your administrator.",
    "workflow-approval-modal.rationale-description":
        "To make changes to this Workflow, you need permission from your administrator. Please describe the rationale for these changes.",
    "workflow-approval-modal.submit": "Request approval",

    "workflow-approval-pending-modal.title": "Change Request Pending",

    "event-wf.tab.workflows": "Workflows",
    "event-wf.tab.running-workflows": "Running Workflows",
    "event-wf.tab.workflow-log": "Workflow Log",

    "event-wf.listener.column.name.label": "Listener",
    "event-wf.listener.column.description.label": "Description",
    "event-wf.listener.column.lastRun.label": "Last run",
    "event-wf.listener.column.createdAt.label": "Created at",
    "event-wf.listener.column.action.label": "Action",
    "event-wf.listener.column.enabled.label": "Enabled",
    "event-wf.listener.column.validatePreviousStepAsyncTaskId.label":
        "Wait for previous step",

    "event-wf.copy.modal.title": "Create a Copy",
    "event-wf.copy.form.workflowName.label": "Workflow Name",
    "event-wf.copy.form.enabled.label": "Enabled",
    "event-wf.copy.form.perex":
        "You can replace sources/targets and other used entities for the copied workflow.",
    "event-wf.copy.form.single-source.original.label": "Original Single Source",
    "event-wf.copy.form.single-source.copy.label": "New Copy Single Source",
    "event-wf.copy.form.source.original.label": "Original Source",
    "event-wf.copy.form.source.copy.label": "New Copy Source",
    "event-wf.copy.form.target.original.label": "Original Target",
    "event-wf.copy.form.target.copy.label": "New Copy Target",
    "event-wf.copy.form.workflows.original.label": "Original Workflows",
    "event-wf.copy.form.workflows.copy.label": "New Copy Workflows",
    "event-wf.copy.form.pmActions.original.label": "Original Data Downloads",
    "event-wf.copy.form.pmActions.copy.label": "New Copy Data Downloads",
    "event-wf.copy.success":
        "Workflow is being copied. It may take several minutes.",

    "event-wf.form.perex":
        "Configure your Event Workflow by adding at least one Event Listener. A workflow is successfully completed when each listener receives an Event in a timeframe defined by a Delay value of a preceding listener.",

    "event-wf.form.general": "General",
    "event-wf.form.types": "Types",
    "event-wf.form.listeners": "Listeners",
    "event-wf.form.listeners.warnings":
        "{count, plural, one {Warning: #} other {Warnings: #}}",
    "event-wf.form.listeners.errors":
        "{count, plural, one {Error: #} other {Errors: #}}",
    "event-wf.form.enabled.label": "Status",
    "event-wf.form.enabled.tooltip":
        "Switch the whole workflow on/off. If switched off, intercepted event will not trigger this workflow.",
    "event-wf.form.name.label": "Workflow Name",
    "event-wf.form.executionOrder.label": "Execution order",
    "event-wf.form.timeoutInMinutes.label": "Global Workflow Timeout",
    "event-wf.form.timeoutInMinutes.placeholder":
        "Default system timeout is 1 day",
    "event-wf.form.timeoutInMinutes.tooltip":
        "When workflow timeout is set, the whole workflow must finish within this time. If the time is exceeded, workflow will be terminated at its current state and no more events will be processed nor actions triggered.",
    "event-wf.form.add-listener": "Add listener",
    "event-wf.form.edit-listener": "Edit listener",
    "event-wf.form.view-listener": "View listener",
    "event-wf.form.source": "Source",
    "event-wf.form.source-events": "Source Events",
    "event-wf.form.target": "Target",
    "event-wf.form.action": "Target Action",
    "event-wf.form.payload": "Payload",
    "event-wf.form.custom-action": "Custom Action",
    "event-wf.form.workflowType.label": "Workflow Type",
    "event-wf.form.workflowType.tooltip":
        "Single-source type means that the workflow takes place within a single entity (e.g. a partition). Multi-source type allows the combination of events/actions in multiple entities.",
    "event-wf.listener-form.steps.general.title": "General",
    "event-wf.listener-form.steps.general.perex":
        "Enter a name and description of your listener.",
    "event-wf.listener-form.steps.source.title": "Source",
    "event-wf.listener-form.steps.source.perex":
        "In this step you can define one or more Source Events that will trigger this Listener. Filters can be used for a more specific selection. You can also define Skip Events to skip this Listener.",
    "event-wf.listener-form.steps.action.title": "Action",
    "event-wf.listener-form.steps.action.perex":
        "In this step select a Target (partition, integration) and an Action to be triggered on the specified Target. The Action will be triggered with an empty payload as default.",
    // Will be added back later: https://pricefx.atlassian.net/browse/PFIM-7602
    // "In this step select a Target (partition, integration) and an Action to be triggered on the specified Target. The Action will be triggered with an empty payload as default. You can define a custom payload if needed.",
    "event-wf.listener-form.steps.fallback.title": "Fallback",
    "event-wf.listener-form.steps.fallback.perex":
        "In case the call to trigger an Action fails, you can specify Fallback Action in this step.",
    "event-wf.listener-form.fallback.label": "Fallback",
    "event-wf.listener-form.fallback.tooltip":
        "If enabled, system will monitor response to an action trigger. In case of an error, selected action will be attempted (retry, skip, trigger alternative action).",
    "event-wf.listener-form.numberOfAttempts.label": "Number of attempts",
    "event-wf.listener-form.numberOfAttempts.tooltip":
        "The number of attempts before the Fallback Action is triggered.",

    "event-wf.listener-form.fallbackTimeout.label": "Fallback Timeout",
    "event-wf.listener-form.fallbackTimeout.tooltip":
        "If a response is not received in selected interval, fallback action is triggered.",
    "event-wf.listener-form.fallbackAction.label": "Fallback Action",
    "event-wf.listener-form.fallbackAction.tooltip":
        "Selected behavior will be triggered if the system will exhaust above selected options (retry, timeout). Please note if no retry or timeout is selected, selected behavior may not be triggered and the listener can end in never ending waiting.",

    "event-wf.listener-form.payload": "Payload",
    "event-wf.listener-form.payload-content": "Payload content",
    "event-wf.listener-form.fallback-payload": "Fallback Payload",
    "event-wf.listener-form.name.label": "Listener Name",
    "event-wf.listener-form.description.label": "Description",
    "event-wf.listener-form.sourceType.label": "Source Type",
    "event-wf.listener-form.sourceId.label": "Source Name",
    "event-wf.listener-form.sourceEvents.label": "Source Events",
    "event-wf.listener-form.sourceEvents.add-button": "Add Source Event",
    "event-wf.listener-form.sourceEvents.modal.title.add": "Add Source Event",
    "event-wf.listener-form.sourceEvents.modal.title.edit": "Edit Source Event",
    "event-wf.listener-form.sourceEvents.modal.title.view": "View Source Event",
    "event-wf.listener-form.skipEvents.label": "Skip Events",
    "event-wf.listener-form.skipEvents.perex":
        "When the Skip event is received, the entire Listener is skipped. This means that no action is triggered and the payload is simply passed to the next Listener in the Workflow.",
    "event-wf.listener-form.skipEvents.add-button": "Add Skip Event",
    "event-wf.listener-form.skipEvents.modal.title.add": "Add Skip Event",
    "event-wf.listener-form.skipEvents.modal.title.edit": "Edit Skip Event",
    "event-wf.listener-form.skipEvents.modal.title.view": "View Skip Event",
    "event-wf.listener-form.sourceEventType.label": "Event Type",
    "event-wf.listener-form.filter.jsonPath.label": "File parameter",
    "event-wf.listener-form.filter.value.label": "Value",
    "event-wf.listener-form.sourceEventParameters": "Source Event Parameters",
    "event-wf.listener-form.filter": "Filter",
    "event-wf.listener-form.destinationType.label": "Target Type",
    "event-wf.listener-form.destinationId.label": "Target Name",
    "event-wf.listener-form.destinationTargetType.label": "Action Type",
    "event-wf.listener-form.destinationTargetId.label": "Action ID",
    "event-wf.listener-form.timeoutInMinutes.label": "Listener Timeout",
    "event-wf.listener-form.timeout.label": "Timeout",
    "event-wf.listener-form.timeout.tooltip":
        'If the timeout is exceeded, the listener will stop waiting for events and the current workflow run will be terminated. Only usable with the option "All".',
    "event-wf.listener-form.timein.label": "Delayed trigger",
    "event-wf.listener-form.timein.tooltip":
        "If a delay is specified, the workflow will wait for the selected amount of time before processing begins. This delay will start when the first event is received.",
    "event-wf.listener-form.combinationLogic.label":
        "Source Events Combination Logic",
    "event-wf.listener-form.combinationLogic.tooltip":
        '"All" option - listener will wait until all the above listed events are intercepted and then will continue with the action."One of" option - as soon any of the above listed events is intercepted, the listener will continue with the action.',
    "event-wf.listener-form.combinationLogic.all.label": "All",
    "event-wf.listener-form.combinationLogic.oneOf.label": "One of",
    "event-wf.listener-form.timeoutInMinutes.tooltip":
        "Timeout specified for this listener (in minutes)",
    "event-wf.listener-form.validatePreviousStepAsyncTaskId.label":
        "Wait for the event from the previous step",
    "event-wf.listener-form.actions.column.targetType": "Target Type",
    "event-wf.listener-form.actions.column.targetName": "Target Name",
    "event-wf.listener-form.actions.column.actionType": "Action Type",
    "event-wf.listener-form.actions.column.actionId": "Action ID",
    "event-wf.listener-form.actions.addAction": "Add Action",
    "event-wf.listener-form.actions.title.new": "New Action",
    "event-wf.listener-form.actions.title.edit": "Edit Action",
    "event-wf.listener-form.actions.title.view": "View Action",
    "event-wf.listener-form.actions.save": "Save Action",
    "event-wf.running.column.name": "Workflow",
    "event-wf.running.column.description": "Description",
    "event-wf.running.column.status": "Status",
    "event-wf.running.column.lastRun": "Last Run",
    "event-wf.running.column.createdAt": "Created At",
    "event-wf.workflows.column.name": "Workflow",
    "event-wf.workflows.column.target": "Target",
    "event-wf.workflows.column.description": "Description",
    "event-wf.workflows.column.status": "Status",
    "event-wf.workflows.column.lastRun": "Last Run",
    "event-wf.workflows.column.createdAt": "Created At",
    "event-wf.workflows.column.createdBy": "Created By",
    "event-wf.workflows.column.updatedAt": "Last Modified At",
    "event-wf.workflows.column.updatedBy": "Last Modified By",
    "event-wf.workflows.modal.delete.title": "Delete this Event Workflow?",
    "event-wf.workflows.modal.delete.message":
        "The system will check any Event Workflow dependencies before deleting this Event Workflow. This may take some time, you will be notified of the results.",
    "event-wf.workflows.message.delete-request.success":
        "Workflow deletion has been requested successfully",
    "event-wf.workflows.alert.unable-to-run.label": "Invalid Links",
    "event-wf.running.action.stop.title": "Stop Workflow",
    "event-wf.running.action.stop.message":
        "This going to stop running workflow with all listeners in it. Do you want to stop it?",
    "event-wf.running.action.restart-last-step.title":
        "Restart Last Executed Step",
    "event-wf.running.action.restart-last-step.message":
        "Do you want to restart the last executed workflow step?",
    "event-wf.running.action.restart-last-step.success-message":
        "Last executed workflow step restarted",

    "event-wf.logs.tab.run-history": "Run History",
    "event-wf.logs.tab.debug-logs": "Debug Logs",

    "general.does-not-exist": "{item} does not exist any more.",
    "general.name-saved-as": "{name} is saved as {value}.",
    "general.general": "General",
    "general.entities": "Entities",
    "general.destination": "Destination",
    "general.show-run-history": "Show Run History",
    "general.workflow-approval-pending.title": "Change Request Pending",
    "general.workflow-approval-pending.description":
        "A change request for this {entity} has already been submitted. Please wait for the previous request to be approved before submitting a new one.",
    "general.workflow": "Workflow",
    "general.scheduler": "Scheduler",
    "event-scheduler.list.columns.name": "Name",
    "event-scheduler.list.columns.destinationName": "Target",
    "event-scheduler.list.columns.destinationTargetLabel": "Action",
    "event-scheduler.list.columns.enabled": "Status",
    "event-scheduler.list.columns.startTime": "Active from",
    "event-scheduler.list.columns.type": "Repeat",
    "event-scheduler.list.columns.endTime": "Active to",
    "event-scheduler.list.columns.lastExecution": "Last run",
    "event-scheduler.list.columns.nextExecution": "Next run",
    "event-scheduler.list.columns.createdAt": "Created At",
    "event-scheduler.list.columns.createdBy": "Created By",
    "event-scheduler.list.columns.updatedAt": "Last Modified At",
    "event-scheduler.list.columns.updatedBy": "Last Modified By",
    "event-scheduler.form.perex":
        "You are able to schedule an action on a target partition or IM so that you have the whole orchestration process in one place.",

    "event-scheduler.group.general.label": "General",
    "event-scheduler.form.enabled.label": "Status",
    "event-scheduler.form.enabled.tooltip":
        "Switch the whole scheduler on/off. If switched off, scheduler will not trigger actions defined below.",
    "event-scheduler.form.name.label": "Scheduler Name",

    "event-scheduler.group.target.label": "Target",
    "event-scheduler.form.targetType.label": "Target Type",
    "event-scheduler.form.targetName.label": "Target Name",

    "event-scheduler.group.action.label": "Action",
    "event-scheduler.form.action.label": "Action",
    "event-scheduler.form.actionId.label": "Action ID",

    "event-scheduler.group.payload.label": "Payload",
    "event-scheduler.group.payload.body": "Body",
    "event-scheduler.form.body.label": "Payload content",
    "event-scheduler.group.payload.headers": "Headers",
    "event-scheduler.form.headers.add-header": "Add Header",
    "event-scheduler.form.headers.edit-header": "Edit Header",
    "event-scheduler.group.payload.properties": "Properties",
    "event-scheduler.form.properties.add-property": "Add Property",
    "event-scheduler.form.properties.edit-property": "Edit Property",

    "event-scheduler.group.timing.label": "Timing",
    "event-scheduler.form.startTime.label": "Active from",
    "event-scheduler.form.eventTime.label": "Event time",
    "event-scheduler.form.endTime.label": "Active to",
    "event-scheduler.form.endTime.tooltip":
        "The Scheduler will no longer run after this period of time.",
    "event-scheduler.form.timezone.label": "Timezone",
    "event-scheduler.form.repeat.label": "Repeat",

    "event-scheduler.form.every.label": "Every",
    "event-scheduler.form.at.label": "At",
    "event-scheduler.form.day.label": "Day",
    "event-scheduler.form.on.label": "On",
    "event-scheduler.form.dayOfYear.label": "DayOfYear",
    "event-scheduler.form.cron.label": "Cron definition",
    "event-scheduler.form.cron.description":
        "Special Quartz cron must be used (not Unix cron). See {link} for more details.",

    "event-scheduler.timing.summary": "Summary",
    "event-scheduler.form.trigger.label": "Will be triggered",
    "event-scheduler.form.next-run.label": "Next run",
    "event-scheduler.form.last-run.label": "Last run",

    "account-admin-users-list.atlassian.atlassian-management":
        "Atlassian Management",
    "account-admin-users-list.atlassian.assign-to-group":
        "Assign to Atlassian Group",
    "account-admin-users-list.atlassian.remove-from-group":
        "Remove from Atlassian Group",

    "account-admin-users-list.resend-activation-email":
        "Resend Activation Email",
    "account-admin-users-list.resend-activation-email-ok":
        "Activation email was successfully resent",
    "account-admin-users-list.resend-activation-email-failed":
        "Failed to resend activation email",

    "user-atlassian-groups.assign-modal.title":
        "Assign User to Atlassian Group",
    "user-atlassian-groups.remove-modal.title":
        "Remove User from Atlassian Group",
    "user-atlassian-groups.form.username.label": "Username",
    "user-atlassian-groups.form.group-name.label": "Atlassian Group",

    "table.action.create.notification": "Create Notification",
    "package.setup-rebate-header-type.form.header-rebate-type-id.label":
        "Rebate Agreement Type",
    "package.setup-rebate-header-type.form.header-rebate-type-id.tooltip":
        "Selected Rebate Agreement Type will be set as the default. The default is on the first position in a table of types and has a unique name that is used instead of the original name defined in the code of the deployed package. Your choice can be later modified. The empty selection indicates the default is kept without modification and you should use it during upgrades.",
    "package.setup-rebate-header-type.submit.label": "Next",
    "version-integrity-check.table.columns.label.type": "Type",
    "version-integrity-check.table.columns.label.name": "Name",
    "version-integrity-check.table.columns.label.change": "Change",
    "version-integrity-check.modal.title": "Version Integrity Check",
    "atlassian-groups.modal.title.create": "Create Atlassian Group",
    "atlassian-groups.modal.title.edit": "Edit Atlassian Group",
    "atlassian-groups.form.name.label": "Name",
    "atlassian-groups.form.name.placeholder": "Enter group name",
    "atlassian-groups.form.account.label": "Account",
    "atlassian-groups.form.account.placeholder": "Select",
    "atlassian-groups.form.description.label": "Description",
    "atlassian-groups.form.description.placeholder": "Enter description",
    "atlassian-groups.form.type.label": "Group Type",
    "atlassian-groups.form.type.placeholder": "Select",
    "atlassian-groups.form.userAccountIds.label": "Users in Atlassian Group",
    "version-integrity-check.error.external-service-unavailable":
        "PlatformManager cannot reach partition {partitionName}",
    "version-integrity-check.error.partition-credentials-are-incorrect":
        "Partition credentials are not correct, please try again",
    "version-integrity-check.error.partition-credentials-are-incorrect-link":
        "Partition Access Entitlement page",
    "version-integrity-check.error.api-invalid-parameter.bijou":
        "Version Integrity Check is available for Bijou 7.0 release or later. Please upgrade the partition to be able to use this feature.",
    "step-edit-form.modal.title": "Edit {stepName}",
    "step-edit-form.title.label": "Title",
    "step-edit-form.title.placeholder": "Enter Title",
    "step-edit-form.description.label": "Description",
    "step-edit-form.description.placeholder": "Enter Description",
    "step-edit-form.placeholder.placeholder": "Replacement for {fieldName}",
    "template.edit-form.tab.general-settings": "General Settings",
    "template.edit-form.tab.steps": "Steps",
    "template.edit-form.label": "Label",
    "template.edit-form.documentationUrl.label": "Documentation URL",
    "template.edit-form.documentationUrl.placeholder":
        "Enter Documentation URL",
    "template.edit-form.packageType": "Package's type",
    "template.edit-form.tags-visibility": "Tags visibility",
    "template.edit-form.tags": "Tags",
    "template.edit-form.description": "Description",
    "template.edit-form.icon": "Icon",
    "template.edit-form.label.placeholder": "Enter Label",
    "template.edit-form.description.placeholder": "Enter Description",
    "template.edit-form.description.title": "Edit Accelerator/Template",
    "template.edit-form.notification-success":
        "The template has been updated successfully.",
    "template.edit-form.steps-table.step": "Step",
    "account-deployed-templates.header.label": "Label",
    "account-deployed-templates.header.template-name": "Name",
    "account-deployed-templates.header.template-version": "Version",
    "account-deployed-templates.header.partition-name": "Partition Name",
    "account-deployed-templates.header.instance-name": "Instance Name",
    "account-deployed-templates.header.deployed-by": "Deployed by",
    "account-deployed-templates.header.step": "Step",
    "account-deployed-templates.header.deployed-at": "Deployed at",
    "account-deployed-templates.header.status": "Status",
    "metadata-params-form.connection-from.label": "Connection From",
    "metadata-params-form.connection-to.label": "Connection To",
    "metadata-params-form.metadata-uri.label": "Metadata URI",
    "metadata-params-form.metadata-uri.help":
        "Metadata can be fetched from URI address or uploaded as file",
    "metadata-params-form.connection-uri.label": "Connection URI",
    "metadata-params-form.configuration.label": "Configuration",
    "metadata-params-form.dropzone.empty.label": "Upload metadata file",
    "route.deploy.error.fetch-deployed-routes": "Unable to fetch route status",
    "route.deploy.error.undeploy-route": "Unable to undeploy route",
    "route.deploy.warn-confirm.deploy-title": "The route is running",
    "route.deploy.warn-confirm.deploy-message":
        "Do you want to deploy route {routeName}? Running route will be redeployed.",
    "rollback.modal.title": "Rollback Deployment",
    "rollback.modal.button.rollback": "Rollback",
    "rollback.table.header.type": "Type",
    "rollback.table.header.name": "Name",
    "rollback.modal.perex-unfinished-deployment":
        "You have an unfinished deployment of {templateName} from {date}. Before starting a new deployment the previous one has to be finished or rollbacked. The rollback might take several minutes. ",
    "rollback.modal.perex":
        "Important: You are about to delete selected entities from your partition. Please keep in mind that PlatformManager does not check if the selected entities are used by any other existing configuration on your partition. Deleting may break such configurations. Do you want to proceed?",
    "sso.form.submitted.info.for-approval-item":
        "Workflow approval process is already running for this item.",
    "sso.form.submitted.info.for-approval":
        "This SAML SSO configuration change has already been submitted for approval.",
    "sso.form.expirenotificationrecipients.label": "Expiry Notification",
    "sso.form.expirenotificationrecipients.tooltip":
        "Fill in email address(es) where notification about ending validity of the certificate will be sent",
    "sso.form.accountid.label": "Account",
    "sso.form.webssourl.label": "SAML Identity Provider URL",
    "sso.form.webssourl.tooltip":
        "Fill in SAML Identity Provider URL. This information is available in your SSO configuration.",
    "sso.form.domain.label": "Email Domain",
    "sso.form.domain.tooltip":
        "Fill in your company email domain. Only users using email with this domain as username will be able to use SSO login. Changing this value needs to be approved.",
    "sso.form.entityid.label": "Entity ID",
    "sso.form.entityid.tooltip":
        "Fill in Entity ID. This information is available in your SSO configuration.",
    "sso.form.loginwith.label": "Log in using",
    "sso.form.enabled.label": "Single Sign-On",
    "feature-flags.page-title": "Feature Flags",
    "feature-flags.add-partition.button": "Add Partition",
    "feature-flags.show-partitions-with-flag.button":
        "Show Partitions using this Flag",
    "feature-flags.add-partition.desc": "Start by adding partition",
    "feature-flags.apply-settings": "Apply Settings",
    "feature-flags.add-partition.modal.title": "Add Partition",
    "feature-flags.add-partition.form.account.label": "Account",
    "feature-flags.add-partition.form.account.placeholder": "Select account",
    "feature-flags.add-partition.form.partition.label": "Partition",
    "feature-flags.add-partition.form.partition.placeholder":
        "Select partition",
    "feature-flags.add-partition.form.set.label": "Feature Flags Set Name",
    "feature-flags.add-partition.form.set.tooltip":
        'Possible to add only partitions with "{onlyPossibleSet}" FF set',
    "feature-flags.table.column.value": "Value",
    "feature-flags.partition.add-set.desc":
        "Start by adding feature flags set ",
    "feature-flags.partition.page-title": "[{set}] Partition Feature Flags",
    "feature-flags.partition.add-set.button": "Add Feature Flags Set",
    "feature-flags.partition.add-set.title": "Add Feature Flags Set",
    "feature-flags.partition.add-set.tooltip":
        "There can be multiple feature flags sets on a partition; please type the name of a set you want to work with (you can find it in the partition Administration menu)",
    "feature-flags.partition.change-set.button": "Change Set",
    "feature-flags.partition.change-set.validation.invalid-character":
        "Only alphanumeric characters and -<>_.!~*[] are allowed",
    "feature-flags.partition.change-set.validation.set-doesnt-exist":
        "This set name does not exist",
    "feature-flags.partition.copy-all.button": "Copy All",
    "feature-flags.copy-modal.title.copy-all": "Copy All Feature Flags",
    "feature-flags.copy-modal.title.copy-selected":
        "Copy Selected Feature Flags",
    "feature-flags.copy-modal.form.account.label": "Account",
    "feature-flags.copy-modal.form.account.placeholder": "Select account",
    "feature-flags.copy-modal.form.partition.label": "Partition",
    "feature-flags.copy-modal.form.partition.placeholder": "Select partition",
    "feature-flags.copy-modal.form.set.label": "Feature Flags Set Name",
    "feature-flags.copy-modal.form.set.placeholder":
        "Enter feature flags set name",
    "feature-flags.copy-modal.confirm.title": "Target Set Name Does Not Exist",
    "feature-flags.copy-modal.confirm.message":
        "The selected feature flags set name [{setName}] was not found on the target partition.\nDo you want to create a new feature flags set and use it for copying? ",
    "feature-flags.copy-modal.confirm.confirm-button": "Create & Copy",
    "feature-flags.copy-modal.partition-select.disabled-option-tooltip":
        'You do not have "Feature Flags - edit" permission for this partition',
    "feature-flags.unsaved-warning.confirm.title": "Non-applied Settings",
    "feature-flags.unsaved-warning.confirm.message":
        "Before you continue do you want to apply your settings?",

    "package-deployment.action.delete-deployment": "Rollback",
    "package-deployment.action.delete-deployment.title": "Rollback Deployment",
    "package-deployment.action.delete-deployment.info":
        "Request started successfully. You can check the progress in notifications.",

    "instance-settings.application-properties.columns.name": "Properties Name",
    "package-failed-option.table.column.row": "Row",
    "package-failed-option.table.column.error": "Error",
    "package-failed-option.header.message":
        "Don’t worry, we saved your progress and next time you can continue where you left. Try to correct your properties and upload again.",
    "package-failed-option.button.upload-again": "Upload Again",
    "package-failed-option.statistics.column.error": "errors in columns:",
    "package-failed-option.statistics.info":
        "Only up to 1000 records can be fetched",
    "package-failed-option.table.column.input-key": "Input Name",
    "package-failed-option.table.column.input-value": "Input Value",
    "constant.integration-type.custom": "Custom",
    "constant.integration-type.template": "Template",

    "merge-request-list.column.title": "Merge Request Name",
    "merge-request-list.column.merge-status": "Merge Status",
    "merge-request-list.column.state": "State",
    "merge-request-list.column.git-url": "Web URL",
    "merge-request-list.column.id": "ID",
    "merge-request-list.column.description": "Description",
    "merge-request-list.column.author": "Author",
    "merge-request-list.column.has-conflicts": "Conflicts",
    "merge-request-list.column.source-branch": "Source Branch",

    "location.accountApp.partitions.partition.calculationEngines":
        "Calculation Engines",
    "calculation-engines.action.new": "Request New Engine",
    "calculation-engines.action.retry.title": "Retry Applying",
    "calculation-engines.list.columns.engines.label": "Engines",
    "calculation-engine.page-new-title": "Request New Engine",
    "calculation-engine.page-edit-title": "Request Engine Change",
    "calculation-engine.perex":
        "Here you can create a request for a new calculation engine to be assigned to your partition or ask for changes to the already existing one.",
    "calculation-engine.machine-config": "Engine Configuration",
    "calculation-engine.request-workflow": "Request Workflow",
    "calculation-engine.request-workflow-desc":
        "Your request will go through the approval workflow. You will receive an email notification once the workflow is finished.",
    "calculation-engine.form.machines.label-new": "Available Engines",
    "calculation-engine.form.machines.label-edit": "Engine",
    "calculation-engine.form.cpu.label": "CPU",
    "calculation-engine.form.cpu.tooltip":
        "Please specify the number of required CPUs for the requested engine.",
    "calculation-engine.form.memory.label": "Memory",
    "calculation-engine.form.memory.tooltip":
        "Please specify the requested memory size for the requested engine.",
    "calculation-engine.form.reason.label": "Request Reason",
    "calculation-engine.form.reason.tooltip":
        "Please provide a short description that will justify this request, so that the person granting the approval has full picture behind this request.",
    "calculation-engine.form.validTo.label": "Valid to",
    "calculation-engine.action.request": "Request Engine",

    "adminReports-list.header.name": "Name",
    "adminReports-list.header.enabled": "Enabled",
    "adminReports-list.header.target": "Target",
    "adminReports-list.header.elkIndex": "ELK Index",
    "adminReports-list.header.query": "Query",
    "adminReports-list.header.timeZone": "Timezone",
    "adminReports-list.header.scheduledAt": "Scheduled at",
    "adminReports-list.header.lookBack": "Lookback (days)",
    "adminReports-list.header.repeatPeriod": "Repeat Period (days)",
    "adminReports-list.header.recipients": "Recipients",
    "adminReports-list.header.createdAt": "Created at",
    "adminReports-list.header.updatedAt": "Updated at",
    "adminReports-list.header.createdBy": "Created by",
    "adminReports-list.header.updatedBy": "Updated by",
    "adminReports-list.rowMenu.delete": "Delete Report",
    "adminReports-list.rowMenu.edit": "Edit Report",

    "adminReports-list.button.create-report": "Create Report",
    "adminReports-modal.form.name.label": "Name",
    "adminReports-modal.form.name.validation.exists": "Name already exists",
    "adminReports-modal.form.enabled.label": "Enabled",
    "adminReports-modal.form.target.label": "Target",
    "adminReports-modal.form.elkIndex.label": "ELK Index",
    "adminReports-modal.form.elIndex.validation":
        "Index is not available for the selected target",
    "adminReports-modal.form.query.label": "Query",
    "adminReports-modal.form.query.tooltip":
        "You can use “$dateFrom” and “$dateTo“ variables in your query to have the time range calculated dynamically.",
    "adminReports-modal.form.timeZone.label": "Time Zone",
    "adminReports-modal.form.repeatPeriod.label": "Repeat Period (Days)",
    "adminReports-modal.form.lookBack.label": "Lookback (Days)",
    "adminReports.save.success": "Report {name} has been saved successfully",
    "adminReports.delete.success":
        "Report {name} has been deleted successfully",
    "integration-archives.columns.instance-name": "Instance Name",
    "integration-archives.columns.status": "Status",
    "integration-archives.columns.dir-count": "DIR Count",
    "sidebar.footer.be-version": "BE version: {version}",
    "sidebar.footer.fe-version": "FE version: {version}",
    "increase-ims-limit.form.hint":
        "Dear PlatformManager user, you've exceeded the recommended technical limit of IntegrationManager instances you can create via PlatformManager. If you need an extra instance under this account, please create a {link}.",
    "increase-ims-limit.form.hint-link-text": "Helpdesk ticket",
    "increase-ims-limit.form.title":
        "Limit of IntegrationManager instances exceeded",
    "increase-ims-limit.form.max.label": "Max Integration Instances",
    "pm-feature-flags.perex":
        "You can activate new features to all users, or restrict their availability only for selected users.",
    "pm-feature-flags.label.name": "Feature Name",
    "pm-feature-flags.label.description": "Description",
    "pm-feature-flags.label.restriction": "Restriction for",
    "pm-feature-flags.label.active": "Active",
    "pm-feature-flags.collapse.header": "Restricted for",
    "pm-feature-flags.button.restrictions": "Manage restrictions",
    "pm-feature-flags.restrictions-modal.title": "Manage restrictions",
    "pm-feature-flags.restrictions-modal.label.main-type": "Type",
    "pm-feature-flags.restrictions-modal.label.primary": "List",
    "pm-feature-flags.restrictions-modal.label.secondary":
        "Additional Restrictions",
    "pm-feature-flags.restrictions-modal.form.type.account": "Account",
    "pm-feature-flags.restrictions-modal.form.type.user": "User",
    "pm-feature-flags.restrictions-modal.form.type.user-group": "User Group",
    "pm-feature-flags.restrictions-modal.form.button.add-new": "Add new",
    "partition-limit.column.project-name": "Account name",
    "partition-limit.column.env": "Environment",
    "partition-limit.column.cluster-name": "Instance name",
    "partition-limit.column.max": "Partition limit",
    "partition-limit.column.existing": "Number of existing partitions",
    "partition-limit.edit.title": "Edit {account} partitions limit",
    "partition-limit.edit.unlimited.label": "Unlimited",
    "eng-support-users.column.partitionSerialNumber": "Partition serial name",
    "eng-support-users.column.username": "Eng-support user",
    "eng-support-users.column.created-at": "Created at",
    "eng-support-users.column.expiration": "Expiration date",
    "eng-support-users.column.approved-by": "Approved by",
    "eng-support-users.column.is-active": "Status",
    "enable-deeplinks.form.label.page-name": "Parameter Name",
    "enable-deeplinks.form.label.url": "URL Structure",
    "enable-deeplinks.form.submit": "Send to Partitions",
    "enable-deeplinks.form.add-new-link": "Add a new link",
    "enable-deeplinks.last-bulk-sending":
        "Last Bulk Sending: {lastBulkSending}",
};

export default en;
