import { EntityType } from "@/constants/CommonConstats";
import { ISV_CONNECTIONS_LOGS } from "@/constants/sessionState.constants";
import { getData } from "@/services/utils";
import { buildQueryPart } from "@/utils/elk";
import PropTypes from "prop-types";
import React from "react";
import { useDic } from "../Dic/useDic.hook";
import { ElasticSearchPanelWithActions } from "../Integrations/Dashboard/Tabs/ElasticSearchPanel";

export const ISVLogHistory = ({
    visible,
    accountId,
    camelExchangeId,
    isvConnectionId,
}) => {
    const { axiosService } = useDic();
    const fetchData = params =>
        axiosService
            .post(`/api/isv-connections/${isvConnectionId}/logs`, params)
            .then(getData);
    const exportLogs = params =>
        axiosService.post(
            `/api/isv-connections/${isvConnectionId}/logs/export`,
            params,
        );

    // For the need of FE exchangeId and breadcrumbId can be considered as the same. Although breadcrumbId is present in more cases.
    const initialQuery = [buildQueryPart("camel.breadcrumbId", camelExchangeId)]
        .filter(Boolean)
        .join(" AND ");

    return (
        <ElasticSearchPanelWithActions
            fetchData={fetchData}
            // defaultColumns={DEFAULT_COLUMNS}
            exportLogMessages={exportLogs}
            entityId={accountId}
            entityType={EntityType.PROJECT}
            sessionStateName={ISV_CONNECTIONS_LOGS}
            initialQuery={initialQuery}
            visible={visible}
        />
    );
};

ISVLogHistory.propTypes = {
    visible: PropTypes.bool.isRequired,
    accountId: PropTypes.string.isRequired,
    camelExchangeId: PropTypes.string.isRequired,
    isvConnectionId: PropTypes.string.isRequired,
};
