import { AccountActivityLogPage } from "@/components/ActivityLog/AccountActivityLog.page";
import { AlertRulesAccountPage } from "@/components/AlertRules/AlertRulesPage.account.page";
import { NewAlertRuleAccountPage } from "@/components/AlertRules/NewAlertRule.account.page";
import { CalculationEnginePage } from "@/components/CalculationEngines/CalculationEngine.page";
import { CalculationEnginesPage } from "@/components/CalculationEngines/CalculationEngines.page";
import { CertificatesListPage } from "@/components/Certificates/CertificatesList.page";
import { EditCertificatePage } from "@/components/Certificates/EditCertificate.page";
import { NewCertificatePage } from "@/components/Certificates/NewCertificate.page";
import { ClassesDefinitionPage } from "@/components/Classes/ClassesDefinition.page";
import { ClassesListPage } from "@/components/Classes/ClassesList.page";
import { EditClassPage } from "@/components/Classes/EditClass.page";
import { NewClassPage } from "@/components/Classes/NewClass.page";
import { ConnectionsPage } from "@/components/Connections/Connections.page";
import { ConnectionsDefinitionPage } from "@/components/Connections/ConnectionsDefinition.page";
import { EditConnectionPage } from "@/components/Connections/EditConnection.page";
import { NewConnectionPage } from "@/components/Connections/NewConnection.page";
import { PartitionCredentialsPage } from "@/components/CustomerPartitionCredentials";
import { CustomerPartitionsPage } from "@/components/CustomerPartitions/CustomerPartitions.page";
import { EditPartitionPage } from "@/components/CustomerPartitions/EditPartition.page";
import { PartitionDashboardPage } from "@/components/CustomerPartitions/PartitionDashboard.page";
import { DataUploadHistoryPage } from "@/components/DataUploads/DataUploadHistory.page";
import { DataUploadSFTPManagementPage } from "@/components/DataUploads/DataUploadSFTPManagement.page";
import { NewDataUploadPage } from "@/components/DataUploads/NewDataUpload.page";
import { SFTPDataLoadUserPage } from "@/components/DataUploads/SftpManagement/SFTPDataLoadUser.page";
import { EditUploadPage } from "@/components/DataUploads/Wizard/EditDataUpload.page";
import {
    EO_LOGS_TABS,
    EventLogsPage,
} from "@/components/EventLogs/EventLogs.page";
import { EditEventSchedulerPage } from "@/components/EventSchedulers/EditEventScheduler.page";
import { EventSchedulersPage } from "@/components/EventSchedulers/EventSchedulers.page";
import { NewEventSchedulerPage } from "@/components/EventSchedulers/NewEventScheduler.page";
import { ShowEventSchedulerPage } from "@/components/EventSchedulers/ShowEventScheduler.page";
import { EditEventWorkflowPage } from "@/components/EventWorkflows/EditEventWorkflow.page";
import { EventWorkflowsPage } from "@/components/EventWorkflows/EventWorkflows.page";
import { NewEventWorkflowPage } from "@/components/EventWorkflows/NewEventWorkflow.page";
import { ShowEventWorkflowPage } from "@/components/EventWorkflows/ShowEventWorkflow.page";
import { PartitionFeatureFlagsPage } from "@/components/FeatureFlags/PartitionFeatureFlagsPage";
import { EditFilterPage } from "@/components/Filters/EditFilter.page";
import { FilterDefinitionPage } from "@/components/Filters/FilterDefinition.page";
import { FiltersListPage } from "@/components/Filters/FiltersList.page";
import { NewFilterPage } from "@/components/Filters/NewFilter.page";
import ISVConnectionPage from "@/components/ISVConnections/ISVConnection.page";
import ISVConnectionsListPage from "@/components/ISVConnections/ISVConnectionsList.page";
import ISVConnectionsOverviewPage from "@/components/ISVConnections/ISVConnectionsOverview.page";
import ISVConnectionsSettingsPage from "@/components/ISVConnections/ISVConnectionsSettings.page";
import { IsvSftpServerUserPage } from "@/components/ISVConnections/IsvSftpServerUser.page";
import ISVDataMappingListPage from "@/components/ISVMapping/ISVDataMapping.page";
import ISVDataMappingCreationPage from "@/components/ISVMapping/ISVDataMappingCreation.page";
import ISVDataMappingEditPage from "@/components/ISVMapping/ISVDataMappingEdit.page";
import ISVDataMappingUploadPage from "@/components/ISVMapping/ISVDataMappingUpload.page";
import { EditRoutePage } from "@/components/IntegrationRoutes/EditRoute.page";
import { IntegrationRoutesPage } from "@/components/IntegrationRoutes/IntegrationRoutes.page";
import { NewRoutePage } from "@/components/IntegrationRoutes/NewRoute.page";
import { RouteDefinitionPage } from "@/components/IntegrationRoutes/RouteDefinition.page";
import { RouteDiagramPage } from "@/components/IntegrationRoutes/RouteDiagram.page";
import { RouteDocumentationPage } from "@/components/IntegrationRoutes/RouteDocumentation.page";
import { RouteSendPayloadPage } from "@/components/IntegrationRoutes/RouteSendPayload.page";
import { InstanceDashboardPage } from "@/components/Integrations/Dashboard/InstanceDashboardPage";
import { InstanceGroupEditPage } from "@/components/Integrations/InstanceGroupEdit/InstanceGroupEdit.page";
import { IntegrationsPage } from "@/components/Integrations/Integrations.account.page";
import { NewInstancePage } from "@/components/Integrations/NewInstance.page";
import { IntegrationSettingsPage } from "@/components/Integrations/Settings.page";
import VersionHistoryPage from "@/components/Integrations/VersionHistory.account.page";
import { EditMapperPage } from "@/components/Mappers/EditMapper.page";
import { MapperDefinitionPage } from "@/components/Mappers/MapperDefinition.page";
import {
    MAPPER_KIND,
    MappersListPage,
} from "@/components/Mappers/MappersList.page";
import { NewMapperPage } from "@/components/Mappers/NewMapper.page";
import { EditPartitionConnectionPage } from "@/components/PartitionDataManagement/Connections/EditPartitionConnectionPage";
import { NewPartitionConnectionPage } from "@/components/PartitionDataManagement/Connections/NewPartitionConnectionPage";
import { AvailableFilesPage } from "@/components/PartitionDataManagement/Downloads/AvailableFilesPage";
import { EditDownloadPage } from "@/components/PartitionDataManagement/Downloads/EditDownloadPage";
import { NewDownloadPage } from "@/components/PartitionDataManagement/Downloads/NewDownloadPage";
import { PartitionDataManagementPage } from "@/components/PartitionDataManagement/PartitionDataManagementPage";
import { IntegrationResourcesPage } from "@/components/Resources/IntegrationResources.page";
import { NewResourcePage } from "@/components/Resources/NewResource.page";
import { SFTPServerUserPage } from "@/components/SFTPServers/SFTPServerUserPage";
import { SFTPServerUsersListPage } from "@/components/SFTPServers/SFTPServerUsers.page";
import SFTPServersList from "@/components/SFTPServers/SFTPServersList.component";
import { SingleSignOnAccountPage } from "@/components/SingleSignOn/SingleSignOnAccountPage";
import { expandParam } from "@/components/TableLocalFiltered/expandParam";
import { ClusterUpgradeAccountPage } from "@/components/UpgradeScheduling/ClusterUpgrade.account.page";
import { UpgradePage } from "@/components/UpgradeScheduling/Upgrade.page";
import { createLocation } from "@/modules/router";
import { UnauthorizedContent } from "@/security/authorization";
import {
    ACCOUNT_ACTIVITY_LOG_FUNC,
    ACCOUNT_DATA_LOAD_PERMISSIONS,
    ACCOUNT_DEPLOYMENT_LOG_FUNC,
    ACCOUNT_FEATURE_FLAGS_EDIT,
    ACCOUNT_FEATURE_FLAGS_READ,
    ACCOUNT_INTEGRATION_OVERVIEW_PERMISSIONS,
    ACCOUNT_LOGS_FUNC,
    ACCOUNT_PARTITION_EDIT_PERMISSIONS,
    ACCOUNT_PARTITION_PROVISIONING_PERMISSIONS,
    ACCOUNT_USER_MANAGEMENT_FUNC,
    ALERTS_PERMISSION_FUNC,
    CLUSTER_UPGRADE_PERMISSIONS,
    EO_EDIT_PERMISSION,
    EO_PERMISSIONS,
    INTEGRATION_EDIT_PERMISSIONS,
    INTEGRATION_PERMISSION_FUNC,
    INTEGRATION_READ_EDIT_PERMISSIONS,
    ISV_CONNECTION_EDIT_PERMISSION,
    PARTITION_PERMISSION_FUNC,
    PART_CALC_ENG_EDIT,
    PART_DATA_MGMT_EDIT,
    REPORTS_PERMISSION_FUNC,
} from "@/security/permission.utils";
import { sftpEntityType } from "@/services/dataLoadSftp.service";
import { NewPartitionPage } from "../../components/CustomerPartitions/NewPartition.page";
import { accountAppLocation } from "../../locations";
import { AccountApiTokenPage } from "./components/AccountApiToken/AccountApiTokenPage";
import { TABS as ACCOUNT_ASSET_ADMIN_SETTINGS_TABS } from "./components/AccountAssetAdminSettings/AccountAssetAdminSettings.component";
import { AssetAdminPage } from "./components/AccountAssetAdminSettings/AssetAdmin.page";
import { AccountDeployedPackagesPage } from "./components/AccountDeploymentPage/AccountDeployedPackagesPage";
import { TABS as ACCOUNT_PERMISSIONS_ADMIN_SETTINGS_TABS } from "./components/AccountPermissionAdminSettings/AccountPermissionsAdminSettings.component";
import { AccountReportsPageContainer } from "./components/AccountReportsPage/AccountReportsPage.container";
import { GroupAdminSettingsContainer } from "./components/AccountUserAdminSettings/GroupAdminSettings/GroupAdminSettings.container";
import { UserAdminSettings } from "./components/AccountUserAdminSettings/UserAdminSettings/UserAdminSettings";
import { AlertsPageContainer } from "./components/AlertsPage/AlertsPage.container";
import { DashboardPage } from "./components/DashboardPage/DashboardPage.component";
import { NotificationRulesPage } from "./components/NotificationRulesPage/NotificationRulesPage";
import { PartitionClonePageContainer } from "./components/PartitionClonePage/PartitionClonePage.container";
import { PartitionGeneralAdminPageContainer } from "./components/PartitionGeneralAdminPage/PartitionGeneralAdminPage.container";
import { PartitionMovePageContainer } from "./components/PartitionMovePage/PartitionMovePage.container";
import { ReportsNewPageContainer } from "./components/ReportsNewPage/ReportsNewPage.container";
import { PermissionAdminPage } from "./pages/PermissionAdminPage/PermissionAdminPage.component";

const dashboardLocation = createLocation(accountAppLocation, "dashboard", "/", {
    component: DashboardPage,
    permissionFunc: c =>
        PARTITION_PERMISSION_FUNC(c) || INTEGRATION_PERMISSION_FUNC(c),
    unauthorizedContent: UnauthorizedContent,
});

const accountAdminLocation = createLocation(
    accountAppLocation,
    "admin",
    "/admin",
    {
        permissionFunc: ACCOUNT_USER_MANAGEMENT_FUNC,
        forwardTo: "accountApp.admin.users",
    },
);

const accountUsersLocation = createLocation(
    accountAdminLocation,
    "users",
    "/users",
    {
        component: UserAdminSettings,
        permissionFunc: ACCOUNT_USER_MANAGEMENT_FUNC,
        unauthorizedContent: UnauthorizedContent,
    },
);

const accountGroupsLocation = createLocation(
    accountAdminLocation,
    "groups",
    "/groups",
    {
        component: GroupAdminSettingsContainer,
        permissionFunc: ACCOUNT_USER_MANAGEMENT_FUNC,
        unauthorizedContent: UnauthorizedContent,
    },
);

// Hidden @see https://pricefx.atlassian.net/browse/PFIM-8042
// const contactManagementLocation = createLocation(
//     accountAppLocation,
//     "contactsManagement",
//     "/contacts-management",
//     {
//         component: ContactsManagementPage,
//         permissionFunc: ACCOUNT_SETTINGS_FUNC,
//         unauthorizedContent: UnauthorizedContent,
//     },
// );

const assetsAccountAdminLocation = createLocation(
    accountAdminLocation,
    "assets",
    "/assets/:activeTab",
    {
        component: AssetAdminPage,
        permissionFunc: ACCOUNT_USER_MANAGEMENT_FUNC,
        unauthorizedContent: UnauthorizedContent,
        defaultParams: {
            activeTab: ACCOUNT_ASSET_ADMIN_SETTINGS_TABS.ASSET_ROLES,
        },
    },
);

const permissionAccountAdminLocation = createLocation(
    accountAdminLocation,
    "permissions",
    "/permissions/:activeTab",
    {
        component: PermissionAdminPage,
        permissionFunc: ACCOUNT_USER_MANAGEMENT_FUNC,
        unauthorizedContent: UnauthorizedContent,
        defaultParams: {
            activeTab: ACCOUNT_PERMISSIONS_ADMIN_SETTINGS_TABS.ASSET_ROLES,
        },
    },
);

const accountAlertsLocation = createLocation(
    accountAppLocation,
    "notifications",
    "/alerts",
    {
        component: AlertsPageContainer,
        permissionFunc: ALERTS_PERMISSION_FUNC,
        unauthorizedContent: UnauthorizedContent,
    },
);

const ssoLocation = createLocation(accountAppLocation, "sso", "/sso", {
    component: SingleSignOnAccountPage,
    permission: ["account.edit"],
    unauthorizedContent: UnauthorizedContent,
});

const accountApiTokenLocation = createLocation(
    accountAppLocation,
    "api-token",
    "/api-token",
    {
        component: AccountApiTokenPage,
        permission: ["account.token_mgmt"],
        unauthorizedContent: UnauthorizedContent,
    },
);

const accountNotificationRulesLocation = createLocation(
    accountAlertsLocation,
    "notification-rules",
    "/notification-rules",
    {
        component: NotificationRulesPage,
        permissionFunc: ALERTS_PERMISSION_FUNC,
        unauthorizedContent: UnauthorizedContent,
    },
);

const accountAlertRulesLocation = createLocation(
    accountAlertsLocation,
    "rules",
    "/rules",
    {
        component: AlertRulesAccountPage,
        permissionFunc: ALERTS_PERMISSION_FUNC,
        unauthorizedContent: UnauthorizedContent,
    },
);

const accountAlertRulesNewLocation = createLocation(
    accountAlertRulesLocation,
    "new",
    "/new",
    {
        component: NewAlertRuleAccountPage,
    },
);

const accountAlertRulesEditLocation = createLocation(
    accountAlertRulesLocation,
    "edit",
    "/:alertRuleId/edit",
    {
        component: NewAlertRuleAccountPage,
    },
);

const accountLogsLocation = createLocation(
    accountAppLocation,
    "logs",
    "/logs",
    {
        permissionFunc: ACCOUNT_LOGS_FUNC,
        forwardTo: "accountApp.logs.activityLog",
    },
);

const accountActivityLogLocation = createLocation(
    accountLogsLocation,
    "activityLog",
    "/activity-log",
    {
        component: AccountActivityLogPage,
        permissionFunc: ACCOUNT_ACTIVITY_LOG_FUNC,
        unauthorizedContent: UnauthorizedContent,
    },
);

const accountDeploymentLogLocation = createLocation(
    accountLogsLocation,
    "deploymentLogs",
    "/deployment-logs",
    {
        component: AccountDeployedPackagesPage,
        permissionFunc: ACCOUNT_DEPLOYMENT_LOG_FUNC,
        unauthorizedContent: UnauthorizedContent,
    },
);

const accountReportsLocation = createLocation(
    accountAppLocation,
    "reports",
    "/reports",
    {
        component: AccountReportsPageContainer,
        permissionFunc: REPORTS_PERMISSION_FUNC,
        unauthorizedContent: UnauthorizedContent,
    },
);

const accountReportsNewLocation = createLocation(
    accountReportsLocation,
    "new",
    "/new",
    {
        component: ReportsNewPageContainer,
    },
);

const accountReportsEditLocation = createLocation(
    accountReportsLocation,
    "edit",
    "/:reportId/edit",
    {
        component: ReportsNewPageContainer,
    },
);

const partitionsLocation = createLocation(
    accountAppLocation,
    "partitions",
    "/partitions",
    {
        permissionFunc: PARTITION_PERMISSION_FUNC,
        component: CustomerPartitionsPage,
        unauthorizedContent: UnauthorizedContent,
    },
);

const partitionsVersionHistoryLocation = createLocation(
    partitionsLocation,
    "version-history",
    "/version-history/:entityType/:entityId",
    {
        permissionFunc: PARTITION_PERMISSION_FUNC,
        component: VersionHistoryPage,
        unauthorizedContent: UnauthorizedContent,
    },
);

const partitionNewLocation = createLocation(partitionsLocation, "new", "/new", {
    component: NewPartitionPage,
    permission: [
        ...ACCOUNT_PARTITION_PROVISIONING_PERMISSIONS,
        ...ACCOUNT_PARTITION_EDIT_PERMISSIONS,
    ],
});

const partitionEditLocation = createLocation(
    partitionsLocation,
    "edit",
    "/:partitionId/edit",
    {
        component: EditPartitionPage,
        permission: [
            ...ACCOUNT_PARTITION_PROVISIONING_PERMISSIONS,
            ...ACCOUNT_PARTITION_EDIT_PERMISSIONS,
        ],
    },
);

const partitionLocation = createLocation(
    partitionsLocation,
    "partition",
    "/:partitionId",
    {
        forwardTo: "accountApp.partitions.partition.overview",
    },
);

const partitionOverviewLocation = createLocation(
    partitionLocation,
    "overview",
    "/dashboard",
    {
        component: PartitionDashboardPage,
    },
);

const partitionCloneLocation = createLocation(
    partitionLocation,
    "clone",
    "/clone",
    {
        component: PartitionClonePageContainer,
    },
);

const partitionMoveLocation = createLocation(
    partitionLocation,
    "move",
    "/move",
    {
        component: PartitionMovePageContainer,
    },
);

const partitionDataManagementLocation = createLocation(
    partitionLocation,
    "data-management",
    "/data-management?tab",
    {
        permission: ACCOUNT_DATA_LOAD_PERMISSIONS, // TODO: double check
        component: PartitionDataManagementPage,
    },
);

const partitionUploadsLocation = createLocation(
    partitionLocation,
    "uploads",
    "/uploads",
    {
        // permission: ACCOUNT_DATA_LOAD_PERMISSIONS,
        // TODO: explicit tab param? https://router5.js.org/guides/defining-routes
        forwardTo: "accountApp.partitions.partition.data-management",
    },
);

const partitionNewDownloadLocation = createLocation(
    partitionDataManagementLocation,
    "new-download",
    "/new-download",
    {
        component: NewDownloadPage,
        permission: [PART_DATA_MGMT_EDIT],
    },
);
const partitionEditDownloadLocation = createLocation(
    partitionDataManagementLocation,
    "edit-download",
    "/:downloadId/edit-download",
    {
        component: EditDownloadPage,
        permission: [PART_DATA_MGMT_EDIT],
    },
);

const partitionAvailableFilesLocation = createLocation(
    partitionDataManagementLocation,
    "available-files",
    "/:downloadId/available-files",
    {
        component: AvailableFilesPage,
    },
);

const partitionNewConnectionLocation = createLocation(
    partitionDataManagementLocation,
    "new-connection",
    "/new-connection",
    {
        component: NewPartitionConnectionPage,
    },
);

const partitionEditConnectionLocation = createLocation(
    partitionDataManagementLocation,
    "edit-connection",
    "/:targetId/edit-connection",
    {
        component: EditPartitionConnectionPage,
    },
);

// const partitionUploadLogsLocation = createLocation(
//     partitionLocation,
//     "upload-logs",
//     "/upload-logs",
//     {
//         // permission: ACCOUNT_OVERVIEW_PERMISSIONS,
//         // component: UploadLogsPage,
//         // TODO: explicit tab param? https://router5.js.org/guides/defining-routes
//         forwardTo: "accountApp.partitions.partition.data-management",
//     },
// );

const partitionCredentialsLocation = createLocation(
    partitionLocation,
    "credentials",
    "/credentials",
    {
        component: PartitionCredentialsPage,
    },
);

const partitionGeneralAdminLocation = createLocation(
    partitionLocation,
    "general-admin",
    "/general-admin",
    {
        component: PartitionGeneralAdminPageContainer,
    },
);

const partitionFeatureFlagsLocation = createLocation(
    partitionLocation,
    "featureFlags",
    "/feature-flags",
    {
        permission: [ACCOUNT_FEATURE_FLAGS_EDIT, ACCOUNT_FEATURE_FLAGS_READ],
        component: PartitionFeatureFlagsPage,
    },
);

const calculationEnginesLocation = createLocation(
    partitionLocation,
    "calculationEngines",
    "/calculation-engines",
    {
        component: CalculationEnginesPage,
        permission: [PART_CALC_ENG_EDIT],
    },
);

const calculationEngineLocation = createLocation(
    calculationEnginesLocation,
    "calculationEngine",
    "/:engineId",
    {
        component: CalculationEnginePage,
        permission: [PART_CALC_ENG_EDIT],
    },
);

const partitionNewUploadLocation = createLocation(
    partitionDataManagementLocation, // former partitionUploadsLocation
    "new-upload",
    "/new-upload",
    {
        component: NewDataUploadPage,
    },
);

const partitionUploadHistoryLocation = createLocation(
    partitionDataManagementLocation, // former partitionUploadsLocation
    "upload-history",
    "/:uploadId/upload-history?expand",
    {
        component: DataUploadHistoryPage,
        defaultParams: {
            expand: expandParam.stringify([]),
        },
    },
);

const partitionDataUploadSFTPManagementLocation = createLocation(
    partitionDataManagementLocation, // former partitionUploadsLocation
    "upload-sftp-management",
    "/:uploadId/upload-sftp-management?SFTP_ENTITY_TYPE",
    {
        component: DataUploadSFTPManagementPage,
        defaultParams: {
            SFTP_ENTITY_TYPE: sftpEntityType.DATA_UPLOAD,
        },
        permission: [PART_DATA_MGMT_EDIT],
    },
);

const partitionDataUploadSFTPManagementServerUserLocation = createLocation(
    partitionDataUploadSFTPManagementLocation,
    "user",
    "/edit/:userId",
    { component: SFTPDataLoadUserPage },
);

const partitionUploadEditLocation = createLocation(
    partitionDataManagementLocation, // former partitionUploadsLocation
    "edit-upload",
    "/:uploadId/edit-upload",
    {
        component: EditUploadPage,
    },
);

const instancesLocation = createLocation(
    accountAppLocation,
    "instances",
    "/instance",
    {
        permissionFunc: INTEGRATION_PERMISSION_FUNC,
        component: IntegrationsPage,
        unauthorizedContent: UnauthorizedContent,
    },
);

const instancesLocationWithTransport = createLocation(
    instancesLocation,
    "instancesWithTransport",
    "?checkTransportFor&mergeRequestId",
    {
        forwardTo: "accountApp.instances",
    },
);

const instanceNewLocation = createLocation(instancesLocation, "new", "/new", {
    component: NewInstancePage,
});

const instanceLocation = createLocation(
    instancesLocation,
    "instance",
    "/:instanceId",
    {
        forwardTo: "accountApp.instances.instance.overview",
    },
);

const instanceOverviewLocation = createLocation(
    instanceLocation,
    "overview",
    "/dashboard?tab&dateFrom&dateTo",
    {
        permission: ACCOUNT_INTEGRATION_OVERVIEW_PERMISSIONS,
        component: InstanceDashboardPage,
    },
);

const integrationsLocation = createLocation(
    instanceLocation,
    "integrations",
    "/integration?tab",
    {
        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
        component: IntegrationRoutesPage,
    },
);

const instanceGroupEditLocation = createLocation(
    instancesLocation,
    "edit",
    "/group/:instanceGroup/edit",
    {
        component: InstanceGroupEditPage,
    },
);

const routeDefinitionLocation = createLocation(
    integrationsLocation,
    "routeDefinition",
    "/route-definition/:routeIdOrPrn?isNonStandardRoute&key",
    {
        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
        component: RouteDefinitionPage,
    },
);

const routeDiagramLocation = createLocation(
    integrationsLocation,
    "routeDiagram",
    "/route-diagram/:routeId",
    {
        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
        component: RouteDiagramPage,
    },
);

const routeDocumentationLocation = createLocation(
    integrationsLocation,
    "routeDocumentation",
    "/route-documentation/:routeId",
    {
        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
        component: RouteDocumentationPage,
    },
);

const routeSendPayloadLocation = createLocation(
    integrationsLocation,
    "routeSendPayload",
    "/route-send-payload/:routeId",
    {
        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
        component: RouteSendPayloadPage,
    },
);

const integrationNewLocation = createLocation(
    integrationsLocation,
    "new",
    "/new",
    { component: NewRoutePage },
);

const integrationEditLocation = createLocation(
    integrationsLocation,
    "edit",
    "/:integrationId/edit",
    { component: EditRoutePage },
);

const mappersLocation = createLocation(
    instanceLocation,
    "mappers",
    "/mapper?tab",
    {
        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
        component: MappersListPage,
    },
);

const mapperNewLocation = createLocation(mappersLocation, "new", "/new", {
    component: NewMapperPage,
});

const mapperEditLocation = createLocation(
    mappersLocation,
    "edit",
    "/:mapperId/edit?kind",
    {
        component: EditMapperPage,
        defaultParams: {
            kind: MAPPER_KIND.WORKSPACE,
        },
    },
);

const mappersDefinitionLocation = createLocation(
    mappersLocation,
    "definition",
    "/definition/:mapperIdOrPrn?isNonStandardMapper",
    {
        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
        component: MapperDefinitionPage,
    },
);

const connectionsLocation = createLocation(
    instanceLocation,
    "connections",
    "/connections?tab",
    {
        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
        component: ConnectionsPage,
    },
);

const connectionsDefinitionLocation = createLocation(
    connectionsLocation,
    "definition",
    "/definition/:prn",
    {
        permission: INTEGRATION_READ_EDIT_PERMISSIONS.at,
        component: ConnectionsDefinitionPage,
    },
);

const connectionEditLocation = createLocation(
    connectionsLocation,
    "edit",
    "/:connectionId/edit",
    {
        component: EditConnectionPage,
    },
);

const connectionNewLocation = createLocation(
    connectionsLocation,
    "new",
    "/new",
    {
        component: NewConnectionPage,
    },
);

const certificatesLocation = createLocation(
    instanceLocation,
    "certificates",
    "/certificates?tab",
    {
        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
        component: CertificatesListPage,
    },
);

const classesLocation = createLocation(
    instanceLocation,
    "class",
    "/class?tab",
    {
        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
        component: ClassesListPage,
    },
);

const classesDefinitionLocation = createLocation(
    classesLocation,
    "definition",
    "/definition/:prn",
    {
        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
        component: ClassesDefinitionPage,
    },
);

const classNewLocation = createLocation(classesLocation, "new", "/new", {
    component: NewClassPage,
});

const classEditLocation = createLocation(
    classesLocation,
    "edit",
    "/:classId/edit",
    {
        component: EditClassPage,
    },
);

const resourcesLocation = createLocation(
    instanceLocation,
    "resources",
    "/resources",
    {
        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
        component: IntegrationResourcesPage,
    },
);

const resourcesNewLocation = createLocation(resourcesLocation, "new", "/new", {
    component: NewResourcePage,
    permission: INTEGRATION_EDIT_PERMISSIONS,
});

const certificateEditLocation = createLocation(
    certificatesLocation,
    "edit",
    "/:certificateId/edit",
    {
        component: EditCertificatePage,
    },
);

const certificateNewLocation = createLocation(
    certificatesLocation,
    "new",
    "/new",
    {
        component: NewCertificatePage,
    },
);

const sftpServersLocation = createLocation(
    instanceLocation,
    "sftp-servers",
    "/sftp-servers",
    {
        permission: INTEGRATION_EDIT_PERMISSIONS,
        component: SFTPServersList,
    },
);

const sftpServerUsersLocation = createLocation(
    sftpServersLocation,
    "user-management",
    "/:serverId/user-management",
    {
        // permission: INTEGRATION_EDIT_PERMISSIONS,
        component: SFTPServerUsersListPage,
    },
);

const sftpServerUserLocation = createLocation(
    sftpServerUsersLocation,
    "user",
    "/edit/:userId",
    { component: SFTPServerUserPage },
);

const filtersLocation = createLocation(
    instanceLocation,
    "filters",
    "/filters?tab",
    {
        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
        component: FiltersListPage,
    },
);

const filterNewLocation = createLocation(filtersLocation, "new", "/new", {
    component: NewFilterPage,
});

const filterEditLocation = createLocation(
    filtersLocation,
    "edit",
    "/:filterId/edit",
    {
        component: EditFilterPage,
    },
);

const filtersDefinitionLocation = createLocation(
    filtersLocation,
    "definition",
    "/definition/:prn",
    {
        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
        component: FilterDefinitionPage,
    },
);

const settingsLocation = createLocation(
    instanceLocation,
    "settings",
    "/settings?tab&step",
    {
        component: IntegrationSettingsPage,
        permission: INTEGRATION_EDIT_PERMISSIONS,
    },
);

const integratonsVersionHistoryLocation = createLocation(
    instanceLocation,
    "version-history",
    "/version-history/:entityType/:entityId",
    {
        component: VersionHistoryPage,
        permission: INTEGRATION_EDIT_PERMISSIONS,
    },
);

const upgradesLocation = createLocation(
    accountAppLocation,
    "upgrades",
    "/upgrades",
    {
        component: ClusterUpgradeAccountPage,
        permission: CLUSTER_UPGRADE_PERMISSIONS,
    },
);
const upgradesNewLocation = createLocation(upgradesLocation, "new", "/new", {
    component: UpgradePage,
    permission: CLUSTER_UPGRADE_PERMISSIONS,
});

const upgradesEditLocation = createLocation(
    upgradesLocation,
    "edit",
    "/:eventId",
    {
        component: UpgradePage,
        permission: CLUSTER_UPGRADE_PERMISSIONS,
    },
);

const isvConnectionsLocation = createLocation(
    accountAppLocation,
    "isvConnections",
    "/isv-connections",
    {
        component: ISVConnectionsListPage,
        permission: [ISV_CONNECTION_EDIT_PERMISSION],
    },
);

const isvConnectionsNewLocation = createLocation(
    isvConnectionsLocation,
    "isvConnectionsNew",
    "/new-connection",
    {
        component: ISVConnectionPage,
        permission: [ISV_CONNECTION_EDIT_PERMISSION],
    },
);

const isvDataMappingListLocation = createLocation(
    isvConnectionsLocation,
    "isvDataMappingList",
    "/:isvConnectionId/data-mapping",
    {
        component: ISVDataMappingListPage,
        permission: [ISV_CONNECTION_EDIT_PERMISSION],
    },
);

const isvDataMappingCreationLocation = createLocation(
    isvConnectionsLocation,
    "isvDataMappingCreation",
    "/:isvConnectionId/new-data-mapping?tab",
    {
        component: ISVDataMappingCreationPage,
        permission: [ISV_CONNECTION_EDIT_PERMISSION],
    },
);

const isvDataMappingEditLocation = createLocation(
    isvConnectionsLocation,
    "isvDataMappingEdit",
    "/:isvConnectionId/edit/:isvMapperId?tab",
    {
        component: ISVDataMappingEditPage,
        permission: [ISV_CONNECTION_EDIT_PERMISSION],
    },
);

const isvDataMappingUploadLocation = createLocation(
    isvDataMappingEditLocation,
    "isvDataMappingUpload",
    "/upload",
    {
        component: ISVDataMappingUploadPage,
        permission: [ISV_CONNECTION_EDIT_PERMISSION],
    },
);

const isvConnectionsOverviewLocation = createLocation(
    isvConnectionsLocation,
    "isvConnectionsOverview",
    "/:isvConnectionId/overview?tab&camelExchangeId",
    {
        component: ISVConnectionsOverviewPage,
        permission: [ISV_CONNECTION_EDIT_PERMISSION],
    },
);

const isvConnectionsSettingsLocation = createLocation(
    isvConnectionsLocation,
    "isvConnectionsSettings",
    "/:isvConnectionId/settings?tab",
    {
        component: ISVConnectionsSettingsPage,
        permission: [ISV_CONNECTION_EDIT_PERMISSION],
    },
);

const isvConnectionsSettingsEditSFTPUserLocation = createLocation(
    isvConnectionsSettingsLocation,
    "isvConnectionsEditSFTPUserSettings",
    "/edit/:userId?SFTP_ENTITY_TYPE",
    { component: IsvSftpServerUserPage },
);

const eventOrchestrationLocation = createLocation(
    accountAppLocation,
    "eventOrchestration",
    "/event-orchestration",
    {
        forwardTo: "accountApp.eventOrchestration.eventWorkflows",
        permission: EO_PERMISSIONS,
    },
);

const eventWorkflowsLocation = createLocation(
    eventOrchestrationLocation,
    "eventWorkflows",
    "/event-workflows?tab",
    {
        component: EventWorkflowsPage,
        permission: EO_PERMISSIONS,
    },
);

const eventWorkflowNewLocation = createLocation(
    eventWorkflowsLocation,
    "new",
    "/new",
    {
        component: NewEventWorkflowPage,
        permission: [EO_EDIT_PERMISSION],
    },
);

const eventWorkflowEditLocation = createLocation(
    eventWorkflowsLocation,
    "edit",
    "/edit/:wfId?wfListenerId",
    {
        component: EditEventWorkflowPage,
        permission: [EO_EDIT_PERMISSION],
        defaultParams: {
            wfListenerId: "",
        },
    },
);

const eventWorkflowShowLocation = createLocation(
    eventWorkflowsLocation,
    "show",
    "/show/:wfId?wfListenerId",
    {
        component: ShowEventWorkflowPage,
        defaultParams: {
            wfListenerId: "",
        },
    },
);

const eventSchedulersLocation = createLocation(
    eventOrchestrationLocation,
    "eventSchedulers",
    "/event-schedulers",
    {
        component: EventSchedulersPage,
        permission: EO_PERMISSIONS,
    },
);

const eventSchedulerNewLocation = createLocation(
    eventSchedulersLocation,
    "new",
    "/new",
    {
        component: NewEventSchedulerPage,
        permission: EO_EDIT_PERMISSION,
    },
);

const eventSchedulerEditLocation = createLocation(
    eventSchedulersLocation,
    "edit",
    "/edit/:schedulerId",
    {
        component: EditEventSchedulerPage,
        permission: EO_EDIT_PERMISSION,
    },
);

const eventSchedulerShowLocation = createLocation(
    eventSchedulersLocation,
    "show",
    "/show/:schedulerId",
    {
        component: ShowEventSchedulerPage,
    },
);

const eventLogsLocation = createLocation(
    eventOrchestrationLocation,
    "eventLogs",
    "/event-logs?tab&entityType&entityId",
    {
        component: EventLogsPage,
        permission: EO_PERMISSIONS,
        defaultParams: {
            tab: EO_LOGS_TABS.RUN_HISTORY,
            entityType: "",
            entityId: "",
        },
    },
);

export const accountAppLocationsMap = {
    dashboardLocation,
    accountLogsLocation,
    accountAdminLocation,
    accountUsersLocation,
    accountGroupsLocation,
    accountActivityLogLocation,
    partitionsLocation,
    partitionNewLocation,
    partitionEditLocation,
    partitionLocation,
    partitionOverviewLocation,
    partitionCloneLocation,
    partitionCredentialsLocation,
    partitionGeneralAdminLocation,
    partitionUploadsLocation,
    partitionDataManagementLocation,
    partitionNewDownloadLocation,
    partitionEditDownloadLocation,
    partitionAvailableFilesLocation,
    partitionNewConnectionLocation,
    partitionEditConnectionLocation,
    partitionMoveLocation,
    partitionNewUploadLocation,
    partitionUploadHistoryLocation,
    partitionUploadEditLocation,
    partitionFeatureFlagsLocation,
    partitionDataUploadSFTPManagementLocation,
    partitionDataUploadSFTPManagementServerUserLocation,
    calculationEnginesLocation,
    calculationEngineLocation,
    instancesLocation,
    instancesLocationWithTransport,
    instanceNewLocation,
    instanceLocation,
    instanceOverviewLocation,
    integrationsLocation,
    instanceGroupEditLocation,
    routeDefinitionLocation,
    routeDiagramLocation,
    routeDocumentationLocation,
    routeSendPayloadLocation,
    integrationNewLocation,
    integrationEditLocation,
    mappersLocation,
    mapperNewLocation,
    mapperEditLocation,
    mappersDefinitionLocation,
    connectionsLocation,
    connectionsDefinitionLocation,
    connectionEditLocation,
    connectionNewLocation,
    certificatesLocation,
    classesLocation,
    classesDefinitionLocation,
    classNewLocation,
    classEditLocation,
    resourcesLocation,
    resourcesNewLocation,
    certificateEditLocation,
    certificateNewLocation,
    filtersLocation,
    filterNewLocation,
    filterEditLocation,
    filtersDefinitionLocation,
    settingsLocation,
    accountAlertsLocation,
    accountAlertRulesLocation,
    accountAlertRulesNewLocation,
    accountAlertRulesEditLocation,
    permissionAccountAdminLocation,
    assetsAccountAdminLocation,
    accountReportsLocation,
    accountReportsNewLocation,
    accountReportsEditLocation,
    // contactManagementLocation,
    upgradesLocation,
    upgradesNewLocation,
    upgradesEditLocation,
    accountNotificationRulesLocation,
    accountDeploymentLogLocation,
    ssoLocation,
    accountApiTokenLocation,
    sftpServersLocation,
    sftpServerUsersLocation,
    sftpServerUserLocation,
    isvConnectionsLocation,
    isvConnectionsNewLocation,
    isvConnectionsOverviewLocation,
    isvConnectionsSettingsLocation,
    isvConnectionsSettingsEditSFTPUserLocation,
    isvDataMappingCreationLocation,
    isvDataMappingListLocation,
    isvDataMappingEditLocation,
    isvDataMappingUploadLocation,
    eventOrchestrationLocation,
    eventWorkflowsLocation,
    eventWorkflowNewLocation,
    eventWorkflowEditLocation,
    eventWorkflowShowLocation,
    integratonsVersionHistoryLocation,
    partitionsVersionHistoryLocation,
    eventSchedulersLocation,
    eventSchedulerNewLocation,
    eventSchedulerEditLocation,
    eventSchedulerShowLocation,
    eventLogsLocation,
};
