import { Forms } from "@/components/DesignSystem";
import { mapVendorToVendorName } from "@/components/ISVMapping/helpers";
import {
    CellPreview,
    renderExampleValue,
} from "@/components/Mappers/MapperTableWithCustomization/components/CellPreview.component";
import { DeleteRowButton } from "@/components/Mappers/MapperTableWithCustomization/components/DeleteRowButton";
import { DIRECTLY_EDITABLE_INPUT_TYPES } from "@/components/Mappers/MapperTableWithCustomization/components/MapperCascaderInput.component";
import { MapperCascaderWithType } from "@/components/Mappers/MapperTableWithCustomization/components/MapperCascaderWithType.component";
import { MapperConverter } from "@/components/Mappers/MapperTableWithCustomization/components/MapperConverter.component";
import { ROW_ENTITIES } from "@/components/Mappers/MapperTableWithCustomization/MapperTableWithCustomization";
import { convertToNumbersForFormula } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageMandatoryFields/PackageMandatoryFields.utils";
import { InputType } from "@/components/Packages/PackageTableDefinitionPanel/constants";
import { logger } from "@/modules/logger";
import { t } from "@/translations";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import find from "lodash/find";
import React from "react";

const errorMessage = (errors, recordId, property) => {
    const error = find(errors, { id: recordId });
    return error?.[property] ?? "";
};

const { Fields } = Forms;

const getExampleValue = tableExampleDataMap => input => {
    const from = "originalValues";
    return tableExampleDataMap.get(input)?.[from]?.[0];
};
export const getExampleResult = tableExampleDataMap => () => {
    const from = "originalValues";
    const result = Array.from(tableExampleDataMap.values()).reduce(
        (result, cell) => ({
            ...result,
            [cell.name]: cell?.[from]?.[0],
        }),
        {},
    );
    logger.debug({
        logGroupKey: ["DATALOAD", "getExampleResult"],
        color: "orchid",
        data: {
            result,
            from,
            tableExampleDataMap,
        },
    });

    return result;
};

const isInputExampleAvailable = mapperItem =>
    [InputType.BODY, InputType.COMPOSED].includes(mapperItem.inputType);
export const MapperRow = ({
    id,
    vendors,
    order,
    errors,
    readOnly,
    tableExampleData,
    tableExampleDataMap,
    inputOptionsWithTypes,
    onConverterClick,
    onChange,
    onDelete,
    isDetailVisible,
    isDeleteDisabled,
    outputOptionsWithTypes,
    converterProps,
    onTypeChange,
    onColumnTypeChange,
    direction,
    dirtyComparator,
}) => {
    const inputType = useFieldValue(
        { name: `${id}_${ROW_ENTITIES.inputType}` },
        [id],
    );
    const inputErrors =
        errorMessage(errors, id, "input") ||
        errorMessage(errors, id, "inputType");
    const outputErrors =
        errorMessage(errors, `${id}`, "output") ||
        errorMessage(errors, id, "outputType");

    const isSameVendor = vendors[0] === vendors[1];

    return (
        <Forms.FieldGrid.Row key={id}>
            <Forms.UIField
                required
                label={t(
                    isSameVendor
                        ? "data-upload.mapper.import.label"
                        : "data-upload.mapper.vendor.label",
                    {
                        vendor: mapVendorToVendorName(vendors[0]),
                    },
                )}
            >
                <>
                    <MapperCascaderWithType
                        id={id}
                        name={`${id}_${ROW_ENTITIES.input}`}
                        disabled={
                            readOnly ||
                            !DIRECTLY_EDITABLE_INPUT_TYPES.includes(inputType)
                        }
                        error={inputErrors}
                        optionsWithTypes={inputOptionsWithTypes}
                        visibleExample={isDetailVisible}
                        onChange={onChange}
                        onColumnTypeChange={onColumnTypeChange}
                        converterProps={converterProps}
                        dirtyComparator={dirtyComparator}
                    >
                        {({ mapperItem, columnType }) => {
                            const canRenderChildren =
                                isDetailVisible &&
                                isInputExampleAvailable(mapperItem);
                            if (!canRenderChildren) return null;

                            const exampleValue =
                                getExampleValue(tableExampleDataMap);

                            return (
                                <div className="pmMapperTableWithCustomization-cell pmMapperTableWithCustomization-detail">
                                    {renderExampleValue(
                                        mapperItem,
                                        exampleValue,
                                    )}
                                </div>
                            );
                        }}
                    </MapperCascaderWithType>
                    <Fields.Input
                        hidden
                        type="hidden"
                        name={`${id}_${ROW_ENTITIES.id}`}
                        initialValue={id}
                        dirtyComparator={dirtyComparator}
                    />
                    <Fields.Input
                        hidden
                        type="hidden"
                        name={`${id}_${ROW_ENTITIES.inputType}`}
                        initialValue={InputType.BODY}
                        dirtyComparator={dirtyComparator}
                    />
                    <Fields.Input
                        hidden
                        type="hidden"
                        name={`${id}_${ROW_ENTITIES.converter}`}
                        dirtyComparator={dirtyComparator}
                    />
                    <Fields.Input
                        hidden
                        type="hidden"
                        name={`${id}_${ROW_ENTITIES.outputType}`}
                        dirtyComparator={dirtyComparator}
                    />
                    <Fields.Input
                        hidden
                        type="hidden"
                        name={`${id}_${ROW_ENTITIES.converterExpression}`}
                        dirtyComparator={dirtyComparator}
                    />
                    <Fields.Input
                        hidden
                        type="hidden"
                        name={`${id}_${ROW_ENTITIES.formulaMapping}`}
                    />
                </>
            </Forms.UIField>
            <Forms.UIField
                label={t("data-upload.mapper.conversion.label")}
                name={`${id}_separator`}
            >
                <MapperConverter
                    id={id}
                    onClick={() => onConverterClick(id)}
                    isDetailVisible={isDetailVisible}
                />
            </Forms.UIField>
            <Forms.UIField
                required
                label={t(
                    isSameVendor
                        ? "data-upload.mapper.export.label"
                        : "data-upload.mapper.vendor.label",
                    {
                        vendor: mapVendorToVendorName(vendors[1]),
                    },
                )}
            >
                <MapperCascaderWithType
                    dataTest={`output-${order}`}
                    id={id}
                    name={`${id}_${ROW_ENTITIES.output}`}
                    disabled={readOnly}
                    error={outputErrors}
                    optionsWithTypes={outputOptionsWithTypes}
                    onChange={onChange}
                    onTypeChange={onTypeChange}
                    converterProps={converterProps}
                    dirtyComparator={dirtyComparator}
                >
                    {({ mapperItem, columnType }) => {
                        if (!isDetailVisible) return null;

                        const formulaMapping =
                            mapperItem.inputType === InputType.FORMULA
                                ? convertToNumbersForFormula(tableExampleData)
                                : undefined;
                        const input =
                            mapperItem.inputType !== InputType.COMPOSED
                                ? mapperItem.input?.[0]
                                : mapperItem.input;
                        const getExampleRow =
                            getExampleResult(tableExampleDataMap);

                        return (
                            <div className="pmMapperTableWithCustomization-cell pmMapperTableWithCustomization-detail">
                                <CellPreview
                                    mapperItem={{
                                        ...mapperItem,
                                        formulaMapping,
                                        input,
                                        output: mapperItem.output?.[0],
                                    }}
                                    getExampleRow={getExampleRow}
                                    parserConfig={converterProps.parserConfig}
                                    direction={direction}
                                />
                            </div>
                        );
                    }}
                </MapperCascaderWithType>
            </Forms.UIField>
            <Forms.UIField label=" " name={`${id}_remove`}>
                <DeleteRowButton
                    disabled={isDeleteDisabled}
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onDelete(id);
                    }}
                />
            </Forms.UIField>
        </Forms.FieldGrid.Row>
    );
};
