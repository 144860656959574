import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Forms } from "@/components/DesignSystem";
import { t } from "@/translations";
import React, { useCallback } from "react";

// domain-model/src/main/java/net/pricefx/pm/model/entity/IntegrationManagerInstance.java
const DIRECTION_OPTIONS = [
    { value: "INBOUND", label: "INBOUND" },
    { value: "OUTBOUND", label: "OUTBOUND" },
    { value: "INBOUND_OUTBOUND", label: "INBOUND_OUTBOUND" },
    { value: "OTHER", label: "OTHER" },
];

const TEMPLATES_USAGE_OPTIONS = [
    { value: "TEMPLATES_ONLY", label: "TEMPLATES_ONLY" },
    {
        value: "TEMPLATES_AS_STARTING_POINT",
        label: "TEMPLATES_AS_STARTING_POINT",
    },
    { value: "NO_TEMPLATES", label: "NO_TEMPLATES" },
];

export const IntegrationArchitectureFields = ({
    name = "integrationArchitecture",
    initialValues = {},
}) => {
    const getName = useCallback(sub => `${name}.${sub}`, [name]);
    const updatedAt =
        Forms.useFieldValue({
            formId: Forms.useFormId(),
            name: getName("architectureUpdatedAt"),
        }) || initialValues?.architectureUpdatedAt;
    const updatedBy =
        Forms.useFieldValue({
            formId: Forms.useFormId(),
            name: getName("architectureUpdatedBy"),
        }) || initialValues?.architectureUpdatedBy;

    return (
        <>
            <Forms.Fields.Input
                required
                name={getName("system")}
                label={t("instance-form.label.system")}
                tooltip={t("instance-form.tooltip.system")}
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                ])}
                initialValue={initialValues?.system}
            />
            <Forms.Fields.Select
                required
                name={getName("direction")}
                label={t("instance-form.label.direction")}
                tooltip={t("instance-form.tooltip.direction")}
                validator={Forms.pmValidators.isRequired}
                options={DIRECTION_OPTIONS}
                initialValue={initialValues?.direction}
            />
            <Forms.Fields.Input
                required
                name={getName("description")}
                label={t("instance-form.label.description")}
                tooltip={t("instance-form.tooltip.description")}
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                ])}
                initialValue={initialValues?.description}
            />
            <Forms.Fields.Input
                required
                name={getName("technology")}
                label={t("instance-form.label.technology")}
                tooltip={t("instance-form.tooltip.technology")}
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                ])}
                initialValue={initialValues?.technology}
            />

            <Forms.Fields.Select
                required
                name={getName("templatesUsage")}
                label={t("instance-form.label.templatesUsage")}
                validator={Forms.pmValidators.isRequired}
                options={TEMPLATES_USAGE_OPTIONS}
                initialValue={initialValues?.templatesUsage}
            />
            {(updatedAt || updatedBy) && (
                <Forms.UIField
                    name="lastChange"
                    label={t("instance-form.label.lastChange")}
                >
                    <div
                        style={{ marginTop: "10px" }}
                        title={updatedAt ? `${updatedAt} (UTC)` : undefined}
                    >
                        <>
                            {updatedAt ? (
                                <FormattedDateTime fromNow utcStr={updatedAt} />
                            ) : null}
                            {updatedBy ? <span> by {updatedBy}</span> : null}
                        </>
                    </div>
                </Forms.UIField>
            )}
        </>
    );
};
