import { Select } from "@/components/DesignSystem";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useRouteAccountIsvConnections } from "@/mixpanel/hooks/useRouteAccountIsvConnections.hook";
import { useMapLoadableMemoized } from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

const mapConnectionsToOptions = partitions =>
    partitions?.map?.(connection => ({
        label: `${connection.name}`,
        value: connection.id,
    })) ?? [];

export const ISVConnectionsSelect = ({ isvConnectionId, ...props }) => {
    const { accountIsvConnectionsLoadable } = useRouteAccountIsvConnections();

    const connectionsOptionsLoadable = useMapLoadableMemoized(
        accountIsvConnectionsLoadable,
        mapConnectionsToOptions,
    );

    return (
        <Select
            showSearch
            allowClear={false}
            value={isvConnectionId}
            dataTest="isv-connections"
            {...getLoadableSelectProps(connectionsOptionsLoadable)}
            {...props}
        />
    );
};

ISVConnectionsSelect.propTypes = {
    accountId: PropTypes.number,
    isvConnectionId: PropTypes.number,
    isvConnectionOptionsLoadable: PropTypes.object.isRequired,
};
