import { useDic } from "@/components/Dic/useDic.hook";
import { defaultParserConfigValues } from "@/components/FileParsing/FileParsingFields";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import {
    mapBasicFormValues,
    mapToInitValues,
} from "@/components/ISVMapping/helpers";
import ISVDataMappingForm from "@/components/ISVMapping/ISVDataMappingForm.component";
import {
    useEditMapperMutation,
    useMapper,
} from "@/components/ISVMapping/loadables";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";

export const mapMapperToInitValues = connection => {
    const { setting, ...rest } = connection;
    return {
        ...setting,
        ...rest,
    };
};

const ISVDataMappingEditPage = () => {
    const { accountId, isvConnectionId, isvMapperId } = useAccountAppParams();
    useFullWidthTableLayout();

    const {
        locationRouterService,
        accountAppLocations: { isvDataMappingListLocation },
    } = useDic();

    const navigateToList = useCallback(() => {
        locationRouterService.navigate(isvDataMappingListLocation, {
            accountId,
            isvConnectionId,
        });
    }, [
        locationRouterService,
        isvDataMappingListLocation,
        accountId,
        isvConnectionId,
    ]);

    const editMapper = useEditMapperMutation({
        isvMapperId,
        afterSuccess: navigateToList,
    });

    const mapperResource = useMapper({ isvMapperId });

    return (
        <LoadableRenderer
            loadable={mapperResource.loadable}
            hasValue={initialValues => (
                <ISVDataMappingForm
                    title={t(
                        "location.accountApp.isvConnections.isvDataMappingEdit",
                    )}
                    isEdit
                    accountId={accountId}
                    initialValues={{
                        ...defaultParserConfigValues,
                        ...mapToInitValues(initialValues),
                    }}
                    onSave={values =>
                        editMapper.mutate(mapBasicFormValues(values))
                    }
                    onCancel={navigateToList}
                />
            )}
        />
    );
};

export default ISVDataMappingEditPage;
