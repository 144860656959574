export class ProjectPartitionApiService {
    baseUrl = "/api/projects";
    /**
     *
     * @type {HttpService}
     */
    httpService = null;

    constructor({ httpService }) {
        this.httpService = httpService;
    }

    /**
     *  @param {number} accountId
     *   @param {number} partitionId
     */
    testPartitionAvailability(accountId, partitionId) {
        return this.httpService.post(
            `${this.baseUrl}/${accountId}/partition-assets/${partitionId}/test-autofix`,
        );
    }

    /**
     *  @param {number} accountId
     *   @param {number} partitionId
     *   @param {string} username
     *   @param {string} password
     */
    testLoginInfo({
        accountId,
        partitionId,
        username,
        password,
        type,
        checkUserRights,
    }) {
        return this.httpService.post(
            `${this.baseUrl}/${accountId}/partition-assets/${partitionId}/test`,
            {
                username,
                password,
                type,
                checkUserRights,
            },
        );
    }

    testPartitionCredentialsNopassword(accountId, partitionId) {
        return this.httpService.post(
            `${this.baseUrl}/${accountId}/partition-assets/${partitionId}/test-nopassword`,
        );
    }

    testPartitionRights(accountId, partitionId, username) {
        return this.httpService.post(
            `${this.baseUrl}/${accountId}/partition-assets/${partitionId}/test-partition-rights`,
            { username: username },
        );
    }

    getPartitions(accountId) {
        return this.httpService.get(
            `${this.baseUrl}/${accountId}/partition-assets`,
        );
    }

    getPartitionsForDataLoadCopy(accountId) {
        return this.httpService.get(
            `${this.baseUrl}/${accountId}/partition-assets/data-load-copy`,
        );
    }

    saveCredentials(projectId, partitionId, data) {
        return this.httpService.post(
            `/api/projects/${projectId}/partition-assets/${partitionId}/login-info`,
            data,
        );
    }

    getJobTrackingRecords(
        accountId,
        partitionId,
        page,
        size,
        sort = "createDate,desc",
        filter,
    ) {
        return this.httpService.post(
            `${this.baseUrl}/${accountId}/partition-assets/${partitionId}/job-tracking-records?page=${page}&size=${size}&sort=${sort}`,
            filter,
        );
    }

    getTrackerTypes(accountId, partitionId) {
        return this.httpService.get(
            `${this.baseUrl}/${accountId}/partition-assets/${partitionId}/job-tracking-records/tracker-types`,
        );
    }

    getEventAdminRecords(
        accountId,
        partitionId,
        page,
        size,
        sort = "createDate,desc",
        filter,
    ) {
        return this.httpService.post(
            `${this.baseUrl}/${accountId}/partition-assets/${partitionId}/event-admin-records?page=${page}&size=${size}&sort=${sort}`,
            filter,
        );
    }

    rerunEventAdminRecord(accountId, partitionId, eventAdminRecord) {
        return this.httpService.post(
            `${this.baseUrl}/${accountId}/partition-assets/${partitionId}/event-admin-records/rerun`,
            eventAdminRecord,
        );
    }

    fetchPartitionPageable(accountId, page, size, sort, filter) {
        return this.httpService.post(
            sort
                ? `${this.baseUrl}/${accountId}/partition-assets/pageable?page=${page}&size=${size}&sort=${sort}`
                : `${this.baseUrl}/${accountId}/partition-assets/pageable?page=${page}&size=${size}`,
            filter,
        );
    }

    fetchRequestTicketUrl(accountId, partitionId, type) {
        return this.httpService.get(
            `${this.baseUrl}/${accountId}/partition-assets/${partitionId}/request-tickets/${type}`,
        );
    }

    fastUpload(accountId, partitionId, uploadedFileId, value) {
        return this.httpService.post(
            `${this.baseUrl}/${accountId}/partitions/${partitionId}/files/${uploadedFileId}/fast-upload`,
            value,
        );
    }

    eventTypes(accountId, partitionId) {
        return this.httpService.get(
            `${this.baseUrl}/${accountId}/partition-assets/${partitionId}/event-types`,
        );
    }
}
