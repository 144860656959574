import { CalculationEngineForm } from "@/components/CalculationEngines/CalculationEngineForm";
import {
    useCalculationEnginePresetsQuery,
    useCalculationEngineQuery,
    useSubmitCalculationEngineMutation,
} from "@/components/CalculationEngines/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import {
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { pipe } from "lodash/fp";
import React, { useCallback } from "react";

const fixMemoryLimitTo = initialValues =>
    !initialValues
        ? initialValues
        : {
              ...initialValues,
              memoryLimit:
                  initialValues.memoryLimit?.value ?? initialValues.memoryLimit,
          };
const fixMemoryLimitFrom = ({
    memoryLimit,
    memoryUnit,
    cpuLimit,
    supportLimits,
    ...engine
}) => ({
    ...engine,
    cpuLimit: supportLimits ? cpuLimit : undefined,
    memoryLimit: supportLimits ? `${memoryLimit}${memoryUnit}` : undefined,
});

export function CalculationEnginePage() {
    const {
        locationRouterService,
        accountAppLocations: { calculationEnginesLocation },
    } = useDic();
    const { partitionId, engineId } = useAccountAppParams();
    const isNew = engineId === "new";

    const onCancel = useCallback(
        () => locationRouterService.navigate(calculationEnginesLocation),
        [calculationEnginesLocation, locationRouterService],
    );
    const submitMutation = useSubmitCalculationEngineMutation({
        partitionId,
        afterSuccess: onCancel,
    });

    useSetPageTitle(
        t(
            isNew
                ? "calculation-engine.page-new-title"
                : "calculation-engine.page-edit-title",
        ),
    );
    useSetBackButtonAction(onCancel);

    const initialValuesQuery = useCalculationEngineQuery({
        partitionId,
        engineId,
        isNew,
    });
    const presetsQuery = useCalculationEnginePresetsQuery({ partitionId });

    return (
        <LoadableRenderer
            loadable={useComposeLoadablesMemoized([
                initialValuesQuery.loadable,
                presetsQuery.loadable,
            ])}
            hasValue={([initialValues, presets]) => (
                <CalculationEngineForm
                    initialValues={fixMemoryLimitTo(initialValues)}
                    presets={presets}
                    onSubmit={pipe(fixMemoryLimitFrom, submitMutation.mutate)}
                    onCancel={onCancel}
                    isNew={isNew}
                    partitionId={partitionId}
                />
            )}
        />
    );
}
