import {
    ButtonMenu,
    Forms,
    Gap,
    Link,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import {
    LoadableRenderer,
    isLoading,
    pendingPromise,
    useQueryLoadable,
} from "@/modules/loadable";
import { t } from "@/translations";
import { naturallySortOptions } from "@/utils/form.utils";
import { map, pipe } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { KAFKA_CLUSTER_OPTIONS, mqClusterFrom } from "./constants";
import {
    useEventConfiguration,
    usePostEventConfigurationResource,
} from "./loadables";

const {
    pmValidators: { emailValid, isRequired, orEmpty },
    useForm,
} = Forms;

const useEventTypeOptionsQuery = ({ accountId, partitionId, canFetch }) => {
    const { projectPartitionApiService } = useDic();
    return useQueryLoadable(
        async () =>
            canFetch
                ? projectPartitionApiService
                      .eventTypes(accountId, partitionId)
                      .then(
                          pipe(
                              map(value => ({ label: value, value })),
                              naturallySortOptions,
                          ),
                      )
                : pendingPromise(),
        [accountId, canFetch, partitionId, projectPartitionApiService],
    );
};

export const EventConfiguratorModal = ({
    visible,
    onClose,
    afterSave,
    projectId,
    partitionId,
}) => {
    const { formId, handleSubmit, setValues, setTouched } = useForm({
        onSubmit: ({ values }) => {
            return postEventConfigurationResource.mutate({
                ...values,
                mqCluster: mqClusterFrom(values.mqCluster),
            });
        },
    });

    const { loadableEventConfiguration, reload } = useEventConfiguration({
        projectId,
        partitionId,
        setValues,
        setTouched,
    });

    const afterSuccess = useCallback(() => {
        afterSave();
        reload();
    }, [afterSave, reload]);

    const postEventConfigurationResource = usePostEventConfigurationResource({
        projectId,
        partitionId,
        afterSuccess,
    });

    const eventTypeOptionsQuery = useEventTypeOptionsQuery({
        accountId: projectId,
        partitionId,
        canFetch: !isNaN(projectId) && !isNaN(partitionId) && visible,
    });

    const submitDisabled = isLoading(postEventConfigurationResource);

    return (
        <Modal visible={visible} onClose={onClose} destroyOnClose={false}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("partition.event-configurator.title")}
                />
                <LoadableRenderer
                    loadable={loadableEventConfiguration}
                    hasValue={() => (
                        <>
                            <UnityLayout.Content padding={[true, true]}>
                                <Forms.Form
                                    formId={formId}
                                    onSubmit={handleSubmit}
                                >
                                    <Forms.FieldGroup
                                        width="max"
                                        inputWidth="max"
                                    >
                                        <Forms.Fields.Select
                                            required
                                            name="eventTypes"
                                            label={t(
                                                "partition.event-configurator.form.event-bitmask",
                                            )}
                                            placeholder={t("general.select")}
                                            allowClear={false}
                                            mode="multiple"
                                            showSearch
                                            validator={isRequired}
                                            {...getLoadableSelectProps(
                                                eventTypeOptionsQuery.loadable,
                                            )}
                                        />
                                        <Forms.Fields.Select
                                            name="mqCluster"
                                            label={t(
                                                "partition.event-configurator.form.event-mq",
                                            )}
                                            placeholder={t("general.select")}
                                            allowClear={false}
                                            options={KAFKA_CLUSTER_OPTIONS}
                                        />
                                        <Forms.Fields.Input
                                            name="eventUrl"
                                            label={t(
                                                "partition.event-configurator.form.event-url",
                                            )}
                                            placeholder={t(
                                                "partition.event-configurator.form.event-url.placeholder",
                                            )}
                                        />
                                        <Forms.Fields.Checkbox
                                            name="disableEventsProcessing"
                                            label={t(
                                                "partition.event-configurator.form.disable-event-processing",
                                            )}
                                        />
                                        <Forms.Fields.Input
                                            name="eventEmail"
                                            label={t(
                                                "partition.event-configurator.form.email",
                                            )}
                                            placeholder={t(
                                                "partition.event-configurator.form.email.placeholder",
                                            )}
                                            validator={orEmpty(emailValid)}
                                        />
                                    </Forms.FieldGroup>
                                </Forms.Form>
                                <Gap />
                                <Link
                                    href="https://pricefx.atlassian.net/wiki/display/UNITY/Server+Events"
                                    targetBlank
                                    displayIcon="always"
                                >
                                    {t(
                                        "partition.event-configurator.server-events-documentation",
                                    )}
                                </Link>
                            </UnityLayout.Content>
                            <UnityLayout.Footer
                                actionButtons={
                                    <ButtonMenu
                                        buttons={[
                                            {
                                                label: t("general.save"),
                                                type: "primary",
                                                formId,
                                                htmlType: "button",
                                                disabled: submitDisabled,
                                            },
                                            {
                                                label: t("general.cancel"),
                                                onClick: onClose,
                                                type: "text",
                                            },
                                        ]}
                                    />
                                }
                            />
                        </>
                    )}
                />
            </UnityLayout>
        </Modal>
    );
};

EventConfiguratorModal.propTypes = {
    afterSave: PropTypes.func,
    onClose: PropTypes.func,
    partitionId: PropTypes.number,
    projectId: PropTypes.number,
    visible: PropTypes.bool,
};
