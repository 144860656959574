import {
    FooterButtonsContext,
    FooterButtonsProvider,
    useFooterButtons,
} from "@/components/DataUploads/Wizard/NewTable/components/FooterButtonsContext";
// import { UCLayoutWithSteps, UCLayoutWithStepsInner, } from "@/components/DataUploads/Wizard/NewTable/components/UCLayoutWithSteps";
import {
    Alert,
    ButtonMenu,
    Forms,
    Tabs,
    UnityLayout,
    UnitySteps,
} from "@/components/DesignSystem";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import RemoteLayout from "@/components/PageLayout/RemoteLayout";
import { useConfirmModal } from "@/modules/modal";
import { t } from "@/translations";
import { map, noop, pick } from "lodash/fp";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";

const useStepperProps = ({ steps, initialStepKey = steps?.[0]?.key }) => {
    if (!steps?.length) throw new Error("Steps are not provided");
    if (!initialStepKey) throw new Error("Initial step key is not provided");

    const [currentStepKey, setCurrentStepKey] = useState(initialStepKey);
    const currentStep = steps.find(({ key }) => key === currentStepKey);

    if (!currentStep)
        throw new Error(`Step not found for key: ${currentStepKey}`);

    const currentStepIndex = steps.findIndex(
        step => step.key === currentStepKey,
    );

    const nextStep = useCurrentHandler(() => {
        const nextStepKey = steps[currentStepIndex + 1]?.key;
        if (nextStepKey) return setCurrentStepKey(nextStepKey);
    });
    const prevStep = useCurrentHandler(() => {
        const prevStepKey = steps[currentStepIndex - 1]?.key;
        if (prevStepKey) return setCurrentStepKey(prevStepKey);
    });
    const goToStep = useCurrentHandler(({ key }) => {
        if (!key) throw new Error("Key is not provided");
        else setCurrentStepKey(key);
    });

    return useMemo(
        () => ({
            steps,
            currentStep,
            currentStepIndex,
            nextStep,
            prevStep,
            isFirstStep: currentStepIndex === 0,
            isLastStep: currentStepIndex === steps.length - 1,
            goToStep,
        }),
        [currentStep, currentStepIndex, goToStep, nextStep, prevStep, steps],
    );
};

export const useSteppedForm = ({
    stepperProps,
    stepFormProps: {
        initialValues, // TODO: treat them as reactive in tab variant?
        reactiveInitialValues, // on tab change needs to be saved and reinitialized
        allValues, // when rendered as steps, values are collected here
        setAllValues,
        onSubmit,
        getStepButtons,
    },
    isTabVariant,
    renderButtons,
}) => {
    const form = Forms.useForm({
        // Initial effect run discarded, needs to have initialValues
        // initialValues: isTabVariant ? {} : allValues,
        initialValues: isTabVariant ? reactiveInitialValues : allValues,
        onSubmit: ({ values, args: [action] }) => {
            let newValues;
            if (isTabVariant) {
                newValues = values;
            } else {
                newValues = { ...allValues, ...values };
                setAllValues(newValues);
            }

            if (typeof action === "function") {
                return action({ values: newValues });
            } else if (stepperProps.isLastStep || isTabVariant) {
                return onSubmit({
                    values: newValues,
                });
            } else {
                stepperProps.nextStep();
            }
        },
    });
    const { formId, submit, setInitialValues } = form;
    const setInitialValuesStatic = useCurrentHandler(setInitialValues);
    useEffect(() => {
        if (reactiveInitialValues && isTabVariant) {
            // Initially missed, lost in Forms - initialValues used instead
            console.log("%c[useSteppedForm.init eff]", "color:lime", {
                isTabVariant,
                reactiveInitialValues,
            });
            setInitialValuesStatic(reactiveInitialValues);
        }
    }, [isTabVariant, reactiveInitialValues, setInitialValuesStatic]);
    // console.log("[useSteppedForm.rndr]", { isTabVariant, form, reactiveInitialValues, allValues, });

    const buttons = useMemo(
        () =>
            getStepButtons({
                stepperProps,
                formId,
                submit,
                isTabVariant,
            }),
        [formId, getStepButtons, isTabVariant, stepperProps, submit],
    );
    useFooterButtons(renderButtons ? buttons : []); // TODO: wrap one lvl up?

    return form;
};

export const withForm = Component => props => {
    const { stepperProps, stepFormProps } = props;
    const { Form, ...form } = useSteppedForm({
        stepperProps,
        stepFormProps,
        isTabVariant: false,
        renderButtons: true,
    });

    return (
        <Form disableBanner>
            <Component {...props} form={form} />
        </Form>
    );
};

const ControlledTabPaneFix = ({ title, tab, onClick }) => {
    return (
        <div
            style={{
                marginTop: "-12px",
                marginBottom: "-12px",
                marginLeft: "-8px",
                marginRight: "-8px",
                paddingTop: "12px",
                paddingBottom: "12px",
                paddingLeft: "8px",
                paddingRight: "8px",
            }}
            onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onClick(tab);
            }}
        >
            {title}
        </div>
    );
};

const StepFormInner = ({
    headerSize = 3,
    actionButtons,
    stepperProps,
    isTabVariant,
    alert,
    title,
    onClickBack,
    alignButtons = "right",
    stretch,
    isRemote = false,
    allStepsProps,
    stepFormProps,
}) => {
    const {
        steps,
        goToStep,
        currentStepIndex, // TODO: currentStepKey
        currentStep: {
            Component,
            padding = [true, true],
            stepProps,
            key: currentStepKey,
        },
    } = stepperProps;
    const StepWithForm = useMemo(() => withForm(Component), [Component]);
    const headerSteps = useMemo(
        () => map(pick(["title", "key"]), steps),
        [steps],
    );
    const { Form, ...form } = useSteppedForm({
        stepperProps,
        stepFormProps,
        isTabVariant: true,
        renderButtons: isTabVariant,
    });
    const renderContent = () => {
        if (isTabVariant)
            return (
                <Form disableBanner>
                    {steps.map(({ Component, stepProps, key }) => {
                        return (
                            <div
                                key={key}
                                style={{
                                    display:
                                        key === currentStepKey
                                            ? "block"
                                            : "none",
                                }}
                            >
                                <Component
                                    stepProps={stepProps}
                                    allStepsProps={allStepsProps}
                                    stepperProps={stepperProps}
                                    stepFormProps={stepFormProps}
                                    form={form}
                                />
                            </div>
                        );
                    })}
                </Form>
            );

        return (
            <StepWithForm
                stepProps={stepProps}
                allStepsProps={allStepsProps}
                stepperProps={stepperProps}
                stepFormProps={stepFormProps}
            />
        );
    };
    const { buttons } = useContext(FooterButtonsContext);
    const WrappedLayout = isRemote ? RemoteLayout : React.Fragment;
    const confirmModal = useConfirmModal();
    const onChangeTab = useCallback(
        async key => {
            const { getBag, submit } = form;
            const {
                dirty,
                values,
                validation: { isValid },
            } = await getBag();

            if (!dirty) {
                stepFormProps?.onTabChange?.(key);
                goToStep({ key });
            } else {
                const confirmed = await confirmModal.confirm({
                    title: "Unsaved changes",
                    message: "Do you want to save unsaved changes?",
                    okText: "Save", // TODO
                    cancelText: t("general.cancel"),
                    // onConfirm: () => { console.log("OK") },
                });
                if (confirmed) {
                    stepFormProps?.onTabChange?.(key);
                    // debugger;
                    await submit(() =>
                        stepFormProps.onTabChangeSave({ values }),
                    );
                    if (isValid) {
                        goToStep({ key });
                    }
                }
            }
        },
        [confirmModal, form, goToStep, stepFormProps],
    );

    const defaultTabsProps = {
        activeKey: currentStepKey,
        onChange: tab => {
            // never called, cannot prevent tab transition
            throw new Error("Should be never called");
        },
        fullSize: true,
        splitLayout: true,
    };

    return (
        <WrappedLayout>
            <UnityLayout>
                <UnityLayout.Header
                    size={headerSize}
                    title={title}
                    alert={alert && <Alert {...alert} />}
                    onClickBack={onClickBack}
                    actionButtons={
                        !actionButtons ? undefined : (
                            <ButtonMenu buttons={actionButtons} />
                        )
                    }
                    steps={
                        !isTabVariant && (
                            <UnitySteps
                                current={currentStepIndex}
                                steps={headerSteps}
                            />
                        )
                    }
                    tabs={
                        isTabVariant && (
                            <Tabs destroyInactiveTabPane {...defaultTabsProps}>
                                {headerSteps.map(({ title, key }) => (
                                    <Tabs.TabPane
                                        key={key}
                                        tab={
                                            <ControlledTabPaneFix
                                                title={title}
                                                tab={key}
                                                onClick={onChangeTab}
                                            />
                                        }
                                    />
                                ))}
                            </Tabs>
                        )
                    }
                />

                <UnityLayout.Content padding={padding} stretch={stretch}>
                    {renderContent()}
                </UnityLayout.Content>
                {buttons?.length && (
                    <UnityLayout.Footer
                        {...(alignButtons === "left"
                            ? {
                                  actionButtonsLeft: (
                                      <ButtonMenu buttons={buttons} />
                                  ),
                              }
                            : {
                                  actionButtons: (
                                      <ButtonMenu buttons={buttons} />
                                  ),
                              })}
                    />
                )}
            </UnityLayout>
        </WrappedLayout>
    );
};

const GET_STEP_BUTTONS = ({ stepperProps, formId }) => [];

export const StepForm = ({
    title,
    headerSize,
    reactiveInitialValues,
    initialValues = reactiveInitialValues,
    onSubmit = noop,
    onTabChangeSave = onSubmit,
    onTabChange,
    onCancel,
    allStepsProps,
    steps,
    getStepButtons = GET_STEP_BUTTONS,
    allValues: allValuesProp,
    setAllValues: setAllValuesProp,
    isTabVariant,
    initialStepIndex,
    defaultActiveTab,
    actionButtons,
}) => {
    const [_allValues, _setAllValues] = useState(initialValues || {});
    const [allValues, setAllValues] = setAllValuesProp
        ? [allValuesProp, setAllValuesProp]
        : [_allValues, _setAllValues];
    const stepperProps = useStepperProps({
        steps,
        initialStepKey: defaultActiveTab,
    });
    const stepFormProps = useMemo(
        () => ({
            initialValues,
            reactiveInitialValues,
            allValues,
            setAllValues,
            onSubmit,
            onTabChangeSave,
            onTabChange,
            onCancel,
            getStepButtons,
        }),
        [
            initialValues,
            reactiveInitialValues,
            allValues,
            setAllValues,
            onSubmit,
            onTabChangeSave,
            onTabChange,
            onCancel,
            getStepButtons,
        ],
    );

    return (
        <FooterButtonsProvider>
            <StepFormInner
                title={title}
                headerSize={headerSize}
                actionButtons={actionButtons}
                stepperProps={stepperProps}
                isTabVariant={isTabVariant}
                allStepsProps={allStepsProps}
                stepFormProps={stepFormProps}
                onClickBack={onCancel}
                alignButtons="left"
                stretch={false}
                isRemote
                defaultActiveTab={defaultActiveTab}
            />
        </FooterButtonsProvider>
    );
};
