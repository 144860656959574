const EMPTY_UI_REPREZENTATION = "_null_";
const EMPTY_API_REPREZENTATION = null;
export const mqClusterFrom = value =>
    value === EMPTY_UI_REPREZENTATION ? EMPTY_API_REPREZENTATION : value;
export const mqClusterTo = value =>
    value === EMPTY_API_REPREZENTATION ? EMPTY_UI_REPREZENTATION : value;
export const KAFKA_CLUSTER_OPTIONS = [
    {
        value: EMPTY_UI_REPREZENTATION,
        label: "[empty]",
    },
    {
        value: "QA",
        label: "QA",
    },
    {
        value: "PROD",
        label: "PROD",
    },
];
