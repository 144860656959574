import { DropdownMenu, Forms } from "@/components/DesignSystem";
import { Button } from "@/components/DesignSystem/Button";
import { Input } from "@/components/DesignSystem/Input";
import { naturallySortBy } from "@/utils/form.utils";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import { noop } from "lodash";
import { identity, isEmpty } from "lodash/fp";
import React, { useMemo } from "react";

const flattenConverters = convertersMap => {
    const entries = Object.entries(convertersMap);
    const converters = entries.reduce((acc, [key, value]) => {
        if (isEmpty(value)) {
            acc = [...acc, `${key}()`];
        } else {
            acc = [...acc, ...value];
        }
        return acc;
    }, []);
    const sort = naturallySortBy(identity);
    return sort(converters);
};

const calculateConvertersDropdown = (
    convertersMap,
    onChange,
    converterExpressionReadOnly,
) => {
    const items = flattenConverters(convertersMap).map(converter => ({
        anchor: converter,
        onClick: () => onChange?.(converter),
    }));

    if (converterExpressionReadOnly)
        return <Button type="text" disabled={true} icon={Plus} />;
    return (
        <DropdownMenu
            button={{
                icon: Plus,
                size: "small",
                "data-test": "ConverterMenuDropdown-open",
            }}
            overlay={{
                contextMenu: true,
                items,
                dataTest: "ConverterMenuDropdown-overlay",
            }}
        />
    );
};

export const ConverterExpressionComponent = ({
    value,
    onChange,
    converters,
    converterExpressionReadOnly,
    isForm = false,
    ...rest
}) => {
    console.log("converters", converters, converterExpressionReadOnly);
    const dropdown = useMemo(
        () =>
            calculateConvertersDropdown(
                converters,
                onChange,
                converterExpressionReadOnly,
            ),
        [converters, onChange, converterExpressionReadOnly],
    );
    const InputOrFormInput = isForm ? Forms.Fields.Input : Input;
    return (
        <InputOrFormInput
            disabled={converterExpressionReadOnly}
            type="text"
            value={value}
            placeholder="Converter"
            onChange={isForm ? noop : e => onChange?.(e?.target?.value)}
            addonAfter={dropdown}
            className="pmConvertorExpression"
            {...rest}
        />
    );
};

ConverterExpressionComponent.propTypes = {};
